import {
    LOAD_TILES_SUCCESS,
    LOAD_TILES_FINISHED,
    CLEAR_ALL_TILES,
    CREATE_TILE_LOADING,
    CREATE_TILE_SUCCESS,
    CREATE_TILE_FAILURE,
    REMOVE_TILE,
    ADD_TILE,
    UPDATE_TILE,
    GET_TRADE_TILES_SUCCESS,
    GET_TRADE_TILES_FAILURE
} from '../actionTypes';
import { client } from '../../services/client';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';
import { colyClient } from '../../services/Panel-initialisation.service';

export const tileTypes = {
    'FARM_PLOT': 'FARM_PLOT',
    'ITEM': 'ITEM',
    'BARREL': 'BARREL',
    'IRRIGATION_CHANNEL': 'IRRIGATION_CHANNEL'
};

export const getTradeTilesSuccess = payload => {
    return ({
        type: GET_TRADE_TILES_SUCCESS,
        payload
    })
}

export const getTradeTilesFailure = () => ({
    type: GET_TRADE_TILES_FAILURE
})

export const getTradeTilesAsync = payload => dispatch => {
    return client.service('tile-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.data.map(tile => ({ ...tile, tileType: payload.tileTypes.find(type => type._id === tile.tileTypeId) }))
            dispatch(getTradeTilesSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const clearAllTiles = () => {
    return ({
        type: CLEAR_ALL_TILES
    })
}

export const loadTilesSuccess = payload => {
    payload.response = payload.response.map(tile => ({ ...tile, tileType: payload.tileTypes.find(type => type._id === tile.tileTypeId) }))
    return ({
        type: LOAD_TILES_SUCCESS,
        payload: payload.response
    })
}

export const loadTilesFinished = payload => {
    payload.response = payload.response.map(tile => ({ ...tile, tileType: payload.tileTypes.find(type => type._id === tile.tileTypeId) }))
    return ({
        type: LOAD_TILES_FINISHED,
        payload: payload.response
    })
}

export const loadTilesAsync = payload => dispatch => {
    const { panelId, characterId, z } = payload;

    dispatch(clearAllTiles());

    return dispatch(
        getRecursivelyAsync(
            'tile-instances',
            (response) => {
                if (!response) {
                    return;
                }
                // dispatch(loadTileTilesAsync({ ...payload, tileIds: response.map(tile => (tile._id))}))
                return (loadTilesSuccess({ ...payload, response }))
            },
            0,
            { $or: [ { panelId: Number(payload.panelId), z: payload.z }, { characterId: payload.characterId } ] },
            (response) => {
                return (loadTilesFinished({ ...payload, response}))
            }
        )
    );
}

export const loadTileTilesAsync = payload => dispatch => {
    const { tileIds } = payload;

    delete payload.tileIds;

    return dispatch(
        getRecursivelyAsync(
            'tile-instances',
            (response) => {
                return (loadTilesSuccess({ ...payload, response }))
            },
            0,
            { tileId : { $in: tileIds } },
            (response) => {
                return (loadTilesFinished({ ...payload, response}))
            }
        )
    );
}

export const createTileSuccess = payload => {
    return ({
        type: CREATE_TILE_SUCCESS,
        payload
    })
}

export const digIrrigationChannelAsync = (tileTypeId) => {
    colyClient.room.send('doAction', {
        serviceName: 'tile-instances',
        commandType: 'create',
        data: {
            tileTypeId
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const clearPlotAsync = (tileTypeId) => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'tile-instances',
        commandType: 'create',
        data: {
            tileTypeId
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const createTileAsync = (tileTypeId) => dispatch => {
    colyClient.room.send('doSuperAction', {
        serviceName: 'tile-instances',
        name: 'create',
        data: {
            tileTypeId
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const removePlotAsync = ({_id}) => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'tile-instances',
        commandType: 'remove',
        data: {
            _id
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const removeTile = payload => ({
    type: REMOVE_TILE,
    payload
})


export const addTile = payload => ({
    type: ADD_TILE,
    payload
})

export const updateTile = payload => ({
    type: UPDATE_TILE,
    payload
})

export const addTileToGroundAsync = payload => dispatch => {
    const { tileInstance, position, panelId } = payload;
    const { _id } = tileInstance;

    colyClient.room.send('doAction', {
        serviceName: 'tile-instances',
        commandType: 'patch',
        data: {
            _id,
            characterId: "",
            panelId,
            position,
            tileId: undefined
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addTileToTileAsync = payload => dispatch => {
    const { tileInstance, tileId } = payload;
    const { _id } = tileInstance;

    colyClient.room.send('doAction', {
        serviceName: 'tile-instances',
        commandType: 'patch',
        data: {
            _id,
            characterId: "",
            tileId
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addTileToCharacterAsync = payload => dispatch => {
    const { tileInstance, characterId } = payload;
    const { _id } = tileInstance;

    colyClient.room.send('doAction', {
        serviceName: 'tile-instances',
        commandType: 'patch',
        data: {
            _id,
            characterId,
            panelId: 0,
            tileId: undefined,
            position: { x: -1, y: -1 }
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}