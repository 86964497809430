import {
    GET_CHARACTER_MESSAGES_SUCCESS
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const getCharacterMessagesSuccess = payload => ({
    type: GET_CHARACTER_MESSAGES_SUCCESS,
    payload
})

export const getCharacterMessagesAsync = payload => dispatch => {
    const { characterId } = payload

    return client.service('character-messages').find({
          query: {
            characterId,
            $limit: 100,
            $sort: {
              createdAt: -1
            },
            $skip: payload?.skip || 0
          }
        })
        .then((response) => {
            dispatch(getCharacterMessagesSuccess(response))
            return response
        })
        .catch((error) => {
            console.error('error: ', error);
            // dispatch(showUnknownError())
        });
}
