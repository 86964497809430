import {
  GET_CHARACTER_FAILURE,
  INITIALISING_APP_STARTED,
  INITIALISING_APP_FINISHED,
  INITIALISING_PANEL_STARTED,
  INITIALISING_PANEL_FINISHED,
  DISABLE_KEYBOARD_MOVEMENT,
  ENABLE_KEYBOARD_MOVEMENT,
  OPEN_INVENTORY,
  CLOSE_INVENTORY,
  OPEN_CRAFTING,
  CLOSE_CRAFTING,
  OPEN_ACTIONS,
  CHOOSE_SEED_TO_PLANT,
  CHOOSE_FOOD_TO_EAT,
  SHOW_NO_CHARACTERS_NEARBY,
  HIDE_NO_CHARACTERS_NEARBY,
  CHOOSE_CHARACTER_TO_FIGHT,
  SHOW_FIGHT_CONFIRMATION,
  NEW_ORGANISATION,
  SHOW_ORGANISATION,
  SHOW_ORGANISATION_LIST,
  SHOW_CHARACTER_SELECT,
  CHANGE_CHARACTER,
  NEW_ZONE,
  SHOW_ZONE,
  SHOW_ZONE_LIST,
  SHOW_HELP,
  HIDE_HELP,
  HIDE_ALL_MENUS,
  FIGHT_CHARACTER_LOADING,
  SHOW_QUANTITY_INPUT,
  HIDE_QUANTITY_INPUT,
  HIDE_CRAFTING_ERROR,
  HIDE_MOVING_ERROR,
  CHOOSE_CHARACTER_TO_GIVE_TO,
  HIDE_CHARACTER_LIST,
  MOVE_ITEM_FAILURE,
  UNKNOWN_ERROR,
  HIDE_UNKNOWN_ERROR,
  SHOW_MESSAGES,
  NEW_TOWN_MESSAGE,
  FOCUS_MESSAGES,
  SAVE_LAST_CRAFT,
  SHOW_SUCCESS,
  HIDE_SUCCESS,
  START_LOOKING,
  STOP_LOOKING,
  START_AIMING,
  STOP_AIMING,
  START_MOVING,
  STOP_MOVING,
  POINT_AT_SUCCESS,
  SHOW_CHARACTER_ACTIONS,
  NEW_EMBARK_GROUPS_SUCCESS,
  SHOW_CHARACTER_PROFILE,
  SHOW_EQUIPMENT,
  SHOW_SHEET,
  SHOW_WORKSHOP,
  SHOW_CONVERSATION_TREE_WIZARD,
  SHOW_EVENTS,
  SHOW_DIARY,
  SHOW_WRITING_SURFACE,
  HIDE_WRITING_SURFACE,
  SHOW_DRAWING_SURFACE,
  HIDE_DRAWING_SURFACE,
  SHOW_WRITING_SURFACE_MENU,
  SHOW_LEVEL_UP_MESSAGE,
  SHOW_NEAR_DEATH_SCREEN,
  SHOW_GROUP_TERMINAL,
  SHOW_GUARD_HOUSE,
  SHOW_TABLE,
  SHOW_ACTION_SELECT_MENU,
  HIDE_ACTION_SELECT_MENU,
  // Crafting Error messages:
  BUILD_CONSTRUCTION_FAILURE,
  COOK_FOOD_FAILURE,
  CREATE_MACHINE_FAILURE,
  CREATE_TOOL_FAILURE,
  CREATE_METAL_FAILURE,
  CREATE_MATERIAL_FAILURE,
  CREATE_LOCK_FAILURE,
  CREATE_WRITING_SURFACE_FAILURE,
  CREATE_WRITING_IMPLEMENT_FAILURE,
  CREATE_FURNITURE_FAILURE,
  CREATE_TILE_FAILURE,
  CREATE_WEAPON_FAILURE,
  CREATE_ARMOUR_FAILURE,
  CREATE_CLOTHING_FAILURE,
  CREATE_JEWELLERY_FAILURE,
  DESTROY_CONSTRUCTION_FAILURE,
  // END of error messages
  EQUIP_TENT_LOCALLY,
  UNEQUIP_TENT_LOCALLY,
  EQUIP_WORKSHOP_LOCALLY,
  UNEQUIP_WORKSHOP_LOCALLY,
  SHOW_DIRECTION_INPUT,
  HIDE_DIRECTION_INPUT,
  SHOW_SELECT_ITEMS,
  HIDE_SELECT_ITEMS,
  SHOW_CHARACTER_DEATH_PAGE,
  HIDE_CHARACTER_DEATH_PAGE,
  UPDATE_POSITION_FAILURE,
  SET_IS_OVERENCUMBERED,

  BEGIN_WALK_DOWNSTAIRS,
  BEGIN_WALK_UPSTAIRS,

  START_AREA_SELECT,
  STOP_AREA_SELECT,

  SHOW_SCHEDULER,
  NAME_LOCATION,

  ADD_ZONE

} from "../actionTypes";

const initialState = {
  isInitialisingApp: false,
  isInitialisingPanel: false,
  isFirstInitialisation: false,
  isMovementEnabled: true,
  isInventoryOpen: false,
  isCraftingOpen: false,
  isActionsOpen: false,
  isSeedListShowing: false,
  isCharacterFoodShowing: false,
  isNoCharactersNearbyErrorShowing: false,
  isCharacterListShowing: false,
  isFightConfirmationShowing: false,
  defendingCharacterId: null,
  isHelpOpen: false,
  isQuantityInputOpen: false,
  isMovingErrorShowing: false,
  isUnknownErrorShowing: false,
  errorMessage: "",
  isMessagesShowing: false,
  isMessagesFocussed: false,
  lastCraft: undefined,
  isSuccessShowing: false,
  isSchedulerShowing: false,
  highlight: false,
  isCharacterActionsShowing: false,
  isCharacterProfileShowing: false,
  isEquipmentShowing: false,
  isDirectionInputShowing: false,
  isLevelUpMessageShowing: false,
  isTerminalShowing: false,
  isNewTownMessageShowing: false,
  isSelectItemShowing: false,
  isCharacterDeathPageShowing: false,
  isTableShowing: false,
  isVendingMachineShowing: false,
  isActionSelectMenuShowing: false,
  isNearDeathScreenShowing: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIALISING_APP_STARTED: {
      return {
        ...state,
        isInitialisingApp: true,
        isFirstInitialisation: true
      };
    }

    case GET_CHARACTER_FAILURE:
    case INITIALISING_APP_FINISHED: {
      return {
        ...state,
        isInitialisingApp: false
      };
    }

    case BEGIN_WALK_DOWNSTAIRS:
    case BEGIN_WALK_UPSTAIRS:
    case INITIALISING_PANEL_STARTED: {
      return {
        ...state,
        isInitialisingPanel: true
      };
    }

    case UPDATE_POSITION_FAILURE:
    case INITIALISING_PANEL_FINISHED: {
      return {
        ...state,
        isInitialisingPanel: false,
        isFirstInitialisation: false
      };
    }

    case DISABLE_KEYBOARD_MOVEMENT: {
      return {
        ...state,
        isMovementEnabled: false
      };
    }

    case ENABLE_KEYBOARD_MOVEMENT: {
      return {
        ...state,
        isMovementEnabled: true,
        isMessagesFocussed: false
      };
    }

    case OPEN_INVENTORY: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isMovementEnabled: false,
        isInventoryOpen: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      };
    }

    case UNKNOWN_ERROR: {
      if (state.isInventoryOpen || state.isWorkshopShowing) {
        return {
          ...state,
          isUnknownErrorShowing: true,
          errorMessage: action.payload?.message
        }
      }

      return { ...state }
    }

    case UNEQUIP_TENT_LOCALLY:
    case EQUIP_TENT_LOCALLY:
    case UNEQUIP_WORKSHOP_LOCALLY:
    case CLOSE_INVENTORY: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isMovementEnabled: true,
        isInventoryOpen: false
      };
    }


    case EQUIP_WORKSHOP_LOCALLY: {
      const areaSelect = action.payload.position ? { ...state.areaSelect, ...action.payload.position } : { ...state.areaSelect }
      const areaSelectType = 'workshop'
      return {
        ...state,
        lastCraft: { ...state.lastCraft },
        isMovementEnabled: true,
        isInventoryOpen: false,
        isWorkshopShowing: false,
        isSelectItemShowing: false,
        areaSelect: { ...areaSelect, areaSelectType },
        coords: { ...action.payload.coords },
      };
    }

    case OPEN_CRAFTING: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isMovementEnabled: false,
        isCraftingOpen: true
      };
    }

    case CLOSE_CRAFTING: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isMovementEnabled: true,
        isCraftingOpen: false
      };
    }

    case OPEN_ACTIONS: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isMovementEnabled: false,
        isActionsOpen: true
      };
    }

    case SHOW_CHARACTER_ACTIONS: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isCharacterActionsShowing: true,
        focussedCharacterId: action.payload?.focussedCharacterId || undefined
      }
    }

    case NEW_EMBARK_GROUPS_SUCCESS: {
      return {
        ...state,
        isMessagesShowing: false
      }
    }

    case NAME_LOCATION: {
      return {
        ...state,
        isNameLocationShowing: true,
        isMovementEnabled: false,
      };
    }

    case CHOOSE_SEED_TO_PLANT: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isSeedListShowing: true,
        isMovementEnabled: false,
        isActionsOpen: true,
      };
    }

    case CHOOSE_FOOD_TO_EAT: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isCharacterFoodShowing: true,
        isMovementEnabled: false,
        isActionsOpen: true,
      };
    }

    case SHOW_NO_CHARACTERS_NEARBY: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isNoCharactersNearbyErrorShowing: true,
        isMovementEnabled: false,
        isActionsOpen: false,
      };
    }

    case HIDE_NO_CHARACTERS_NEARBY: {
      return {
        ...state,
        isNoCharactersNearbyErrorShowing: false
      }
    }

    case CHOOSE_CHARACTER_TO_GIVE_TO: {
      return {
        ...state,
        isCharacterListShowing: true,
      };
    }

    case HIDE_CHARACTER_LIST: {
      return {
        ...state,
        isCharacterListShowing: false
      };
    }

    case CHOOSE_CHARACTER_TO_FIGHT: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isCharacterListShowing: true,
        isMovementEnabled: false,
        isActionsOpen: true,
      };
    }

    case SHOW_FIGHT_CONFIRMATION: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isFightConfirmationShowing: true,
        isMovementEnabled: false,
        isActionsOpen: true,
        defendingCharacterId: action.payload.defendingCharacterId,
      };
    }

    case FIGHT_CHARACTER_LOADING: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isFightConfirmationShowing: false,
        isMovementEnabled: true,
        isActionsOpen: false,
        defendingCharacterId: null
      }
    }

    case NEW_ORGANISATION: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isNewOrganisationShowing: true,
        organisationId: undefined
      };
    }

    case SHOW_ORGANISATION: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isNewOrganisationShowing: true,
        organisationId: action.payload.organisationId
      }
    }

    case SHOW_ORGANISATION_LIST: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isOrganisationListShowing: true,
      };
    }

    case NEW_ZONE: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isZoneShowing: true,
        organisationId: undefined
      };
    }

    case SHOW_ZONE: {
      return {
        ...initialState,
        areaSelect: state.areaSelect,
        isMovementEnabled: false,
        isZoneShowing: true,
        zoneId: action.payload?.zoneId || state.zoneId
      }
    }

    case ADD_ZONE: {
      return {
        ...initialState,
        isMovementEnabled: state.isMovementEnabled,
        isZoneShowing: state.isZoneShowing,
        zoneId: action.payload?._id
      }
    }

    case SHOW_ZONE_LIST: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isZoneListShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_HELP: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isMovementEnabled: false,
        isHelpOpen: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      };
    }

    case HIDE_HELP: {
      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isHelpOpen: false,
      };
    }

    case HIDE_UNKNOWN_ERROR: {
      return {
        ...state,
        isUnknownErrorShowing: false
      }
    }

    case HIDE_ALL_MENUS: {
      if (state.isUnknownErrorShowing) {
        return {
          ...state,
          isUnknownErrorShowing: false
        }
      }

      if (state.isCharacterListShowing) {
        return {
          ...state,
          isCharacterListShowing: false
        }
      }

      return {
        ...initialState,
        lastCraft: { ...state.lastCraft },
        isMovementEnabled: true,
        focussedCharacterId: undefined
      };
    }

    case SHOW_QUANTITY_INPUT: {
      return {
        ...state,
        isQuantityInputOpen: true,
      };
    }

    case HIDE_QUANTITY_INPUT: {
      return {
        ...state,
        isQuantityInputOpen: false,
      };
    }

    case HIDE_MOVING_ERROR: {
      return {
        ...state,
        isMovingErrorShowing: false
      }
    }

    case SHOW_DIRECTION_INPUT: {
      return {
        ...initialState,
        isDirectionInputShowing: true,
        isMovementEnabled: false,
      };
    }

    case SHOW_MESSAGES: {
      return {
        ...state,
        isCharacterActionsShowing: false,
        isMessagesShowing: true,
        isMovementEnabled: false,
        focussedCharacterId: action.payload?.focussedCharacterId || undefined,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_SELECT_ITEMS: {
     return {
      ...state,
      isMovementEnabled: false,
      isSelectItemShowing: action.payload?.itemsToSelect,
      isCharacterActionsShowing: false,
      selectedCharacterId: action.payload?.selectedCharacterId,

      moving: undefined,
      highlight: false,
      coords: undefined
     } 
    }

    case HIDE_SELECT_ITEMS: {
      return {
      ...state,
      isMovementEnabled: !state.isEquipmentShowing,
      isSelectItemShowing: false,
     }
    }

    case NEW_TOWN_MESSAGE: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isNewTownMessageShowing: true,
        focussedCharacterId: action.payload?.focussedCharacterId || undefined
      }
    }

    case FOCUS_MESSAGES: {
      return {
        ...state,
        isMessagesFocussed: true
      }
    }

    case SAVE_LAST_CRAFT: {
      return {
        ...state,
        lastCraft: action.payload
      }
    }

    case SHOW_SUCCESS: {
      return {
        ...state,
        isSuccessShowing: true
      }
    }

    case HIDE_SUCCESS: {
      return {
        ...state,
        isSuccessShowing: false
      }
    }

    case SHOW_SCHEDULER:  {
      return {
        ...state,
        isSchedulerShowing: true,
        isMovementEnabled: false,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case START_LOOKING: {
      const highlight = action.payload.position ? { ...action.payload.position } : { ...state.highlight }
      return {
        ...state,
        highlight: { ...highlight },
        coords: { ...action.payload.coords }
      }
    }

    case POINT_AT_SUCCESS: 
    case STOP_LOOKING: {
      return {
        ...state,
        highlight: false
      }
    }

    case START_AIMING: {
      const aiming = action.payload.position ? { ...action.payload.position, type: action.payload.type } : { ...state.aiming }
      return {
        ...state,
        aiming: { ...aiming },
        coords: { ...action.payload.coords }
      }
    }

    case START_MOVING: {
      const moving = action.payload.position ? { ...action.payload.position, type: action.payload.type } : { ...state.moving }

      return {
        ...state,
        moving: { ...moving },
        coords: { ...action.payload.coords }
      }
    }

    case START_AREA_SELECT: {
      const areaSelect = action.payload.position ? { ...state.areaSelect, ...action.payload.position } : { ...state.areaSelect }

      if (action.payload.coords) {
        areaSelect.type = 'selectingArea'
        areaSelect.startingCoords = action.payload.coords
      }

      if (action.payload.isStartOfAreaSelect) {
        areaSelect.startingCoords = undefined
      }

      if (action.payload.zoneSettings) {
        areaSelect.zoneSettings = { ...action.payload.zoneSettings }
      }

      if (action.payload.zone) {
        areaSelect.zone = { ...action.payload.zone }
      }

      const areaSelectType = action.payload?.areaSelectType || state.areaSelect?.areaSelectType || 'gather'

      return {
        ...state,
        isZoneShowing: false,
        isMovementEnabled: true,
        isSelectItemShowing: false,
        isWorkshopShowing: false,
        areaSelect: { ...areaSelect, areaSelectType, isDisabled: action.payload.isDisabled },
        coords: { ...action.payload.coords },
        selectedCharacterId: action.payload.selectedCharacterId
      }
    }

    case STOP_AIMING: {
      return {
        ...state,
        aiming: false
      }
    }

    case STOP_MOVING: {
      return {
        ...state,
        moving: false
      }
    }

    case STOP_AREA_SELECT: {
      return {
        ...state,
        areaSelect: false
      }
    }

    case SHOW_CHARACTER_PROFILE: {
      return {
        ...state,
        isMovementEnabled: false,
        isCharacterProfileShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_EQUIPMENT: {
      return {
        ...state,
        isMovementEnabled: false,
        isEquipmentShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_SHEET: {
      return {
        ...state,
        isMovementEnabled: false,
        isSheetShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_WORKSHOP: {
      return {
        ...state,
        isCharacterActionsShowing: false,
        isMovementEnabled: false,
        isWorkshopShowing: action.payload?.workshopTypeId !== 'workshopSelect',
        isWorkshopSelectShowing: action.payload?.workshopTypeId === 'workshopSelect',
        workshopTypeId: action.payload?.workshopTypeId,
        workshopId: action.payload?.workshopId,
        selectedCharacterId: action.payload?.selectedCharacterId,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_CONVERSATION_TREE_WIZARD: {
      return {
        ...state,
        isMovementEnabled: false,
        isConversationTreeWizardShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_EVENTS: {
      return {
        ...state,
        isMovementEnabled: false,
        isEventsShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_DIARY: {
      return {
        ...state,
        isMovementEnabled: false,
        isDiaryShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_WRITING_SURFACE: {
      return {
        ...state,
        isMovementEnabled: false,
        surfaceService: action.payload?.surfaceService,
        surfaceId: action.payload?.surfaceId,
        isEditWritingSurfaceShowing: action.payload.isEditWritingSurfaceShowing,
        isLooking: action.payload?.isLooking,
        item: action.payload?.item,
        workshopId: action.payload?.workshopId || '',

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case HIDE_WRITING_SURFACE: {
      return {
        ...state,
        isMovementEnabled: false,
        surfaceService: undefined,
        surfaceId: undefined,
        isEditWritingSurfaceShowing: false,
        isLooking: false,
        item: undefined,
      }
    }

    case SHOW_DRAWING_SURFACE: {
      return {
        ...state,
        isMovementEnabled: false,
        surfaceService: action.payload?.surfaceService,
        surfaceId: action.payload?.surfaceId,
        isEditDrawingSurfaceShowing: true,
        isLooking: action.payload?.isLooking,
        item: action.payload?.item,
        workshopId: action.payload?.workshopId || '',

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case HIDE_DRAWING_SURFACE: {
      return {
        ...state,
        isMovementEnabled: false,
        surfaceService: undefined,
        surfaceId: undefined,
        isEditDrawingSurfaceShowing: false,
        isLooking: false,
        item: undefined
      }
    }

    case SHOW_LEVEL_UP_MESSAGE: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isLevelUpMessageShowing: true,
      }
    }

    case SHOW_NEAR_DEATH_SCREEN: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isNearDeathScreenShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_GROUP_TERMINAL: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isTerminalShowing: true
      }
    }

    case SHOW_GUARD_HOUSE: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isGuardHouseShowing: true
      }
    }

    case SHOW_TABLE: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isTableShowing: true,
        focussedFurnitureId: action.payload?.focussedFurnitureId,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SHOW_ACTION_SELECT_MENU: {
      return {
        ...state,
        isMovementEnabled: false,
        isActionSelectShowing: action.payload?.options,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case HIDE_ACTION_SELECT_MENU: {
      return {
        ...state,
        isActionSelectShowing: false,
        isMovementEnabled: !(state.isNewTownMessageShowing || state.isCharacterActionsShowing || state.isMessagesShowing || state.isTableShowing || state.isSelectItemShowing || state.isInventoryOpen || state.isCraftingOpen || state.isActionsOpen || state.isEquipmentShowing || state.isWorkshopShowing || state.isLooking)
      }
    }

    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
        isMovementEnabled: false,
        isCharacterDeathPageShowing: true,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case SET_IS_OVERENCUMBERED: {
      return {
        ...state,
        isOverencumbered: action.payload.isOverencumbered
      }
    }

    case SHOW_CHARACTER_SELECT: {
      return {
        ...state,
        isMovementEnabled: false,
        isCharacterSelectShowing: true,
        isActionsOpen: false,

        moving: undefined,
        highlight: false,
        coords: undefined
      }
    }

    case CHANGE_CHARACTER: {
      return {
        ...initialState,
      }
    }

    default:
      return state;
  }
}
