import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';

import { addMaterialToCharacterAsync } from '../../redux/actions/inventory.actions';
import { addFoodToCharacterAsync } from '../../redux/actions/food.actions';
import { addMineralToCharacterAsync } from '../../redux/actions/mineral.actions';
import { addToolToCharacterAsync } from '../../redux/actions/tool.actions';
import { tileTypes, addTileToCharacterAsync } from '../../redux/actions/tile.actions';
import { addMetalToCharacterAsync } from '../../redux/actions/metal.actions';
import { addKeyToCharacterAsync } from '../../redux/actions/key.actions';
import { addWritingSurfaceToCharacterAsync } from '../../redux/actions/writing-surface.actions';
import { addWritingImplementToCharacterAsync } from '../../redux/actions/writing-implement.actions';
import { addWeaponToCharacterAsync } from '../../redux/actions/weapon.actions';
import { addArmourToCharacterAsync } from '../../redux/actions/armour.actions';
import { addClothingToCharacterAsync } from '../../redux/actions/clothing.actions';
import { addJewelleryToCharacterAsync } from '../../redux/actions/jewellery.actions';
import { addTentToCharacterAsync } from '../../redux/actions/tent.actions';
import { addLockToCharacterAsync, unlockLockAsync, lockLockAsync } from '../../redux/actions/lock.actions';
import { hideAllMenus, showQuantityInput, hideQuantityInput, showWritingSurface, showDrawingSurface, showCharacterActions } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    equipToolAsync,
    equipWeaponAsync,
    equipArmourAsync,
    equipClothingAsync,
    equipJewelleryAsync,
    unequipClothingAsync,
    unequipJewelleryAsync, 
    equipTentLocally,
    unequipTentLocally
} from '../../redux/actions/character.actions';
import { disableKeyboardMovement, enableKeyboardMovement, startAreaSelect, } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectIsSelectItemsShowing,
    selectCurrentTiles,
    selectTileInventory,
    selectTileFood,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectTileTents,
    selectTileLocks,
    selectInventory,
    selectCharacterFood,
    selectTiles,
    selectCharacterMinerals,
    selectCharacterTools,
    selectCharacterMetals,
    selectCharacterKeys,
    selectTileTypeByName,
    selectCharacterWritingSurfaces,
    selectCharacterWritingImplements,
    selectCharacterWeapons,
    selectCharacterArmour,
    selectCharacterClothing,
    selectCharacterJewellery,
    selectCharacterTents,
    selectCharacterLocks,
    selectCharacterTiles,
    selectCharacters,
    selectServerTimeOffset,
    selectTileTiles,
    selectCharacterZones,
    selectSelectedCharacterId,
    selectMoving,
    selectOnScreenAnimals,
    selectOrganisations,
    selectTilesByPosition,
    selectNearbyCharacters,
    selectCharacterPanels
} from '../../redux/selectors';
import Menu from '../utils/menu/Menu';
import QuantityInput from '../utils/quantity-input/QuantityInput';
import TextInput from '../utils/text-input/TextInput';
import { getKeyDescription } from '../side-bar/looking/key-description'
import { animalTypeDescribers } from '../side-bar/looking/animal-description'
import { getTorchDurationString, getCondition } from '../side-bar/looking/Looking';
import { BRAINCHIP_TYPE_NAMES } from '../../services/Tilemap.service';
import { colyClient } from '../../services/Panel-initialisation.service';

import { client } from '../../services/client';

import './SelectItems.css';

class SelectItems extends React.Component {
    constructor() {
        super();
        this.state = { direction: "", hiddenOptions: [] };

        document.addEventListener('keydown', this.directionHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.directionHandler);
    }

    getWeight(item, itemTypeKey, isTileWeightCalc) {
        if (!isTileWeightCalc) {
            this.totalWeight += (item.quantity || 1) * item[itemTypeKey]?.weight;
        }
        return `${(item.quantity || 1) * item[itemTypeKey]?.weight}u`;
    }

    getQuantity(item) {
        if (Number(item.quantity) > 1) {
            this.setState({isQuantityInputShowing: true, maxQuantity: item.quantity });
            // this.props.showQuantityInput();
        }

        return new Promise((resolve, reject) => {
            if (Number(item.quantity) === 1) {
                return resolve(1);
            }

            this.setState({
                onQuantitySupplied: (quantity) => {
                    this.props.hideQuantityInput();
                    this.setState({isQuantityInputShowing: false})
                    resolve(Number(quantity));
                },
                onQuantityClosed:  () => {
                    this.props.hideQuantityInput();
                    this.setState({isQuantityInputShowing: false})
                    reject('No quantity supplied');
                }
            })
        });
    }

    searchUpdate(tiles, searchField) {
        tiles = tiles.filter((tile) => (tile.text.toLowerCase().indexOf(searchField.toLowerCase) > -1))
    }

    async onItemClick(materialInstance, quantity) {
        if (!quantity) {
            quantity = await this.getQuantity(materialInstance);
        }
        materialInstance = { ...materialInstance, quantity };

        this.setState({isLoading: true})
        this.props.addMaterialToCharacterAsync({ characterId: this.props.character._id, materialInstance })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onFoodClick(foodInstance, quantity) {
        if (!quantity) {
            quantity = await this.getQuantity(foodInstance);
        }
        foodInstance = { ...foodInstance, quantity };

        this.setState({isLoading: true})
        this.props.addFoodToCharacterAsync({ characterId: this.props.character._id, foodInstance })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onMineralClick(mineralInstance, quantity) {
        if (!quantity) {
            quantity = await this.getQuantity(mineralInstance);
        }

        mineralInstance = { ...mineralInstance, quantity };

        this.setState({isLoading: true})
        this.props.addMineralToCharacterAsync({ characterId: this.props.character._id, mineralInstance })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onToolClick(tool, quantity) {
        if (!quantity) {
            quantity = 1;

            if (tool.hint > 1) {
                quantity = await this.getQuantity({ ...tool, quantity: tool.hint });
            }
        }

        this.setState({isLoading: true})

        const toolInstances = tool.instances.slice(0, quantity)

        this.props.addToolToCharacterAsync({ characterId: this.props.character._id, toolInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
}

    async onMetalClick(metalInstance, quantity) {
        if (!quantity) {
            quantity = await this.getQuantity(metalInstance);
        }

        metalInstance = { ...metalInstance, quantity };

        this.setState({isLoading: true})
        this.props.addMetalToCharacterAsync({ characterId: this.props.character._id, metalInstance })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onKeyClick(key, quantity) {
        if (!quantity) {
            quantity = 1;

            if (key.hint > 1) {
                quantity = await this.getQuantity({ ...key, quantity: key.hint });
            }
        }

        this.setState({isLoading: true})

        const keyInstances = key.instances.slice(0, quantity)

        this.props.addKeyToCharacterAsync({ characterId: this.props.character._id, keyInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onWritingSurfaceClick(writingSurface, quantity) {
        if (!quantity) {
            quantity = 1;

            if (writingSurface.hint > 1) {
                quantity = await this.getQuantity({ ...writingSurface, quantity: writingSurface.hint });
            }
        }

        this.setState({isLoading: true})

        const writingSurfaceInstances = writingSurface.instances.slice(0, quantity);

        this.props.addWritingSurfaceToCharacterAsync({ characterId: this.props.character._id, writingSurfaceInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onWritingImplementClick(writingImplement, quantity) {
        if (!quantity) {
            quantity = 1;

            if (writingImplement.hint > 1) {
                quantity = await this.getQuantity({ ...writingImplement, quantity: writingImplement.hint });
            }
        }

        this.setState({isLoading: true})

        const writingImplementInstances = writingImplement.instances.slice(0, quantity)

        this.props.addWritingImplementToCharacterAsync({ characterId: this.props.character._id, writingImplementInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onWeaponClick(weapon, quantity) {
        if (!quantity) {
            quantity = 1;

            if (weapon.hint > 1) {
                quantity = await this.getQuantity({ ...weapon, quantity: weapon.hint });
            }
        }

        this.setState({isLoading: true})

        const weaponInstances = weapon.instances.slice(0, quantity)
        
        this.props.addWeaponToCharacterAsync({ characterId: this.props.character._id, weaponInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onArmourClick(armour, quantity) {
        if (!quantity) {
            quantity = 1;

            if (armour.hint > 1) {
                quantity = await this.getQuantity({ ...armour, quantity: armour.hint });
            }
        }

        this.setState({isLoading: true})

        const armourInstances = armour.instances.slice(0, quantity)

        this.props.addArmourToCharacterAsync({ characterId: this.props.character._id, armourInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onClothingClick(clothing, quantity) {
        if (!quantity) {
            quantity = 1;

            if (clothing.hint > 1) {
                quantity = await this.getQuantity({ ...clothing, quantity: clothing.hint });
            }
        }

        this.setState({isLoading: true})

        const clothingInstances = clothing.instances.slice(0, quantity)

        this.props.addClothingToCharacterAsync({ characterId: this.props.character._id, clothingInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onJewelleryClick(jewellery, quantity) {
        if (!quantity) {
            quantity = 1;

            if (jewellery.hint > 1) {
                quantity = await this.getQuantity({ ...jewellery, quantity: jewellery.hint });
            }
        }

        this.setState({isLoading: true})

        const jewelleryInstances = jewellery.instances.slice(0, quantity)

        this.props.addJewelleryToCharacterAsync({ characterId: this.props.character._id, jewelleryInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onTentClick(tent, quantity) {
        if (!quantity) {
            quantity = 1;

            if (tent.hint > 1) {
                quantity = await this.getQuantity({ ...tent, quantity: tent.hint });
            }
        }

        this.setState({isLoading: true})

        const tentInstances = tent.instances.slice(0, quantity)

        this.props.addTentToCharacterAsync({ characterId: this.props.character._id, tentInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onLockClick(lock, quantity) {
        if (!quantity) {
            quantity = 1;

            if (lock.hint > 1) {
                quantity = await this.getQuantity({ ...lock, quantity: lock.hint });
            }
        }

        this.setState({isLoading: true})

        const lockInstances = lock.instances.slice(0, quantity)

        this.props.addLockToCharacterAsync({ characterId: this.props.character._id, lockInstances })
            .finally(() => (this.setState({isLoading: false})));

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onTileClick(tile, quantity) {
        if (!quantity) {
            quantity = 1;

            if (tile.hint > 1) {
                quantity = await this.getQuantity({ ...tile, quantity: tile.hint });
            }
        }

        for (let i=0; i < quantity; i++) {
            const tileInstance = tile.instances ? tile.instances[i] : tile

            if (i >= quantity) {
                return;
            }

            this.props.addTileToCharacterAsync({ tileInstance, characterId: this.props.character._id })
        }

        setTimeout(store.dispatch(hideAllMenus()))
    }

    directionHandler = (event) => {
        if (this.state.isQuantityInputShowing) {
            return;
        }

        if (event.key === 'Enter') {
            if (this.props.onDirectionSupplied) {
                this.props.onDirectionSupplied(this.state.direction);
            }

            return;
        }
    };

    onLookAtWritingSurface(writingSurface) {
        if (writingSurface.graffiti) {
            this.onEditWritingSurface({ ...writingSurface, isLooking: true })
            return;
        }

        if (writingSurface.pixelsArray && writingSurface.pixelsArray.length > 0) {
            this.onEditDrawingSurface({ ...writingSurface, isLooking: true })
            return;
        }
    }

    onEditWritingSurface(writingSurface) {
        this.props.showWritingSurface({
            surfaceService: writingSurface.serviceName || 'writing-surface-instances',
            surfaceId: writingSurface._id,
            isLooking: writingSurface.isLooking,
            isEditWritingSurfaceShowing: true
        })
    }

    onEditDrawingSurface(writingSurface) {
        this.props.showDrawingSurface({
            surfaceService: writingSurface.serviceName || 'writing-surface-instances',
            surfaceId: writingSurface._id,
            isLooking: writingSurface.isLooking,
        })
    }

    correctShortcutKeys(newConversationTree) {
        let index = 0;

        return newConversationTree.map((option) => {
            const newShortcut = String.fromCharCode(97 + index)
            index++;

            return {
                ...option,
                shortcut: newShortcut
            }
        })

        setTimeout(store.dispatch(hideAllMenus()))
    }

    onEquipWeapon({_id, weaponTypeId}) {
        this.props.equipWeaponAsync({
            _id,
            weaponTypeId,
            characterId: this.props.character._id
        })

        setTimeout(store.dispatch(hideAllMenus()))
    }

    onEquipArmour({_id, armourTypeId}) {
        this.props.equipArmourAsync({
            _id,
            armourTypeId,
            characterId: this.props.character._id
        })

        setTimeout(store.dispatch(hideAllMenus()))
    }

    onEquipTool({_id, toolTypeId}) {
        this.props.equipToolAsync({
            _id,
            toolTypeId,
            characterId: this.props.character._id
        })

        setTimeout(store.dispatch(hideAllMenus()))
    }

    onEquipClothing({ _id, clothingTypeId, type }) {
        if (!_id) {
            const itemToRemove = this.props.character.clothingItems.find(item => (item?.type?.bodyPart === this.props.isSelectItemsShowing));
            if (itemToRemove?.itemType === 'clothing') {
                this.onRemoveClothing(itemToRemove?.type)
            } else {
                this.onRemoveJewellery(itemToRemove?.type);
            }
            return;
        }
        this.props.equipClothingAsync({
            _id,
            clothingTypeId,
            bodyPart: type?.bodyPart,
            clothingItems: this.props.character.clothingItems || [],
            characterId: this.props.character._id
        })

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onRemoveClothing({ _id }) {
        await this.props.unequipClothingAsync({
            _id,
            clothingItems: this.props.character.clothingItems,
            characterId: this.props.character._id
        })

        setTimeout(store.dispatch(hideAllMenus()))
    }
    
    onEquipJewellery({ _id, jewelleryTypeId, jewelleryType }) {
        this.props.equipJewelleryAsync({
            _id,
            jewelleryTypeId,
            bodyPart: jewelleryType.bodyPart,
            clothingItems: this.props.character.clothingItems || [],
            characterId: this.props.character._id
        });

        setTimeout(store.dispatch(hideAllMenus()))
    }

    async onRemoveJewellery({ _id }) {
        const unequipped = await this.props.unequipJewelleryAsync({
            _id,
            clothingItems: this.props.character.clothingItems,
            characterId: this.props.character._id
        })

        setTimeout(store.dispatch(hideAllMenus()))
    }

    render() {
        let takeAllOption = {}

        if (this.props.isSelectItemsShowing === 'tile') {
            takeAllOption = {
                'Tab': {
                    callback: (option) => {
                        Object.values(this.props.tileContents).forEach((tile, index) => {
                            const installedLock = tile.locks.find(lock => (lock.isInstalled))

                            if (installedLock?.isLocked) {
                                return;
                            }

                            if (tile.tile?.tileType?.name === 'Book') {
                                this.onTileClick(tile.tile)
                                return;
                            }

                            tile.inventory.forEach(item => (this.onItemClick(item, item.quantity)))
                            tile.food.forEach(food => (this.onFoodClick(food, food.quantity)));
                            tile.minerals.forEach(minerals => (this.onMineralClick(minerals, minerals.quantity)));
                            tile.tools.forEach(tool => (this.onToolClick(tool, tool.hint)));
                            tile.metals.forEach(metal => (this.onMetalClick(metal, metal.quantity)));
                            tile.keys.forEach(key => (this.onKeyClick(key, key.hint)));
                            tile.writingSurfaces.forEach(writingSurface => (this.onWritingSurfaceClick(writingSurface, writingSurface.hint)));
                            tile.writingImplements.forEach(writingImplement => (this.onWritingImplementClick(writingImplement, writingImplement.quantity)));
                            tile.weapons.forEach(weapon => (this.onWeaponClick(weapon, weapon.quantity)));
                            tile.armour.forEach(armour => (this.onArmourClick(armour, armour.hint || armour.quantity)));
                            tile.clothing.forEach(clothing => (this.onClothingClick(clothing, clothing.hint || clothing.quantity)));
                            tile.jewellery.forEach(jewellery => (this.onJewelleryClick(jewellery, armour.hint || jewellery.quantity)));
                            tile.tents.forEach(tent => (this.onTentClick(tent, tent.hint)));
                            tile.locks.filter(lock => (!lock.isInstalled)).forEach(lock => (this.onLockClick(lock, lock.hint)));
                            tile.tiles.forEach(tile => (this.onTileClick(tile, tile.hint)))
                        });

                        this.props.onSelectItemsClosed()
                    },
                    text: 'Take all'
                }
            }
        }

        if (this.props.isSelectItemsShowing === 'itemSearch') {
            takeAllOption = {
                '/': {
                    callback: (option) => {
                        this.setState({
                            isTextInputShowing: true
                        })
                    },
                    text: 'Search'
                }
            }
        }

        const menuOptions = {
            ...takeAllOption,
            '+': {
                callback: (option) => {
                    if (this.state.isDisabled) {
                        return;
                    }
                    this.setState({
                        hiddenOptions: [ ...this.state.hiddenOptions.filter(hiddenOption => (hiddenOption !== `${option.text}.${option.parentId}`)) ]
                    })
                },
                text: 'Open'
            },
            '-': {
                callback: (option) => {
                    if (this.state.isDisabled) {
                        return;
                    }
                    this.setState({
                        hiddenOptions: [ ...this.state.hiddenOptions.filter(hiddenOption => (hiddenOption !== `${option.text}.${option.parentId}`)), `${option.text}.${option.parentId}` ]
                    })
                },
                text: 'Close'
            }
        }

        let tiles = Object.values(this.props.tileContents).map((tile, index) => {
            const materials = {
                text: 'Materials',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Materials.${tile._id}`) > -1 ? [] : Object.values(tile.inventory).map((material, index) => {
                    let materialName = material?.materialType?.name;

                    if (material?.plant?.name || animalTypeDescribers[material?.animal?.name]?.name || material?.fish?.name) {
                        materialName = (material?.plant?.name || animalTypeDescribers[material?.animal?.name]?.name || material?.fish?.name) + ' ' + material?.materialType?.name;
                    }

                    return (
                        {
                            ...material,
                            text: materialName,
                            condition: getCondition(material),
                            hintClassName: 'quantity',
                            hint:material.quantity || 1,
                            weight: this.getWeight(material, 'materialType'),
                            indentation: 2,
                            actions: {
                                ...takeAllOption,
                                'SPACE': {
                                    callback: (option) => (this.onItemClick(option)),
                                    text: 'Pick up'
                                },
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            const foods = {
                text: 'Food',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Food.${tile._id}`) > -1 ? [] : tile.food.map((food, index) => {
                    let text = food?.foodType?.name

                    const brainchipEffect = BRAINCHIP_TYPE_NAMES[food?.effect];

                    if (brainchipEffect) {
                        text = `${text} [${brainchipEffect}]`
                    }

                    return (
                        {
                            ...food,
                            text,
                            condition: getCondition(food),
                            hintClassName: 'quantity',
                            hint:food.quantity || 1,
                            weight: this.getWeight(food, 'foodType'),
                            indentation: 2,
                            actions: {
                                ...takeAllOption,
                                'SPACE': {
                                    callback: (option) => (this.onFoodClick(option)),
                                    text: 'Pick up'
                                }
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            const minerals = {
                text: 'Minerals',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Minerals.${tile._id}`) > -1 ? [] : tile.minerals.map((mineral, index) => {
                    return (
                        {
                            ...mineral,
                            text: mineral?.mineralType?.name,
                            condition: getCondition(mineral),
                            hintClassName: 'quantity',
                            hint:mineral.quantity || 1,
                            weight: this.getWeight(mineral, 'mineralType'),
                            indentation: 2,
                            actions: {
                                ...takeAllOption,
                                'SPACE': {
                                    callback: (option) => (this.onMineralClick(option)),
                                    text: 'Pick up'
                                }
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            const tools = {
                text: 'Tools',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Tools.${tile._id}`) > -1 ? [] : tile.tools.map((tool, index) => {
                    const torchDurationString = getTorchDurationString(tool, this.props.serverTimeOffset, false);
                    
                    return (
                        {
                            ...tool,
                            text: tool?.toolType?.name,
                            condition: torchDurationString ? torchDurationString : getCondition(tool),
                            hintClassName: 'quantity',
                            hint:tool.quantity || 1,
                            weight: this.getWeight(tool, 'toolType'),
                            indentation: 2,
                            actions: {
                                ...takeAllOption,
                                'SPACE': {
                                    callback: (option) => (this.props.isSelectItemsShowing === 'tools' ? this.onEquipTool(option) : this.onToolClick(option)),
                                    text: this.props.isSelectItemsShowing === 'tools' ? 'Equip' : 'Pick up'
                                }
                            }
                        }
                    )
                }),
                actions: {
                    ...menuOptions
                }
            }

            const metals = {
                text: 'Metals',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Metals.${tile._id}`) > -1 ? [] : tile.metals.map((metal, index) => {
                    return {
                        ...metal,
                        text: metal?.metalType?.name,
                        condition: getCondition(metal),
                        hintClassName: 'quantity',
                            hint:metal.quantity || 1,
                        weight: this.getWeight(metal, 'metalType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.onMetalClick(option)),
                                text: 'Pick up'
                            }
                        }

                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const keys = {
                text: 'Keys',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Keys.${tile._id}`) > -1 ? [] : tile.keys.map((key, index) => {
                    return {
                        ...key,
                        text: `${key?.keyType?.name}(${getKeyDescription(key)})`,
                        condition: getCondition(key),
                        hintClassName: 'quantity',
                            hint:key.quantity || 1,
                        weight: this.getWeight(key, 'keyType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.onKeyClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const writingSurfaces = {
                text: 'Writing Surfaces',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Writing Surfaces.${tile._id}`) > -1 ? [] : tile.writingSurfaces.map((writingSurface, index) => {
                    return {
                        ...writingSurface,
                        text: writingSurface?.writingSurfaceType?.name,
                        condition: getCondition(writingSurface),
                        hintClassName: 'quantity',
                            hint:writingSurface.quantity || 1,
                        weight: this.getWeight(writingSurface, 'writingSurfaceType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.onWritingSurfaceClick(option)),
                                text: 'Pick up'
                            },
                            '\\': {
                                callback: (lock) => (setTimeout(() => (this.onLookAtWritingSurface(lock)))),
                                text: 'Look'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const writingImplements = {
                text: 'Writing Implements',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Writing Implements.${tile._id}`) > -1 ? [] : tile.writingImplements.map((writingImplement, index) => {
                    return {
                        ...writingImplement,
                        text: writingImplement?.writingImplementType?.name,
                        condition: getCondition(writingImplement),
                        hintClassName: 'quantity',
                            hint:writingImplement.quantity || 1,
                        weight: this.getWeight(writingImplement, 'writingImplementType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.onWritingImplementClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const weapons = {
                text: 'Weapons',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Weapons.${tile._id}`) > -1 ? [] : tile.weapons.map((weapon, index) => {
                    const combatValues = `${weapon.weaponType?.damage}ϴ ${weapon.weaponType?.toHit}ϰ ${weapon.weaponType?.penetration}➚`
                    const text = `${weapon?.weaponType?.name} ${combatValues}`
                    return {
                        ...weapon,
                        text,
                        condition: getCondition(weapon),
                        hintClassName: 'quantity',
                            hint:weapon.quantity || 1,
                        weight: this.getWeight(weapon, 'weaponType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.props.isSelectItemsShowing === 'weapons' ? this.onEquipWeapon(option) : this.onWeaponClick(option)),
                                text: this.props.isSelectItemsShowing === 'weapons' ? 'Equip' : 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const armours = {
                text: 'Armour',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Armour.${tile._id}`) > -1 ? [] : tile.armour.map((armour) => {
                    const combatValues = `${armour.armourType?.armour}❤ ${armour.armourType?.dodge}❥`
                    const text = `${armour?.armourType?.name} ${combatValues}`
                    return {
                        ...armour,
                        text,
                        condition: getCondition(armour),
                        hintClassName: 'quantity',
                            hint:armour.quantity || 1,
                        weight: this.getWeight(armour, 'armourType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.props.isSelectItemsShowing !== 'tile' ? this.onEquipArmour(option) : this.onArmourClick(option)),
                                text: this.props.isSelectItemsShowing !== 'tile' ? 'Equip' : 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const clothing = {
                text: 'Clothing',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Clothing.${tile._id}`) > -1 ? [] : tile.clothing.map((clothing, index) => {
                    const combatValues = `${clothing.clothingType?.armour}❤ ${clothing.clothingType?.dodge}❥`
                    const text = `${clothing?.clothingType?.name} ${combatValues}`
                    return {
                        ...clothing,
                        text,
                        condition: getCondition(clothing),
                        hintClassName: 'quantity',
                            hint:clothing.quantity || 1,
                        weight: this.getWeight(clothing, 'clothingType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.props.isSelectItemsShowing !== 'tile' ? this.onEquipClothing(option) : this.onClothingClick(option)),
                                text: this.props.isSelectItemsShowing !== 'tile' ? 'Equip' : 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const jewellery = {
                text: 'Jewellery',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Jewellery.${tile._id}`) > -1 ? [] : tile.jewellery.map((jewellery, index) => {
                    return {
                        ...jewellery,
                        text: jewellery?.jewelleryType?.name,
                        condition: getCondition(jewellery),
                        hintClassName: 'quantity',
                        hint:jewellery.quantity || 1,
                        weight: this.getWeight(jewellery, 'jewelleryType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.props.isSelectItemsShowing !== 'tile' ? this.onEquipJewellery(option) : this.onJewelleryClick(option)),
                                text: this.props.isSelectItemsShowing !== 'tile' ? 'Equip' : 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const tents = {
                text: 'Tents',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Tents.${tile._id}`) > -1 ? [] : tile.tents.map((tent, index) => {
                    return {
                        ...tent,
                        text: tent?.tentType?.name,
                        condition: getCondition(tent),
                        hintClassName: 'quantity',
                        hint:tent.quantity || 1,
                        weight: this.getWeight(tent, 'tentType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.onTentClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const locks = {
                text: 'Locks',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Locks.${tile._id}`) > -1 ? [] : tile.locks.filter(lock => (!lock.isInstalled)).map((lock, index) => {
                    return {
                        ...lock,
                        text: lock?.lockType?.name,
                        condition: getCondition(lock),
                        hintClassName: 'quantity',
                        hint:lock.quantity || 1,
                        weight: this.getWeight(lock, 'lockType'),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => (this.onLockClick(option)),
                                text: 'Pick up'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const tiles = {
                text: 'Books',
                parentId: tile._id,
                childOptions: tile.tiles?.map((tile, index) => {
                    return {
                        ...tile,
                        text: tile?.tileType?.name,
                        condition: getCondition(tile),
                        hintClassName: 'quantity',
                        hint:tile.quantity || 1,
                        weight: '?u', //TODO
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onTileClick(option)),
                                text: 'Pick up'
                            },
                            '\\': {
                                callback: (option) => (setTimeout(() => (this.onLookAtWritingSurface({ ...option, serviceName: 'tile-instances' })))),
                                text: 'Read'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const characters = {
                text: 'Characters',
                parentId: tile._id,
                childOptions: tile.characters?.map((character, index) => {
                    return {
                        ...character,
                        text: character?.name,
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => {
                                    if (this.props.isSelectItemsShowing === 'nearbyCharactersOrAnimals') {
                                        colyClient.room.send('doSuperAction', {
                                            name: 'fight',
                                            data: {
                                                ...this.props.moving,
                                                z: this.props.character.z,
                                                defenderType: option.defenderType,
                                                defenderId: option._id,
                                                defenderIds: option.characterIds || option.animalIds
                                            }
                                        })
                                        return;
                                    }

                                    this.props.showCharacterActions({ focussedCharacterId: option._id })
                                },
                                text: 'Choose'
                            },
                        }
                    }
                }) || []
            }

            const animals = {
                text: 'Animals',
                parentId: tile._id,
                childOptions: tile.animals?.map((animal, index) => {
                    return {
                        ...animal,
                        text: animal?.animal?.name.toLowerCase(),
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => {
                                    colyClient.room.send('doSuperAction', {
                                        name: 'fight',
                                        data: {
                                            ...this.props.moving,
                                            z: this.props.character.z,
                                            defenderType: 'animal-instances',
                                            defenderId: option.id,
                                        }
                                    })
                                    setTimeout(store.dispatch(hideAllMenus()))
                                },
                                text: 'Choose'
                            },
                        }
                    }
                }) || []
            }

            const zones = {
                text: 'Zones',
                parentId: tile._id,
                childOptions: tile.zones?.map((zone, index) => {
                    return {
                        ...zone,
                        text: zone?.name,
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => {
                                    setTimeout(() => this.props.startAreaSelect({ isStartOfAreaSelect: true, position: this.props.character.position, areaSelectType: 'haul', zone: option, selectedCharacterId: this.props.selectedCharacterId }))
                                },
                                text: 'Select Area to Haul'
                            },
                        }
                    }
                }) || []
            }

            const resourceTypes = {
                text: 'Resource Types',
                parentId: tile._id,
                childOptions: tile.resourceTypes?.map((resourceType, index) => {
                    return {
                        ...resourceType,
                        text: resourceType?.name,
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => {
                                    setTimeout(() => this.props.startAreaSelect({ isStartOfAreaSelect: true, position: this.props.character.position, areaSelectType: 'gather', zone: resourceType, selectedCharacterId: this.props.selectedCharacterId }))
                                },
                                text: 'Select Resource Type to Gather'
                            },
                        }
                    }
                }) || []
            }

            const locations = {
                text: 'Locations',
                parentId: tile._id,
                childOptions: tile.locations?.map((location, index) => {
                    return {
                        ...location,
                        text: location?.locationName,
                        indentation: 2,
                        actions: {
                            ...takeAllOption,
                            'SPACE': {
                                callback: (option) => {
                                    colyClient.room.send('doSuperAction', {
                                        name: 'move',
                                        data: {
                                            panelId: option.panelId,
                                            position: this.props.character.position,
                                            z: option.z || 0,
                                        }
                                    })
                                    setTimeout(store.dispatch(hideAllMenus()))
                                },
                                text: 'Select Location to Move To'
                            },
                        }
                    }
                }) || []
            }

            const combineReducer = (combinedTools, toolInstance, index) => {
                const existingTool = combinedTools.find(tool => (tool.text === toolInstance.text && tool.graffiti === toolInstance.graffiti && tool.condition === toolInstance.condition && tool.level === toolInstance.level))

                if (existingTool) {
                    existingTool.instances.push({ ...toolInstance })
                    existingTool.hint++;
                } else {
                    combinedTools.push({
                        ...toolInstance,
                        instances: [ { ...toolInstance } ],
                        hint: toolInstance.quantity || 1
                    })
                }

                return combinedTools
            }

            let options = [
                { ...materials, indentation: 1 },
                ...materials.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...foods, indentation: 1 },
                ...foods.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...minerals, indentation: 1 },
                ...minerals.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...tools, indentation: 1 },
                ...tools.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...metals, indentation: 1 },
                ...metals.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...keys, indentation: 1 },
                ...keys.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...writingSurfaces, indentation: 1 },
                ...writingSurfaces.childOptions.reduce(combineReducer, []).sort((a, b) => {
                    if (a.graffiti && a.graffiti[0] && a.graffiti[0].children && a.graffiti[0].children[0]?.text && b.graffiti && b.graffiti[0]?.children[0]?.text) {
                        return a.graffiti[0]?.children[0]?.text.localeCompare(b.graffiti[0]?.children[0]?.text)
                    }

                    return a.text?.localeCompare(b.text)
                }),
                { ...writingImplements, indentation: 1 },
                ...writingImplements.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...weapons, indentation: 1 },
                ...weapons.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...armours, indentation: 1 },
                ...armours.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...clothing, indentation: 1 },
                ...clothing.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...jewellery, indentation: 1 },
                ...jewellery.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...tents, indentation: 1 },
                ...tents.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...locks, indentation: 1 },
                ...locks.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...tiles, indentation: 1 },
                ...tiles.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...characters, indentation: 1},
                ...characters.childOptions,
                { ...animals, indentation: 1},
                ...animals.childOptions,
                { ...zones, indentation: 1},
                ...zones.childOptions,
                { ...resourceTypes, indentation: 1},
                ...resourceTypes.childOptions,
                { ...locations, indentation: 1},
                ...locations.childOptions,
            ]
            .filter(option => {
                if (option.childOptions?.length === 0 && this.state.hiddenOptions?.indexOf(`${option.text}.${option.parentId}`) === -1) {
                    return false;
                }

                return true
            });

            const installedLock = tile.locks.find(lock => (lock.isInstalled));

            let text = tile.tile?.tileType?.name === 'ITEM' ? 'Loose' : tile.tile?.tileType?.name || 'Loose'

            const actions = { ...takeAllOption }

            if (installedLock) {
                actions[','] = {
                    callback: (option) => {
                        if (installedLock.isLocked) {
                            this.props.unlockLockAsync(installedLock)
                        } else {
                            this.props.lockLockAsync(installedLock)
                        }
                    },
                    text: installedLock.isLocked ? 'Unlock' : 'Lock'
                }

                if (installedLock.lockDirection) {
                    text += ` [${installedLocklockDirection}`
                } else {
                    text += ` [${installedLocklockDirection}]`
                }
                
                if (installedLock.isLocked) {
                    text += ' [locked]'
                } else {
                    text += ' [unlocked]'
                }
            }

            options = [
                {
                    ...tile.tile,
                    text,
                    actions: tile.tile?.tileType?.name ? actions : undefined,
                    childOptions: true
                },
                ...options
            ]

            if (this.props.isSelectItemsShowing !== 'tile') {
                options = options.map(option => ({...option, indentation: 0})).filter(option => (!option.childOptions))
            }

            return options
        })

        // to enable deep level flatten use recursion with reduce and concat
        function flatDeep(arr, d = 1) {
           return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
                        : arr.slice();
        };

        tiles = flatDeep(tiles);

        tiles = this.correctShortcutKeys(tiles);

        if (this.state.searchText) {
            tiles = tiles.filter(tile => (tile.text.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1));
        }

        if (tiles.length === 0 && this.state.searchText) {
            tiles.push({
                text: 'No items found!',
                actions: {
                    '/': {
                        callback: (option) => {
                            this.setState({
                                isTextInputShowing: true
                            })
                        },
                        text: 'Search'
                    }
                }
            })
        }

        return (
            <div className="select-items">
                <p className="conversation-wizard-exit"><span className="action">Esc</span> to exit</p>
                <p className="select-items-title">{this.props.selectText || 'Select an item'}</p>

                <div className="select-items-menu-container">
                    <Menu
                        options={tiles}
                        menuContainer="select-items-menu-container"
                        disabled={this.state.isTextInputShowing}
                    />
                </div>

                {this.state.isQuantityInputShowing &&
                    <QuantityInput
                        quantity={this.state.maxQuantity}
                        onQuantitySupplied={this.state.onQuantitySupplied}
                        onQuantityClosed={this.state.onQuantityClosed}
                    />
                }

                {this.state.isTextInputShowing && 
                    <div className="search-bar">
                        <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                        <TextInput
                            text={this.state.searchText || ''}
                            onTextSubmit={(searchText) => this.setState({searchText})}
                            onTextUpdate={(searchText) => this.setState({searchText})}
                            exitKey="Escape"
                            onExitKey={() => (this.setState({isTextInputShowing: false}))}
                        />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const isSelectItemsShowing = selectIsSelectItemsShowing(state);
    const character = selectCharacter(state);
    const moving = selectMoving(state);

    let tileContents = {};
    let selectText = '';

    const selectedCharacterId = selectSelectedCharacterId(state)

    const emptyTileContents = {
        inventory:  [],
        food:  [],
        minerals:  [],
        tools:  [],
        metals:  [],
        keys:  [],
        writingSurfaces:  [],
        writingImplements:  [],
        weapons:  [],
        armour:  [],
        clothing:  [],
        jewellery:  [],
        tents:  [],
        locks:  [],
        tiles: []
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'tile') {
        const tiles = selectCurrentTiles(state, character.position);

        tiles.byId.forEach(tile => {
            tileContents[tile._id] = {
                tile,
                inventory: [ ...selectTileInventory(state, tile._id) ],
                food: [ ...selectTileFood(state, tile._id) ],
                minerals: [ ...selectTileMinerals(state, tile._id) ],
                tools: [ ...selectTileTools(state, tile._id) ],
                metals: [ ...selectTileMetals(state, tile._id) ],
                keys: [ ...selectTileKeys(state, tile._id) ],
                writingSurfaces: [ ...selectTileWritingSurfaces(state, tile._id) ],
                writingImplements: [ ...selectTileWritingImplements(state, tile._id) ],
                weapons: [ ...selectTileWeapons(state, tile._id) ],
                armour: [ ...selectTileArmour(state, tile._id) ],
                clothing: [ ...selectTileClothing(state, tile._id) ],
                jewellery: [ ...selectTileJewellery(state, tile._id) ],
                tents: [ ...selectTileTents(state, tile._id) ],
                locks: [ ...selectTileLocks(state, tile._id) ],
                tiles: [ ...selectTileTiles(state, tile._id) ],
            }
        })
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'weapons') {
        const weapons = selectCharacterWeapons(state);
        const containers = selectCharacterTiles(state);

        containers.byId.forEach(tile => {
            tileContents[tile._id] = {
                ...emptyTileContents,
                weapons:  [ ...selectTileWeapons(state, tile._id) ],
            }
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            weapons: [ { weaponType: { name: 'Fists', damage: '2d2', toHit: '0', penetration: '1' } }, ...weapons],
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'tools') {
        const tools = selectCharacterTools(state);
        const containers = selectCharacterTiles(state);

        containers.byId.forEach(tile => {
            tileContents[tile._id] = {
                ...emptyTileContents,
                tools:  [ ...selectTileTools(state, tile._id) ],
            }
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            tools: [ { toolType: { name: 'Nothing' } }, ...tools],
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'head') {
        const clothing = selectCharacterClothing(state).filter(clothing => (clothing.clothingType.bodyPart === 'head'));
        const containers = selectCharacterTiles(state);

        containers.byId.forEach(tile => {
            tileContents[tile._id] = {
                ...emptyTileContents,
                clothing:  [ ...selectTileClothing(state, tile._id).filter(clothing => (clothing.clothingType.bodyPart === 'head')) ],
            }
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            clothing: [ { clothingType: { name: 'Nothing', armour: 0, dodge: 0 } }, ...clothing],
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'body') {
        const clothing = selectCharacterClothing(state).filter(clothing => (clothing.clothingType.bodyPart === 'body'));
        const containers = selectCharacterTiles(state);

        containers.byId.forEach(tile => {
            tileContents[tile._id] = {
                ...emptyTileContents,
                clothing:  [ ...selectTileClothing(state, tile._id).filter(clothing => (clothing.clothingType.bodyPart === 'body')) ],
            }
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            clothing: [ { clothingType: { name: 'Nothing', armour: 0, dodge: 0 } }, ...clothing],
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'legs') {
        const clothing = selectCharacterClothing(state).filter(clothing => (clothing.clothingType.bodyPart === 'legs'));
        const containers = selectCharacterTiles(state);

        containers.byId.forEach(tile => {
            tileContents[tile._id] = {
                ...emptyTileContents,
                clothing:  [ ...selectTileClothing(state, tile._id).filter(clothing => (clothing.clothingType.bodyPart === 'legs')) ],
            }
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            clothing: [ { clothingType: { name: 'Nothing', armour: 0, dodge: 0 } }, ...clothing],
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'feet') {
        const clothing = selectCharacterClothing(state).filter(clothing => (clothing.clothingType.bodyPart === 'feet'));
        const containers = selectCharacterTiles(state);

        containers.byId.forEach(tile => {
            tileContents[tile._id] = {
                ...emptyTileContents,
                clothing:  [ ...selectTileClothing(state, tile._id).filter(clothing => (clothing.clothingType.bodyPart === 'feet')) ],
            }
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            clothing: [ { clothingType: { name: 'Nothing', armour: 0, dodge: 0 } }, ...clothing],
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'arm') {
        const clothing = selectCharacterClothing(state).filter(clothing => (clothing.clothingType.bodyPart === 'arm'));
        const jewellery = selectCharacterJewellery(state).filter(jewellery => (jewellery.jewelleryType.bodyPart === 'arm'));
        const containers = selectCharacterTiles(state);

        containers.byId.forEach(tile => {
            tileContents[tile._id] = {
                ...emptyTileContents,
                clothing:  [ ...selectTileClothing(state, tile._id).filter(clothing => (clothing.clothingType.bodyPart === 'arm')) ],
                jewellery: [ ...selectTileJewellery(state, tile._id).filter(jewellery => (jewellery.jewelleryType.bodyPart === 'arm')) ]
            }
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            clothing: [ { clothingType: { name: 'Nothing', armour: 0, dodge: 0 } }, ...clothing],
            jewellery: [ ...jewellery],
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'armour') {
        const armour = selectCharacterArmour(state);
        const containers = selectCharacterTiles(state);

        containers.byId.forEach(tile => {
            tileContents[tile._id] = {
                ...emptyTileContents,
                armour:  [ ...selectTileArmour(state, tile._id) ],
            }
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            armour: [ { armourType: { name: 'Nothing', armour: 0, dodge: 0 } }, ...armour],
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'nearbyCharacters') {
        const nearbyCharacters = selectNearbyCharacters(state, character);
        tileContents['loose'] = {
            ...emptyTileContents,
            characters: [ ...nearbyCharacters ],
        }

        selectText = 'Choose a character';
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'nearbyCharactersOrAnimals') {
        const characters = selectCharacters(state);
        const animals = selectOnScreenAnimals(state);
        const organisations = selectOrganisations(state);

        // TODO - remove any organisations you are a part of!
        // TODO - remove self from characters list!

        organisations.forEach(organisation => {
            const members = characters.filter(character => {
                const isAdmin = organisation.adminIds.find(adminId => (adminId === character._id))
                const isMod = organisation.modIds.find(modId => (modId === character._id))
                const isMember = organisation.memberIds.find(memberId => (memberId === character._id))

                return isAdmin || isMod || isMember;
            });
            const membersCount = members.length

            characters.unshift({
                name: `All members of '${organisation.name}' (${membersCount})`,
                organisationId: organisation._id,
                characterIds: members.map(member => (member._id)),
                defenderType: 'characters'
            })
        });

        characters.unshift({
            name: `All animals (${animals.length})`,
            animalIds: animals.map(animal => (animal.id)),
            defenderType: 'animal-instances'
        })

        tileContents['loose'] = {
            ...emptyTileContents,
            characters: [ ...characters.map(character => ({ ...character, defenderType: character.defenderType || 'characters' })) ],
            animals: [ ...animals.map(animal => ({ ...animal, defenderType: 'animal-instances' })) ],
        }

        selectText = 'Choose a character or animal to fight';
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'zones') {
        const zones = selectCharacterZones(state, character._id);

        tileContents['loose'] = {
            ...emptyTileContents,
            zones: [ ...zones ]
        }

        selectText = 'Choose a stockpile you wish to haul goods to';

        if (zones.length === 0) {
            selectText = 'You need to define a stockpile first';
        }
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'namedLocations') {
        const namedLocations = selectCharacterPanels(state);

        tileContents['loose'] = {
            ...emptyTileContents,
            locations: [ ...namedLocations ]
        }

        selectText = 'Choose a location you wish to move to';
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'resources') {
        const resourceTypes = [
            { name: 'Wood or Components', },
            { name: 'Brush', },
            { name: 'Fruit & Vegetables', },
            { name: 'Minerals', },
            { name: 'Farm Plot', },
            { name: 'Plant Seeds', },
            { name: 'Tend Plants', },
            { name: 'Fuel Light', },
            { name: 'Repair Constructions', },
            { name: 'Damage Constructions', },
            { name: 'Haul Furniture', },
            { name: 'Disown Furniture', },
            // { name: 'Bury Body', }, //TODO
            // { name: 'Dig Irrigation Channel', }, //TODO
        ]

        tileContents['loose'] = {
            ...emptyTileContents,
            resourceTypes: [ ...resourceTypes ]
        }

        selectText = 'Choose a resource type you wish to gather';
    }

    if (isSelectItemsShowing && isSelectItemsShowing === 'itemSearch') {
        selectText = 'Search your stockpiles for an item';

        const zones = selectCharacterZones(state, character._id)
        const allTilePositions = zones.map(zone => {
            let tilePositions = [];

            if (zone.panelAreas) {
                const { x, y, width, height } = zone.panelAreas[0]

                for (let _y=y; _y < y + height; _y++) {
                    for (let _x=x; _x < x + width; _x++) {
                        tilePositions.push({ x: _x, y: _y })
                    }
                }
            }

            return tilePositions
        }).flat();
        const tiles = selectTilesByPosition(state, allTilePositions);
        
        // TODO - just tiles within stockpiles, rather than ALL
        // TODO - ability to search

        tiles.forEach(tile => {
            tileContents[tile._id] = {
                tile,
                inventory: [ ...selectTileInventory(state, tile._id) ],
                food: [ ...selectTileFood(state, tile._id) ],
                minerals: [ ...selectTileMinerals(state, tile._id) ],
                tools: [ ...selectTileTools(state, tile._id) ],
                metals: [ ...selectTileMetals(state, tile._id) ],
                keys: [ ...selectTileKeys(state, tile._id) ],
                writingSurfaces: [ ...selectTileWritingSurfaces(state, tile._id) ],
                writingImplements: [ ...selectTileWritingImplements(state, tile._id) ],
                weapons: [ ...selectTileWeapons(state, tile._id) ],
                armour: [ ...selectTileArmour(state, tile._id) ],
                clothing: [ ...selectTileClothing(state, tile._id) ],
                jewellery: [ ...selectTileJewellery(state, tile._id) ],
                tents: [ ...selectTileTents(state, tile._id) ],
                locks: [ ...selectTileLocks(state, tile._id) ],
                tiles: [ ...selectTileTiles(state, tile._id) ],
            }
        })
    }

    const serverTimeOffset = selectServerTimeOffset(state);

    return { serverTimeOffset, character, isSelectItemsShowing, tileContents, selectText, selectedCharacterId, moving }
}

export default connect(
    mapStateToProps,
    {
        addFoodToCharacterAsync,
        addMineralToCharacterAsync,
        addToolToCharacterAsync,
        addMetalToCharacterAsync,
        addKeyToCharacterAsync,
        addWritingSurfaceToCharacterAsync,
        addWritingImplementToCharacterAsync,
        addWeaponToCharacterAsync,
        addArmourToCharacterAsync,
        addClothingToCharacterAsync,
        addJewelleryToCharacterAsync,
        addTentToCharacterAsync,
        addTileToCharacterAsync,
        addLockToCharacterAsync,
        addMaterialToCharacterAsync,
        showQuantityInput,
        hideQuantityInput,
        equipWeaponAsync,
        equipArmourAsync,
        equipToolAsync,
        equipClothingAsync,
        unequipClothingAsync,
        equipJewelleryAsync,
        unequipJewelleryAsync,
        showCharacterActions,
        showWritingSurface,
        showDrawingSurface,
        unlockLockAsync,
        lockLockAsync,
        startAreaSelect,
    }
)(SelectItems);