import store from '../redux/store';

import {
    openInventory,
    closeInventory,
    closeCrafting,
    openCrafting,
    closeActions,
    openActions,
    hideCraftingError,
    hideMovingError,
    hideUnknownError,
    hideHelp,
    hideAllMenus,
    startLooking,
    stopLooking,
    startAiming,
    startAreaSelect,
    stopAiming,
    startMoving,
    stopMoving,
    stopAreaSelect,
    initialisingPanelStarted,
    showUnknownError
} from '../redux/actions/keyboard-shortcuts.actions';
import { beginUpdatePosition, updatePositionAsync, setTentDirection, setWorkshopDirection } from '../redux/actions/character.actions';
import { beginUpdateEmbarkPosition, updateEmbarkPositionAsync } from '../redux/actions/embark-character.actions';
import { beginUpdateBoat } from '../redux/actions/boat.actions';
import { beginUpdateAnimal } from '../redux/actions/animal.actions';
import { beginUpdateWagon } from '../redux/actions/wagon.actions';
import { createMessageSuccess } from '../redux/actions/messages.actions';
import {
    selectCharacter,
    selectPanel,
    selectIsKeyboardMovementEnabled,
    selectIsCraftingShowing,
    selectIsInventoryShowing,
    selectIsUnknownErrorShowing,
    selectIsHelpShowing,
    selectIsInitialisingPanel,
    selectHighlight,
    selectPlants,
    selectNeighbouringBoats,
    selectBoats,
    selectAnimals,
    selectWagons,
    selectAiming,
    selectAreaSelect,
    selectMoving,
    selectBrainchipTypeByIds,
    selectInventoryWeight,
    selectMaximumInventoryWeight,
    selectMineWalls,
    selectConnectionState,
    selectConstructions,
    selectLocks,
    selectCurrentTiles,
    selectNearbyCharacters,
    selectSelectedCharacterId
} from '../redux/selectors';
import { PANEL_WIDTH, PANEL_HEIGHT } from './geography';
import { config } from '../components/containers/game/Game'
import { BRAINCHIP_TYPE_NAMES } from './Tilemap.service';
import tileMap from './tile-map'

import { BLOCKING_PLANT_KEYS } from './blocking-plants';

const DOOR_INDEX = tileMap.DOOR;
const WALL_TYPES = [
    'NONE',
    'LEFT.',
    'LEFT.RIGHT.',
    'LEFT.RIGHT.2',
    'LEFT.RIGHT.3',
    'LEFT.RIGHT.4',
    'LEFT.RIGHT.5',
    'LEFT.RIGHT.6',
    'LEFT.RIGHT.TOP.',
    'LEFT.RIGHT.TOP.BOTTOM.',
    'LEFT.RIGHT.BOTTOM.',
    'LEFT.TOP.',
    'LEFT.TOP.BOTTOM.',
    'LEFT.BOTTOM.',
    'RIGHT.',
    'RIGHT.TOP.',
    'RIGHT.TOP.BOTTOM.',
    'RIGHT.BOTTOM.',
    'TOP.',
    'TOP.BOTTOM.',
    'BOTTOM.'
]

const TILE_WIDTH = 16;
const TILE_HEIGHT = 24;

const MOVEMENT_THROTTLE_TIME = 0;

const MAX_PANEL_MOVEMENT_AMOUNT = 9;

let animationIndex = 0;

class MovementService {
    isInputListenerActive;

    isUpdatingCharacterPosition = false;

    character;
    currentPanelId;
    isCraftingShowing;
    isInventoryShowing;
    isUnknownErrorShowing;
    isHelpShowing;
    plants;
    panel;
    interval;

    WALL_INDICES = [
        tileMap['NONE'],
        tileMap['LEFT.'],
        tileMap['LEFT.RIGHT.'],
        tileMap['LEFT.RIGHT.TOP.'],
        tileMap['LEFT.RIGHT.TOP.BOTTOM.'],
        tileMap['LEFT.RIGHT.BOTTOM.'],
        tileMap['LEFT.TOP.'],
        tileMap['LEFT.TOP.BOTTOM.'],
        tileMap['LEFT.BOTTOM.'],
        tileMap['RIGHT.'],
        tileMap['RIGHT.TOP.'],
        tileMap['RIGHT.TOP.BOTTOM.'],
        tileMap['RIGHT.BOTTOM.'],
        tileMap['TOP.'],
        tileMap['TOP.BOTTOM.'],
        tileMap['BOTTOM.'],
        // Geyser (wet tundra)
        tileMap['GEYSER.RIGHT.BOTTOM.'],
        tileMap['GEYSER.LEFT.BOTTOM.'],
        tileMap['GEYSER.RIGHT.TOP.'],
        tileMap['GEYSER.LEFT.TOP.'],
        // Slab (the slabs)
        tileMap['SLABS.SLOPE.LEFT.BOTTOM.'],
        tileMap['SLABS.SLOPE.LEFT.TOP.BOTTOM.'],
        tileMap['SLABS.SLOPE.RIGHT.TOP.'],
        tileMap['SLABS.SLOPE.LEFT.RIGHT.TOP.'],
        tileMap['SLABS.SLOPE.LEFT.TOP.'],
        // Mine walls
        tileMap['MINE-WALL.BOTTOM.'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BR-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BR-TL-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BL-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-BL-GAP'], 
        tileMap['MINE-WALL.LEFT.TOP.'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BR-BL-GAP'], 
        tileMap['MINE-WALL.RIGHT.TOP.'], 
        tileMap['MINE-WALL.RIGHT.BOTTOM.BR-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.BOTTOM.BR-BL-GAP'], 
        tileMap['MINE-WALL.LEFT.BOTTOM.BL-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.BOTTOM.BR-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.BOTTOM.BL-GAP'], 
        tileMap['MINE-WALL.TOP.BOTTOM.'], 
        tileMap['MINE-WALL.LEFT.TOP.BOTTOM.'], 
        tileMap['MINE-WALL.RIGHT.TOP.BOTTOM.'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-BR-GAP'], 
        tileMap['MINE-WALL.RIGHT.TOP.BOTTOM.BR-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BL-TL-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BL-TL-GAP'], 
        tileMap['MINE-WALL.RIGHT.TOP.BOTTOM.TR-BR-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-BR-BL-TL-GAP'], 
        tileMap['MINE-WALL.LEFT.TOP.BOTTOM.BL-TL-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.TL-GAP'], 
        tileMap['MINE-WALL.RIGHT.TOP.BOTTOM.TR-GAP'], 
        tileMap['MINE-WALL.LEFT.'], 
        tileMap['MINE-WALL.LEFT.RIGHT.'], 
        tileMap['MINE-WALL.RIGHT.'], 
        tileMap['MINE-WALL.TOP.'], 
        tileMap['MINE-WALL.NONE'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.BOTTOM.'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TL-GAP'], 
        tileMap['MINE-WALL.LEFT.BOTTOM.'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-TL-GAP'], 
        tileMap['MINE-WALL.RIGHT.BOTTOM.'], 
        tileMap['MINE-WALL.RIGHT.TOP.TR-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.TR-TL-GAP'], 
        tileMap['MINE-WALL.LEFT.RIGHT.TOP.TR-GAP'], 
        tileMap['MINE-WALL.LEFT.TOP.TL-GAP'], 
        tileMap['MINE-WALL.LEFT.TOP.BOTTOM.TL-GAP'], 
        tileMap['MINE-WALL.LEFT.TOP.BOTTOM.BL-GAP'], 
    ];

    constructor(scene, options) {
        if (options.isEmbarkWaitingRoom) {
            this.isEmbarkWaitingRoom = true;
        }

        if (options.isCutscene) {
            WALL_TYPES.forEach(wallType => this.WALL_INDICES.push(tileMap[`SPACE.${wallType}`]))
            WALL_TYPES.forEach(wallType => this.WALL_INDICES.push(tileMap[`DYSTOPIA.${wallType}`]))
            this.WALL_INDICES.push(18);
            this.WALL_INDICES.push(6);
        }
    }

    getStoreValues() {
        const state = store.getState();

        this.character = selectCharacter(state);
        this.nearbyCharacters = selectNearbyCharacters(state, this.character);
        
        this.neighbouringBoats = selectNeighbouringBoats(state, this.character);
        this.boats = selectBoats(state)
        this.animals = selectAnimals(state);
        this.wagons = selectWagons(state);
        this.isPassenger = this.boats.find(boat => (boat.passengerIds?.find(id => (id === this.character._id))))
        this.mineWalls = selectMineWalls(state)
        this.constructions = Object.values(selectConstructions(state).byId);
        this.locks = selectLocks(state);

        this.inventoryWeight = selectInventoryWeight(state);
        this.maximumInventoryWeight = selectMaximumInventoryWeight(state);

        this.isDrivingBoat = this.boats.find(boat => (boat.driverId === this.character._id))
        this.isDrivingAnimal = this.animals.find(animal => (animal.targetId === this.character._id && (animal.currentBehaviour === 'FOLLOWING' || animal.currentBehaviour === 'CARRYING')));
        this.isDrivingWagon = this.wagons.find(wagon => (wagon._id === this.isDrivingAnimal?.wagonId));
        this.isInputListenerActive = selectIsKeyboardMovementEnabled(state);
        this.isCraftingShowing = selectIsCraftingShowing(state);
        this.isInventoryShowing = selectIsInventoryShowing(state);
        this.isUnknownErrorShowing = selectIsUnknownErrorShowing(state);
        this.isHelpShowing = selectIsHelpShowing(state);
        this.isInitialisingPanel = selectIsInitialisingPanel(state);
        this.isLooking = selectHighlight(state);
        this.isAiming = selectAiming(state);
        this.isAreaSelect = selectAreaSelect(state);
        this.isMoving = selectMoving(state);
        this.plants = Object.values(selectPlants(state).byId);
        this.panel = selectPanel(state);
        this.connectionState = selectConnectionState(state);
        this.currentTiles = selectCurrentTiles(state, this.character.position);
        this.selectedCharacterId = selectSelectedCharacterId(state);

        const currentActiveBrainchips = selectBrainchipTypeByIds(state, this.character.activeBrainchipTypeIds)

        this.isPhase = false;
        if (currentActiveBrainchips.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.PHASE))) {
            this.isPhase = true;
        }

        this.isWebwalk = false;
        if (currentActiveBrainchips.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.WEB_SPITTER || brainchipType.name === BRAINCHIP_TYPE_NAMES.WEB_RESIST))) {
            this.isWebwalk = true;
        }

        if (this.isLooking) {
            this.character.position = { ...this.isLooking }
        }

        if (this.isAiming) {
            this.character.position = { ...this.isAiming }
        }

        if (this.isAreaSelect) {
            this.character.position = { ...this.isAreaSelect }
        }

        if (this.isMoving) {
            this.character.position = { ...this.isMoving }
        }
    }

    onDestroy() {
        clearInterval(this.interval);
    }

    handleLeft(scene) {
        const tilePosition = this.getTilePosition();

        const targetPosition = { x: this.character.position.x - 1, y: this.character.position.y}
        const currentPosition = { ...this.character.position }
        const newTilePosition = { x: tilePosition.x - TILE_WIDTH, y: tilePosition.y }

        this.handleDirection(scene, targetPosition, currentPosition, newTilePosition, 'left')
    }

    handleRight(scene) {
        const tilePosition = this.getTilePosition();

        const targetPosition = { x: this.character.position.x + 1, y: this.character.position.y}
        const currentPosition = { ...this.character.position }
        const newTilePosition = { x: tilePosition.x + TILE_WIDTH, y: tilePosition.y }

        this.handleDirection(scene, targetPosition, currentPosition, newTilePosition, 'right')
    }

    handleUp(scene) {
        const tilePosition = this.getTilePosition();

        const targetPosition = { x: this.character.position.x, y: this.character.position.y - 1}
        const currentPosition = { ...this.character.position }
        const newTilePosition = { x: tilePosition.x, y: tilePosition.y - TILE_HEIGHT }

        this.handleDirection(scene, targetPosition, currentPosition, newTilePosition, 'up')
    }

    handleUpLeft(scene) {
        const tilePosition = this.getTilePosition();

        const targetPosition = { x: this.character.position.x - 1, y: this.character.position.y - 1}
        const currentPosition = { ...this.character.position }
        const newTilePosition = { x: tilePosition.x - TILE_WIDTH, y: tilePosition.y - TILE_HEIGHT }

        this.handleDirection(scene, targetPosition, currentPosition, newTilePosition, 'up')
    }

    handleUpRight(scene) {
        const tilePosition = this.getTilePosition();

        const targetPosition = { x: this.character.position.x + 1, y: this.character.position.y - 1}
        const currentPosition = { ...this.character.position }
        const newTilePosition = { x: tilePosition.x + TILE_WIDTH, y: tilePosition.y - TILE_HEIGHT }

        this.handleDirection(scene, targetPosition, currentPosition, newTilePosition, 'up')
    }

    handleDown(scene) {
        const tilePosition = this.getTilePosition();

        const targetPosition = { x: this.character.position.x, y: this.character.position.y + 1}
        const currentPosition = { ...this.character.position }
        const newTilePosition = { x: tilePosition.x, y: tilePosition.y + TILE_HEIGHT }

        this.handleDirection(scene, targetPosition, currentPosition, newTilePosition, 'down')
    }

    handleDownLeft(scene) {
        const tilePosition = this.getTilePosition();

        const targetPosition = { x: this.character.position.x - 1, y: this.character.position.y + 1}
        const currentPosition = { ...this.character.position }
        const newTilePosition = { x: tilePosition.x - TILE_WIDTH, y: tilePosition.y + TILE_HEIGHT }

        this.handleDirection(scene, targetPosition, currentPosition, newTilePosition, 'down')
    }

    handleDownRight(scene) {
        const tilePosition = this.getTilePosition();

        const targetPosition = { x: this.character.position.x + 1, y: this.character.position.y + 1}
        const currentPosition = { ...this.character.position }
        const newTilePosition = { x: tilePosition.x + TILE_WIDTH, y: tilePosition.y + TILE_HEIGHT }

        this.handleDirection(scene, targetPosition, currentPosition, newTilePosition, 'down')
    }

    handleDirection(scene, targetPosition, currentPosition, tilePosition, targetDirectionString) {
        if (this.isThrottling) {
            return;
        } else {
            this.isThrottling = true;

            setTimeout(() => {
                this.isThrottling = false;
            }, MOVEMENT_THROTTLE_TIME)
        }

        if (this.character.healthPoints <= 0 && !this.isLooking) {
            store.dispatch(showUnknownError({ message: 'Can\'t walk whilst in near-death state' }))
            return;
        }

        if (this.connectionState !== undefined && this.connectionState !== 'CONNECTED') {
            return;
        }

        if (this.character.tentId) {
            this.setTentDirection(targetDirectionString);
            return;
        }

        const isCliff = this.isCliff(this.character.z, targetPosition.x, targetPosition.y);

        if (isCliff) {
            return;
        }

        const isWaterAtTarget = this.isWater(this.character.z, targetPosition.x, targetPosition.y);
        const isWaterAtCurrent = this.isWater(this.character.z, currentPosition.x, currentPosition.y);

        const isWater = isWaterAtTarget && isWaterAtCurrent

        if (!this.isDrivingBoat && isWaterAtTarget && isWaterAtCurrent) {
            return;
        }

        if (this.isDrivingBoat && !isWaterAtTarget && !isWaterAtCurrent) {
            return
        }

        var tile;
        
        if (this.character.z < 0) {
            tile = scene.floor.getTileAtWorldXY(tilePosition.x, tilePosition.y, true);
        } else {
            tile = scene.walls.getTileAtWorldXY(tilePosition.x, tilePosition.y, true);
        }

        let isWall = (tile && this.isWall(tile.index))

        if (!isWall && scene.plants) {
            tile = scene.plants.getTileAtWorldXY(tilePosition.x, tilePosition.y, true);
            isWall = !!(tile && this.isWall(tile.index))
        }

        const isPlant = this.isBlockingPlantOrMineWall(targetPosition.x, targetPosition.y)

        if ((!isWall && !isPlant && !isCliff && (!isWater || this.isDrivingBoat)) && this.isInputListenerActive && !this.isInitialisingPanel) {
            this.dispatchUpdatePositionAction(targetPosition.x, targetPosition.y);
        }
    }

    setTentDirection(tentDirection) {
        store.dispatch(setTentDirection({ tentDirection }))
    }

    setWorkshopDirection(workshopDirection) {
        store.dispatch(setWorkshopDirection({ workshopDirection }))
    }

    createInputListeners(scene) {
        let isForceAttacking = false;

        scene.input.keyboard.on('keydown-SHIFT', () => {
            isForceAttacking = true;
        })

        scene.input.keyboard.on('keyup-SHIFT', () => {
            isForceAttacking = false;
        })

        scene.input.keyboard.on('keydown', (event) => {
            this.getStoreValues();

            if (!this.character?._id) {
                return;
            }
            if (isForceAttacking || !this.isInputListenerActive) {
                return;
            }

            if (event.key === 'ArrowUp') {
                this.handleUp(scene);
            }
            if (event.key === 'ArrowDown') {
                this.handleDown(scene);
            }
            if (event.key === 'ArrowLeft') {
                this.handleLeft(scene);
            }
            if (event.key === 'ArrowRight') {
                this.handleRight(scene);
            }

            if (event.key === ']') {
                event.preventDefault();
                this.handleUpRight(scene)
            }

            if (event.key === '[') {
                event.preventDefault();
                this.handleUpLeft(scene)
            }

            if (event.key === "'") {
                event.preventDefault();
                this.handleDownRight(scene)
            }

            if (event.key === ';') {
                event.preventDefault();
                this.handleDownLeft(scene)
            }
        })
        //  Left
        // scene.input.keyboard.on('keydown-A', (event) => this.handleLeft(scene));

        // Right
        // scene.input.keyboard.on('keydown-D', (event) => this.handleRight(scene));

        //  Up
        // scene.input.keyboard.on('keydown-W', (event) => this.handleUp(scene));

        //  Down
        // scene.input.keyboard.on('keydown-S', (event) => this.handleDown(scene));

        // Taps
        scene.input.on('pointerdown', (event) => {
            if (isForceAttacking) {
                return;
            }

            document.activeElement.blur();

            if (document.body.clientWidth > 1200) {
                return;
            }

            const topLeft = [0, 0];
            const topRight = [config.scale.width, 0];
            const centre = [(config.scale.width / 2), (config.scale.height / 2)];
            const bottomLeft = [0, config.scale.height];
            const bottomRight = [config.scale.width, config.scale.height];

            const topTriangle = [...topLeft, ...centre, ...topRight];
            const leftTriangle = [...topLeft, ...centre, ...bottomLeft];
            const rightTriangle = [...topRight, ...centre, ...bottomRight];
            const bottomTriangle = [...bottomLeft, ...centre, ...bottomRight];

            const clickPosition = [event.worldX, event.worldY];

            if (isInside(...topTriangle, ...clickPosition)) {
                this.handleUp(scene)
            }

            if (isInside(...leftTriangle, ...clickPosition)) {
                this.handleLeft(scene)
            }

            if (isInside(...rightTriangle, ...clickPosition)) {
                this.handleRight(scene)
            }

            if (isInside(...bottomTriangle, ...clickPosition)) {
                this.handleDown(scene)
            }

            store.dispatch(hideCraftingError());
            store.dispatch(hideMovingError());
            store.dispatch(hideUnknownError());

            if (this.isHelpShowing) {
                store.dispatch(hideHelp());
            }

            if (!this.isCraftingShowing && !this.isInventoryShowing && this.isUnknownErrorShowing) {
                store.dispatch(hideAllMenus());
            }
        });
    }

    getTilePosition() {
        if (!this.character) {
            throw new Error('Character not found');
            return;
        }

        return {
            x: (this.character.position.x * TILE_WIDTH),
            y: (this.character.position.y * TILE_HEIGHT)
        }
    }

    dispatchUpdatePositionAction(x, y) {
        // if (this.inventoryWeight > this.maximumInventoryWeight && !this.isDrivingBoat && !this.isDrivingAnimal && !this.isDrivingWagon) {
        //     store.dispatch(showUnknownError({ message: 'You cannot walk as you are holding too much stuff' }));
        //     return;
        // }

        if (x < -1 || y < -1 || x > PANEL_WIDTH + 1 || y > PANEL_HEIGHT + 1) {
            if (!this.isMoving) {
                return;
            }
        }

        if (x === -1 || y === -1 || x === PANEL_WIDTH || y === PANEL_HEIGHT) {
            if (this.isLooking || this.isAiming || this.isAreaSelect) {
                return;
            }
        }

        if (this.isLooking) {
            store.dispatch(startLooking({ position: { x, y } }))
            return;
        }

        if (this.isAiming) {
            store.dispatch(startAiming({ type: this.isAiming.type, position: { x, y } }))
            return;
        }

        if (this.isAreaSelect) {
            if (this.isAreaSelect.isDisabled) {
                // TODO - Show message that you cant alter zone
                return;
            }
            store.dispatch(startAreaSelect({ type: this.isAreaSelect.type, position: { x, y }, selectedCharacterId: this.selectedCharacterId }))
            return;
        }

        if (this.isMoving) {
            if ((x < 0 || x >= PANEL_WIDTH) && (y < 0 || y >= PANEL_HEIGHT)) {
                return;
            }
            // We do not allow movement through keypress any more!

            if (x < 0) {
                x = Math.max((-1 * MAX_PANEL_MOVEMENT_AMOUNT), x)
            }

            if (y < 0) {
                y = Math.max((-1 * MAX_PANEL_MOVEMENT_AMOUNT), y)
            }

            if (x > PANEL_WIDTH) {
                x = Math.min(MAX_PANEL_MOVEMENT_AMOUNT + PANEL_WIDTH - 1, x)
            }

            if (y > PANEL_HEIGHT) {
                y = Math.min(MAX_PANEL_MOVEMENT_AMOUNT + PANEL_HEIGHT - 1, y)
            }

            store.dispatch(
                startMoving({
                    type: this.isMoving.type,
                    position: {
                        x,
                        y
                    }
                })
            )
            return;
        }

        if (this.isPassenger) {
            // allow passengers that have fallen from the boat to try and move
            const passengerBoat = this.isPassenger
            const boatPositions = passengerBoat.boatType.horizontalBoundries.map(boundry => ({ x: boundry.x + passengerBoat.position.x, y: boundry.y + passengerBoat.position.y }))

            const isPassengerInsideBoat = boatPositions.find(boatPosition => (boatPosition.x === this.character.position.x && boatPosition.y === this.character.position.y));

            if (isPassengerInsideBoat) {
                // store.dispatch(showUnknownError({ message: 'Cant walk whilst passenger of a boat' }))
                return;
            }
        }

        if (this.isEmbarkWaitingRoom) {
            store.dispatch(beginUpdateEmbarkPosition({...this.character}));
            store.dispatch(updateEmbarkPositionAsync({
                ...this.character,
                position: { x, y }
            }))
            return;
        }

        if (!this.isLooking && !this.isAiming && !this.isMoving && !this.isAreaSelect) {
            const oldCharacterPosition = { ...this.character.position };

            if (this.isCharacterStuckInPlace()) {
                store.dispatch(updatePositionAsync({...this.character}));
            } else {
                // We do not allow movement through keypress any more!
                // store.dispatch(updatePositionAsync({
                //     ...this.character,
                //     position: { x, y },
                //     boat: this.isDrivingBoat,
                //     animal: this.isDrivingAnimal,
                //     wagon: this.isDrivingWagon
                // }))
            }

            if (this.isDrivingBoat) {
                const newCharacterPosition = { x, y }
                const positionChange = { x: newCharacterPosition.x - oldCharacterPosition.x, y: newCharacterPosition.y - oldCharacterPosition.y }
                const oldBoatPosition = this.isDrivingBoat.position;
                // We want all of these movement methods to walk through the m key as well!
                // store.dispatch(beginUpdateBoat({
                //     ...this.isDrivingBoat,
                //     position: { x: oldBoatPosition.x + positionChange.x, y: oldBoatPosition.y + positionChange.y }
                // }))
            }

            if (this.isDrivingWagon) {
                const newCharacterPosition = { x, y }
                const positionChange = { x: newCharacterPosition.x - oldCharacterPosition.x, y: newCharacterPosition.y - oldCharacterPosition.y }
                const oldWagonPosition = this.isDrivingWagon.position;
                // We want all of these movement methods to walk through the m key as well!
                // store.dispatch(beginUpdateWagon({
                //     ...this.isDrivingWagon,
                //     position: { x: oldWagonPosition.x + positionChange.x, y: oldWagonPosition.y + positionChange.y }
                // }))
            }

            if (this.isDrivingAnimal) {
                const newCharacterPosition = { x, y }
                const positionChange = { x: newCharacterPosition.x - oldCharacterPosition.x, y: newCharacterPosition.y - oldCharacterPosition.y }
                const oldBoatPosition = this.isDrivingAnimal.position;
                // We want all of these movement methods to walk through the m key as well!
                // store.dispatch(beginUpdateAnimal({
                //     ...this.isDrivingAnimal,
                //     position: { x: oldBoatPosition.x + positionChange.x, y: oldBoatPosition.y + positionChange.y }
                // }))

                // todo - update passenger position
            }
        }
    }

    isCliff(z, x, y) {
        if (z < 0) {
            return false;
        }

        if (this.isLooking || this.isAiming || this.isMoving || this.isAreaSelect) {
            return false;
        }
        return this.panel.floorLayer.find(tile => (tile.x === x && tile.y === y && (tile.tileType === 'Cliff' || tile.tileType === 'MOUNTAIN')))
    }

    isWater(z, x, y) {
        // return false if inventory is empty
        if (this.inventoryWeight === 0 && !this.isDrivingBoat) {
            return false;
        }

        if (this.isLooking && this.isDrivingBoat) {
            return true
        }

        if (this.panel.isOcean) {
            return true;
        }

        // return false if there is a bridge
        const currentConstruction = this.constructions.find(construction => (construction.position.x === x && construction.position.y === y));
        const isBridge = currentConstruction?.construction?.name?.toLowerCase().indexOf('bridge') > -1;

        const isBoat = this.boats.find(boat => {
            return boat.boatType.horizontalBoundries.find(boundry => {
                const position = { x: boundry.x + boat.position.x, y: boundry.y + boat.position.y }

                return position.x === x && position.y === y
            })
        })

        if (isBoat) {
            return false;
        }

        if (isBridge) {
            return false;
        }

        if (z < 0) {
            return false;
        }

        if (this.neighbouringBoats && this.neighbouringBoats.length > 0 && !this.isDrivingBoat) {
            return false;
        }

        if (this.isLooking || this.isAiming || this.isMoving || this.isAreaSelect) {
            return false;
        }

        if (x < 0 || y < 0 || x >= PANEL_WIDTH || y >= PANEL_HEIGHT) {
            return true;
        }

        return this.panel.floorLayer.find(tile => (tile.x === x && tile.y === y && (tile.tileType === 'OCEAN' || tile.tileType  === 'WATER')))
    }

    isCharacterStuckInPlace() {
        return this.character.isProne ||
            (this.currentTiles.byId.find(tile => (tile.tileType?.name === 'Web')) && !this.isWebwalk)
    }

    isWall(index) {
        if (this.isPhase) {
            return false;
        }

        if (this.isLooking || this.isAiming || this.isMoving || this.isAreaSelect) {
            return false;
        }

        if (index === undefined) {
            return false;
        }

        if (this.WALL_INDICES.indexOf(index) > -1) {
            return true;
        }

        const tileMapIndex = Object.values(tileMap).indexOf(index)

        if (!Object.keys(tileMap)[tileMapIndex]) {
            return false;
        }

        const tileString = Object.keys(tileMap)[tileMapIndex].toLowerCase();

        if (tileString.indexOf('wall') > -1 || tileString.indexOf('window') > -1) {
            // We can walk on some walls :)
            if (tileString.indexOf('broken') > -1 || tileString.indexOf('destroyed') > -1) {
                return false;
            }

            return true;
        }

        return false;
    }

    isBlockingPlantOrMineWall(x, y) {
        const blockAnimals = [
            'BROWN_BEAR',
            'TIGER',
            'LEOPARD',
            'BLACK_BEAR',
            'POLAR_BEAR',
            'LION',
            'JAGUAR',
            'WOLF',
            'NIGHT_STALKER',
            'SNAPJAW',
            'POISONER',
            'SWARMER',
            'TROLL',
            'SCARY',
            'SHOOTER',
            'SWAPPER',
            'RUSTER',
            'AOE',
            'PHASER',
            'PHASED_SHOOTER',
            'DISARMER',
            'LIFE_LEECH',
            'SLUGGISHER',
            'HP_HALVER',
            'TELEPORTER',
            'HORN_BLOWER',
            'BEASTIE_SPAWNER',
            'DEATH_SONG',
            'TELEPORT_LEVEL',
            'BLINDNESSER',
            'WONEYED_BEAST',
            'WEB_SHOOTER',
            'ACID_SPLASH',
            'FIRE_SHOOTER',
            'SLIME_BEAST',
            'MAGMA_CROC',
            'KRAKEN'
        ]

        if (this.isLooking || this.isAiming || this.isMoving || this.isAreaSelect) {
            return false;
        }

        const plant = this.plants.find(plant => plant.position.x === x && plant.position.y === y);

        const mineWall = this.mineWalls.find(mineWall => (mineWall.position.x === x && mineWall.position.y === y))

        const isTreadingOnAnimal = this.animals.find(animal => (animal.position.x === x && animal.position.y === y))
        if (isTreadingOnAnimal) {
            if (isTreadingOnAnimal.position.x < 0 || isTreadingOnAnimal.position.x === PANEL_WIDTH || isTreadingOnAnimal.position.y < 0 || isTreadingOnAnimal.position.y === PANEL_HEIGHT) {

            } else {
                if (blockAnimals.indexOf(isTreadingOnAnimal.animal?.name) > -1) {
                    return true;
                }
            }
        }


        // so barricades, you can stand on the door but cant go through
        const currentConstruction = this.constructions.find(construction => (construction.position.x === this.character.position.x && construction.position.y === this.character.position.y));
        const targetConstruction = this.constructions.find(construction => (construction.position.x === x && construction.position.y === y));

        if (currentConstruction) {
            const lock = this.locks.find(lock => (lock.constructionId === currentConstruction?._id))

            const travelX = this.character.position.x - x;
            const travelY = this.character.position.y - y;

            const verticalDirection = travelY < 0 ? 'south' : travelY > 0 ? 'north' : '';
            const horizontalDirection = travelX < 0 ? 'east' : travelX > 0 ? 'west' : '';

            if (lock?.isLocked && (lock.lockDirection === verticalDirection || lock.lockDirection === horizontalDirection)) {
                return true;
            }
        }

        if (targetConstruction) {
            const lock = this.locks.find(lock => (lock.constructionId === targetConstruction?._id))

            const travelX = this.character.position.x - x;
            const travelY = this.character.position.y - y;

            const verticalDirection = travelY < 0 ? 'north' : travelY > 0 ? 'south' : '';
            const horizontalDirection = travelX < 0 ? 'west' : travelX > 0 ? 'east' : '';

            if (lock?.isLocked && (!lock.lockDirection || (lock.lockDirection === verticalDirection || lock.lockDirection === horizontalDirection))) {
                return true;
            }
        }

        if (mineWall) {
            return true;
        }

        if (!plant) {
            return false;
        }

        if (this.isDrivingBoat) {
            return true;
        }

        return BLOCKING_PLANT_KEYS.indexOf(plant.tileKey) > -1
    }
}


//https://www.geeksforgeeks.org/check-whether-a-given-point-lies-inside-a-triangle-or-not/
function areaOfTriangle(x1, y1, x2, y2, x3, y3) {
    return Math.abs((x1*(y2-y3) + x2*(y3-y1)+ x3*(y1-y2))/2.0);
}

/* A function to check whether point P(x, y) lies inside the triangle formed
by A(x1, y1), B(x2, y2) and C(x3, y3) */
function isInside(x1, y1, x2, y2, x3, y3, x, y)
{
    /* Calculate area of triangle ABC */
    let A = areaOfTriangle(x1, y1, x2, y2, x3, y3);
     
    /* Calculate area of triangle PBC */
    let A1 = areaOfTriangle(x, y, x2, y2, x3, y3);
     
    /* Calculate area of triangle PAC */
    let A2 = areaOfTriangle(x1, y1, x, y, x3, y3);
     
    /* Calculate area of triangle PAB */   
    let A3 = areaOfTriangle(x1, y1, x2, y2, x, y);
     
    /* Check if sum of A1, A2 and A3 is same as A */
    return (A == A1 + A2 + A3);
}


export default MovementService;