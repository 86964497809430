import {
    GET_MESSAGES_LOADING,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAILURE,
    GET_MORE_MESSAGES_LOADING,
    GET_MORE_MESSAGES_SUCCESS,
    GET_MORE_MESSAGES_FAILURE,
    CREATE_MESSAGE_LOADING,
    CREATE_MESSAGE_SUCCESS,
    CREATE_MESSAGE_FAILURE,
    POINT_AT_SUCCESS,
    ADD_NEW_MESSAGE,
    ADD_MESSAGE_OPTIONS,
    CLEAR_MESSAGE_OPTIONS
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const getMessagesSuccess = payload => ({
    type: GET_MESSAGES_SUCCESS,
    payload
})

export const getMessagesFailure = () => ({
    type: GET_MESSAGES_FAILURE
})

export const getFurnitureMessagesAsync = payload => dispatch => {
    return client.service('messages').find({
          query: {
            $limit: 100,
            $sort: {
              createdAt: 1
            },
            $skip: payload?.skip || 0,
            targetFurnitureId: payload?.targetFurnitureId,
          }
        })
        .then((response) => {
            console.log('w', response)
            dispatch(getMoreMessagesSuccess(response))
            return response.data;
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError())
        });
}

export const getMessagesAsync = payload => dispatch => {
    const { characterId } = payload

    return client.service('messages').find({
          query: {
            characterIds: characterId,
            $limit: 100,
            $sort: {
              createdAt: -1
            },
            $skip: payload?.skip || 0
          }
        })
        .then((response) => {
            dispatch(getMessagesSuccess(response))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError())
        });
}

export const getMoreMessagesSuccess = payload => ({
    type: GET_MORE_MESSAGES_SUCCESS,
    payload
})

export const getMoreMessagesFailure = () => ({
    type: GET_MESSAGES_FAILURE
})

export const getMoreMessagesAsync = payload => dispatch => {
    const { characterId } = payload

    return client.service('messages').find({
          query: {
            characterId,
            $limit: 10,
            $sort: {
              createdAt: -1
            },
            $skip: payload?.skip || 0
          }
        })
        .then((response) => {
            dispatch(getMoreMessagesSuccess(response))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError())
        });
}

export const loadCharacterMessagesAsync = payload => dispatch => {
    const { characterId, targetCharacterId, targetFurnitureId } = payload

    if (targetFurnitureId) {
        return client.service('messages').find({
          query: {
            targetFurnitureId,
            $limit: 10,
            $sort: {
              createdAt: -1
            },
            $skip: payload?.skip || 0
          }
        })
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError())
        });
    }

    return client.service('messages').find({
          query: {
            $or: [
                { characterId, targetCharacterId },
                { targetCharacterId: characterId, characterId: targetCharacterId },
            ],
            $limit: 10,
            $sort: {
              createdAt: -1
            },
            $skip: payload?.skip || 0
          }
        })
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError())
        });
}

export const createMessageSuccess = payload => ({
    type: CREATE_MESSAGE_SUCCESS,
    payload
})

export const createMessageFailure = () => ({
    type: CREATE_MESSAGE_FAILURE
})

export const createMessageAsync = payload => dispatch => {
    const { text, targetCharacterId, targetFurnitureId, targetCharacterName, characterName } = payload;

    console.log('targetCharacterName: ', targetCharacterName);

    return Promise.resolve(colyClient.room.send('doAction', {
        serviceName: 'messages',
        commandType: 'create',
        data: {
            text,
            targetCharacterId,
            targetCharacterName,
            characterName,
            targetFurnitureId,
            type: targetCharacterId ? 'TALK_TO_CHARACTER' : targetFurnitureId ? 'TALK_TO_FURNITURE' : 'TALK_TO_WORLD'
        }
    }))
        .then((response) => {
            dispatch(createMessageSuccess({
                ...response,
                targetCharacterName,
                characterName
            }))
            return response;
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError())
        });
}

export const playInstrumentAsync = payload => dispatch => {
    const { text, targetCharacterId, targetFurnitureId, targetCharacterName, characterName } = payload;

    return Promise.resolve(colyClient.room.send('doAction', {
        serviceName: 'messages',
        commandType: 'create',
        data: {
            text,
            type: 'PLAY_INSTRUMENT'
        }
    }))
    .catch((error) => {
        console.error('error: ', error);
        dispatch(showUnknownError())
    });
}

export const pointAtSuccess = payload => ({
    type: POINT_AT_SUCCESS
})

export const pointAtAsync = payload => dispatch => {
    const { text, position } = payload;

    return Promise.resolve(colyClient.room.send('doAction', {
        serviceName: 'messages',
        commandType: 'create',
        data: {
            text: 'pointed somewhere',
            type: 'POINTING',
            position
        }
    }))
        .then((response) => {
            dispatch(pointAtSuccess(response))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError())
        });
}

export const createNewMessage = payload => ({
    type: ADD_NEW_MESSAGE,
    payload
})

export const addMessageOptions = payload => ({
    type: ADD_MESSAGE_OPTIONS,
    payload
})

export const clearMessageOptions = payload => ({
    type: CLEAR_MESSAGE_OPTIONS,
    payload
})
