import React, { Component, useState } from "react";
import { connect } from 'react-redux'
import './Startup.css';
import KeyboardMenu from '../keyboard-menu/KeyboardMenu';
import Menu from '../utils/menu/Menu';
import { logOutAsync, startNewGame } from '../../redux/actions/user.actions';
import { getCharacterAsync, newCharacterAsync, changeCharacter } from '../../redux/actions/character.actions';
import { initialisingPanelStarted, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import { getUserBedsAsync } from '../../redux/actions/furniture.actions';
import { getCharacterPanelsAsync } from '../../redux/actions/character-panel.actions';
import {
    selectIsInitialisingApp,
    selectIsInitialisingPanel,
    selectPossibleCharacters,
    selectUser,
    selectCharacterPanels
} from '../../redux/selectors';

class Startup extends React.Component {
    state = {
        isBedSelectShowing: false,
        availableBeds: [],
        navigationHandler: this.navigationHandler.bind(this)
    }

	constructor() {
		super();
	}

	start(command, print) {
		this.props.startNewGame();
	}

	selectCharacter(character) {
		if (character.text === 'New Character') {
			// TODO now 
			console.log(this.props.characters);
			if (this.props.characters.length === 0) {
				// IF you have no characters, just make the call
				this.props.newCharacterAsync({ userId: this.props.user.user._id })
			} else {
				// IF you have characters, get list of unassigned beds.
				this.props.getUserBedsAsync({ userId: this.props.user.user._id })
					.then((userBeds) => {
						if (userBeds.length <= this.props.characters.length) {
							this.setState({
								isBedSelectShowing: true,
								availableBeds: [],
							})
							return;
						}

						let unassignedBeds = userBeds.filter(bed => (!bed.ownerCharacterId))

						unassignedBeds = unassignedBeds.map(bed => {
							const panel = this.props.characterPanels.find(characterPanel => (characterPanel.panelId === bed.panelId))
							let text = 'Unknown location'

							if (panel) {
								text = panel.locationName
							}

							text += ` [${bed.position.x} ${bed.position.y}]`

							return {
								...bed,
								text
							}
						})

						this.setState({
							isBedSelectShowing: true,
							availableBeds: unassignedBeds
						})
					});
			}
			return;
		}

		if (character.text === 'Log Out') {
			this.logout()
			return;
		}

		if (character._id === localStorage.getItem('characterId')) {
			this.props.hideAllMenus();
			return;
		}

		localStorage.setItem('characterId', character._id);


		this.props.changeCharacter();
		this.props.initialisingPanelStarted();
		this.props.getCharacterAsync({ userId: character.userId });
	}

	selectBed(bed) {
		console.log('here we go pal: ', bed)
		this.props.newCharacterAsync({ userId: this.props.user.user._id, bedId: bed._id })

		this.setState({
			isBedSelectShowing: false
		});
	}

    navigationHandler(event) {
    	console.log('wow!', event.code);
        if (event.code === 'Escape' && this.state.isBedSelectShowing) {
            this.setState({
                isBedSelectShowing: false
            });

            event.preventDefault();
            return;
        }
    }

	componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
        this.props.getCharacterPanelsAsync({ userId: this.props.user.user._id })
    }

	continue(command, print) {
		console.log('continue');
	}

	settings(command, print) {
		console.log('settings');
	}

	logout(command, print) {
        localStorage['feathers-jwt'] = null;
        this.props.logOutAsync();
        window.location.reload();
	}

	render() {
	    return (
	      	<div className="startup">
	      		<p className="header">[ <span className="title">Settle Gliese</span> ]</p>
	      		<div className="options-container">
	      			{
	      				this.state.isBedSelectShowing ? (

		      				this.state.availableBeds.length > 0 ? (
				      			<Menu menuContainer="options-container"
				      				optionChosen={(bed) => (this.selectBed(bed))}
				      				options={[
				      					...this.state.availableBeds.map(bed => ({
				      						...bed,
				      					})),
				      				]
				      			}/>
	      					): (
	      						<p>To spawn a new character, you need more beds than alive characters, and have at least one bed unassigned.</p>
	      					)
      					) : (
			      			<Menu menuContainer="options-container"
			      				optionChosen={(character) => (this.selectCharacter(character))}
			      				options={[
			      					...this.props.characters.map(character => ({
			      						...character,
			      						text: character.name,
			      					})),
			      					{
			      						text: "New Character",
			      						callback: this.start.bind(this)
			      					},
			      					{
			      						text: "Log Out",
			      						callback: this.start.bind(this)
			      					},
			      				]
			      			}/>
      					)
	      			}
	      			<p className="footer">Copyright (<span className="action">c</span>) 2024 Harry Bishop</p>
	      		</div>
      		</div>
	    );
	}
}

const mapStateToProps = state => {
    const isInitialisingApp = selectIsInitialisingApp(state);
    const isInitialisingPanel = selectIsInitialisingPanel(state);

    const isInitialising = isInitialisingApp || isInitialisingPanel

    const user = selectUser(state);
    const characters = selectPossibleCharacters(state);
    const characterPanels = selectCharacterPanels(state);

    return { isInitialising, characters, user, characterPanels }
}

export default connect(
    mapStateToProps,
    {
    	logOutAsync,
    	startNewGame,
    	getCharacterAsync,
    	initialisingPanelStarted,
    	newCharacterAsync,
    	changeCharacter,
    	hideAllMenus,
    	getUserBedsAsync,
    	getCharacterPanelsAsync
    }
)(Startup);