import {
    LOG_IN_LOADING,
    LOG_IN_SUCCESS,
    LOG_IN_FAILURE,
    BACK_TO_START_PAGE,
    START_NEW_GAME,
    SEE_AN_ADVERT,
    RULES,
    CREATE_CHARACTER,
    START_INTRO,
    CHOOSE_EMBARK,
    CREATE_NEW_EMBARK,
    SHOW_CHARACTER_DEATH_PAGE,
    USER_BED_COUNT_SUCCESS,
} from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const logInSuccess = payload => {
    return ({
        type: LOG_IN_SUCCESS,
        payload
    })
}

export const logInFailure = payload => ({
    type: LOG_IN_FAILURE
})

export const logInAsync = payload => dispatch => {
    const { email, password } = payload;

    return client.authenticate({
        strategy: 'local',
        email,
        password
    })
        .then(response => {
            console.log('logged in baby: ', response);
            dispatch(logInSuccess(response));
            return response;
        })
        .catch(error => {
            console.log(error);
            throw new Error(error);
        });
}

export const logOutAsync = payload => dispatch => {
    return client.logout();
}

export const logInLocalStorageAsync = () => dispatch => {
    return client.authenticate()
        .then(response => {
            dispatch(logInSuccess(response));
            return response;
        })
        .catch(error => {
            console.log(error);
            throw new Error(error);
        });
}

export const backToStartPage = payload => ({
    type: BACK_TO_START_PAGE
})

export const startNewGame = payload => ({
    type: START_NEW_GAME
})

export const seeAnAdvert = payload => ({
    type: SEE_AN_ADVERT
})

export const rules = payload => ({
    type: RULES
})

export const createCharacter = payload => ({
    type: CREATE_CHARACTER
})

export const startIntro = payload => ({
    type: START_INTRO
})

export const chooseEmbark = payload => ({
    type: CHOOSE_EMBARK
})

export const createNewEmbark = payload => ({
    type: CREATE_NEW_EMBARK
})

export const showCharacterDeathPage = payload => ({
    type: SHOW_CHARACTER_DEATH_PAGE
})

export const hideCharacterDeathPage = payload => ({
    type: HIDE_CHARACTER_DEATH_PAGE
})

export const userBedCountSuccess = payload => ({
    type: USER_BED_COUNT_SUCCESS,
    payload
})