import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { loadCharacterMessagesAsync, getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { getCharacterMessagesAsync } from '../../redux/actions/character-messages.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPanel,
    selectCharacterMessages,
    selectEmbarkCharacterMessages,
    selectTotalMessages,
    selectCharacter,
    selectCharacters,
    selectIsMessagesFocussed,
    selectFocussedCharacterId,
    selectMessageOptionss,
    selectEmbarkCharacter,
    selectCurrentEmbarkGroup,
    selectCharacterMessagePairs
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { serialize } from '../side-bar/looking/Looking';

import { client } from '../../services/client';

import './Messages.css';

// DISABLE DEFAULT SCROLL?

class Messages extends React.Component {
    state = {
        text: "",
        isFetching: false,
        isMessagesSet: false,
        handleNavigation: this.handleNavigation.bind(this),
        isEmptyFocussedCharacterShowing: false,
        messages: {
            data: []
        }
    };

    handleInput = event => {
        let isLastMessageFocussed, focussedCharacterMessages;

        if (this.state.highlight?.characterId) {
            focussedCharacterMessages = this.state.messages.data
            isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length;
        }

        if (focussedCharacterMessages && !isLastMessageFocussed) {
            return;
        }

        if (event.nativeEvent.inputType === 'insertLineBreak') {
            this.submitMessage();
        } else {
            this.setState({ text: event.target.value });
        }
    };

    disableMovement = () => {
        store.dispatch(disableKeyboardMovement());
    }

    enableMovement = () => {
        store.dispatch(enableKeyboardMovement());
    }

    submitMessage = async () => {
        const message = await this.props.createMessageAsync({
            text: this.state.text,
            targetCharacterId: this.state.highlight.characterId === 'undefined' ? undefined : this.state.highlight.characterId,
            targetCharacterName: this.props.characterMessages.find(charMess => charMess.targetCharacterId === this.state.highlight.characterId).targetCharacterName,
            targetFurnitureId: this.state.highlight.furnitureId
        })

        this.loadMessages();

        this.setState({ text: '' });

        document.activeElement.blur();
    };

    openHelp = () => {
        store.dispatch(showHelp());
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.state.handleNavigation)
    }

    async componentDidMount() {
        window.addEventListener('keydown', this.state.handleNavigation);

        const characterMessages = await store.dispatch(getCharacterMessagesAsync({ characterId: this.props.character._id }))

        if (characterMessages.length > 0) {
            const characterMessage = characterMessages[0];

            const messages = await store.dispatch(loadCharacterMessagesAsync({
                characterId: characterMessage.characterId,
                targetCharacterId: characterMessage.targetCharacterId,
                targetFurnitureId: characterMessage.targetFurnitureId,
            }))

            if (messages) {
                this.setState({
                    messages: {
                        ...messages,
                        data: messages.data.reverse()
                    },
                    highlight: {
                        type: 'messages',
                        characterId: characterMessage.targetCharacterId,
                        messageIndex: messages?.data?.length,
                        furnitureId: characterMessage.targetFurnitureId,
                        // isEmptyFocussedCharacterShowing: !!state.messages[focussedCharacterId]
                    }
                })

                setTimeout(() => (this.correctScrollPosition()), 1000);
            }
        }

        const focussedCharacterMessages = this.state.messages.data
        const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages?.length;

        if (isLastMessageFocussed) {
            const textareaElement = document.getElementsByClassName('new-message')[0]

            if (!textareaElement) {
                return;
            }

            setTimeout(() => {
                textareaElement?.focus()
                setTimeout(() => (textareaElement.value = ''), 10)
            })
        }

        setTimeout(() => (this.correctScrollPosition()));
    }

    async handleUpArrow() {
        console.log('ok');
        if (this.state.highlight.type === 'messages') {
            console.log('ok2');
            this.setState({
                highlight: {
                    ...this.state.highlight,
                    messageIndex: this.state.highlight.messageIndex === 0 ? 0 : this.state.highlight.messageIndex - 1
                }
            })

            if (this.state.highlight.messageIndex === 0 && !this.state.isLoading) {
                this.setState({
                    isLoading: true
                })

                const moreMessages = await store.dispatch(loadCharacterMessagesAsync({
                    characterId: this.props.character._id,
                    targetCharacterId: this.state.highlight?.characterId,
                    targetFurnitureId: this.state.highlight?.furnitureId,
                    skip: this.state.messages.data.length
                }))

                this.setState({
                    isLoading: false
                })

                this.setState({
                    messages: {
                        ...moreMessages,
                        data: [ ...moreMessages.data.reverse(), ...this.state.messages.data ]
                    },
                    highlight: {
                        ...this.state.highlight,
                        messageIndex: this.state.highlight.messageIndex + moreMessages.data.length
                    }
                })

                setTimeout(() => this.correctScrollPosition());
            }

            document.activeElement.blur();
        }

        if (this.state.highlight.type === 'characters') {
            const characterMessageIndex = this.props.characterMessages
                .findIndex((characterMessage) => (characterMessage.targetCharacterId === this.state.highlight.characterId && characterMessage.targetFurnitureId === this.state.highlight.furnitureId));
            const nextIndex = Math.max(0, characterMessageIndex - 1)

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    characterId: this.props.characterMessages[nextIndex].targetCharacterId,
                    furnitureId: this.props.characterMessages[nextIndex].targetFurnitureId,
                }
            })

            await this.loadMessages();
        }
    }

    async handleDownArrow() {
        if (this.state.highlight.type === 'messages') {
            const messageOptionsCount = this.state.messages.length ? this.state.messages.length - 1 : 0;
            const focussedCharacterMessages = this.state.messages.data
            const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length;

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    messageIndex: isLastMessageFocussed ? this.state.highlight.messageIndex : this.state.highlight.messageIndex + 1
                }
            })
        }

        if (this.state.highlight.type === 'characters') {
            const characterMessageIndex = this.props.characterMessages
                .findIndex((characterMessage) => (characterMessage.targetCharacterId === this.state.highlight.characterId && characterMessage.targetFurnitureId === this.state.highlight.furnitureId));
            const nextIndex = Math.min(this.props.characterMessages.length - 1, characterMessageIndex + 1)

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    characterId: this.props.characterMessages[nextIndex].targetCharacterId,
                    furnitureId: this.props.characterMessages[nextIndex].targetFurnitureId,
                }
            })

            await this.loadMessages();
        }
    }

    handleRightArrow() {
        if (this.state.highlight.type === 'messages') {
            return;
        }

        const focussedCharacterMessages = this.state.messages.data

        this.setState({
            highlight: {
                ...this.state.highlight,
                type: 'messages',
                messageIndex: focussedCharacterMessages.length
            }
        })
    }

    handleLeftArrow() {
        if (this.state.highlight.type === 'characters') {
            return;
        }

        this.setState({
            highlight: {
                ...this.state.highlight,
                type: 'characters',
            }
        })
    }

    correctScrollPosition() {
        const highlighted = document.getElementsByClassName('highlighted')[0]

        const messagesList = document.getElementsByClassName('messages-list')[0]
        const charactersList = document.getElementsByClassName('characters-list')[0]

        if (!messagesList) {
            return;
        }

        const isElementAboveTop = highlighted?.offsetTop < messagesList.scrollTop
        const isElementBelowBottom = highlighted?.offsetTop > messagesList.offsetHeight + messagesList.scrollTop

        if (this.state.highlight.type === 'messages' && (isElementAboveTop || isElementBelowBottom)) {
            messagesList.scrollTop = highlighted?.offsetTop - 20;
        }

        if (this.state.highlight.type === 'characters') {
            messagesList.scrollTop = 1000000;

            const isElementAboveTop = highlighted?.offsetTop < charactersList.scrollTop
            const isElementBelowBottom = highlighted?.offsetTop > charactersList.offsetHeight + charactersList.scrollTop

            if (isElementAboveTop || isElementBelowBottom) {
                charactersList.scrollTop = highlighted?.offsetTop - 20;
            }
        }
    }

    focusTextareaOnNavigation() {
        if (this.state.highlight.type === 'messages') {
            const isMessageOptions = this.props.messageOptions?.length > 0;
            const focussedCharacterMessages = this.state.messages.data
            const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length;

            if (!isMessageOptions && isLastMessageFocussed) {
                const textareaElement = document.getElementsByClassName('new-message')[0]
                textareaElement?.focus()
            }
        }
    }

    handleNavigation(event) {
        console.log(this.state.highlight)
        // if (!this.state.highlight.characterId && !this.state.highlight.furnitureId) {
        //     return;
        // }

        if (event.key === 'ArrowUp') {
            this.handleUpArrow();
        }

        if (event.key === 'ArrowDown') {
            this.handleDownArrow();
        }

        if (event.key === 'ArrowLeft') {
            this.handleLeftArrow();
        }

        if (event.key === 'ArrowRight') {
            this.handleRightArrow();
        }

        setTimeout(() => {
            this.correctScrollPosition();
            this.focusTextareaOnNavigation();
        })
    }

    async loadMessages() {
        const messages = await store.dispatch(loadCharacterMessagesAsync({
            characterId: this.props.character._id,
            targetCharacterId: this.state.highlight?.characterId,
            targetFurnitureId: this.state.highlight?.furnitureId
        }))

        if (!messages){
            return;
        }

        this.setState({
            messages: {
                ...messages,
                data: messages.data.reverse()
            }
        })

        this.correctScrollPosition();
    }

    formatDistance(result, number) {
        result = result.replace('Minutes', 'm');
        result = result.replace('Hours', 'h');
        result = result.replace('Days', 'd');
        result = result.replace('Months', 'mo');
        result = result.replace('Years', 'y');

        result = result.replace('lessThan', '<')
        result = result.replace('about', '');
        result = result.replace('over', '>');

        result = result.replace('x', number)
        result = result.replace('X', number)

        return result;
    }

    render() {
        const characters = this.props.characterMessages?.map((characterMessage, index) => {
            const isHighlighted = this.state.highlight?.type === 'characters' && this.state.highlight?.characterId === characterMessage.targetCharacterId;
            const isSelected = characterMessage.targetCharacterId === this.state.highlight?.characterId;

            let className = 'character';

            if (isHighlighted) {
                className += ' highlighted';
            }

            if (isSelected) {
                className += ' selected';
            }

            let graffiti;

            if (characterMessage.targetFurnitureName) {
                graffiti = serialize({ children: characterMessage.targetFurnitureName })
            }

            return (
                <div className={className} key={index} >
                    <span className={graffiti ? "graffiti": "hidden"}>
                        {graffiti}
                    </span>
                    <span className="name">{characterMessage?.targetCharacterName}</span>
                </div>
            )
        })

        const messages = this.state.messages?.data
            .map((message, index) => {
                const isHighlighted = this.state.highlight?.type === 'messages' && this.state.highlight?.messageIndex === index;
                const characterId = message.characterId || message.embarkCharacterId;
                const isReader = this.props.character._id === characterId;

                let classNames = "chat-message";

                if (isHighlighted) {
                    classNames += " highlighted"
                }

                if (isReader) {
                    classNames += " reader-message"
                } else {
                    classNames += " non-reader-message"
                }

                return (
                    <li key={index} className={classNames}>
                        <span className={"message-name"}>
                            {message.characterName}
                        </span>
                        <span>
                            &nbsp;{formatDistance(new Date(message.createdAt), new Date(), { locale: { formatDistance: this.formatDistance.bind(this) } })}&nbsp;
                        </span>
                        <div className={characterId ? "messages-text-not-sidebar message-text" : "messages-text-not-sidebar system-message-text"}>
                            {message.text}
                        </div>
                    </li>
                )
            })

        let textareaClassName = 'new-message';

        return (
            <div>
                <div className="messages">
                    <ul className="characters-list">
                        {characters}
                    </ul>
                    <ul className="messages-list">
                        {messages}

                        <div>
                            <span
                                className="speak link"
                                onClick={this.submitMessage}
                                style={{display: this.state.text ? 'block' : 'none'}}
                            >Speak</span>

                            {
                                this.props.messageOptions?.length > 0 ? 
                                    messageOptions :
                                    <textarea
                                        value={this.state.text}
                                        className={textareaClassName}
                                        onChange={this.handleInput}
                                        placeholder="Say something"
                                    />
                            }
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let isEmbarkMessages = false;

    const panel = selectPanel(state);
    const embarkMessages = selectEmbarkCharacterMessages(state);

    const character = selectCharacter(state)

    const characterMessages = selectCharacterMessagePairs(state);

    return {
        panel,
        character: isEmbarkMessages ? embarkCharacter : character,
        characterMessages
    }
}

export default connect(
    mapStateToProps,
    { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, flashCharacterColorAsync, startLooking, createNewMessage }
)(Messages);