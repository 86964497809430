import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectFocussedCharacterId,
    selectCharacterById,
    selectWeaponTypes,
    selectArmourTypes,
    selectClothingTypes,
    selectJewelleryTypes,
    selectToolTypes,
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../services/client';

import { getHealthString, getHungerString } from '../side-bar/looking/Looking';
import { BALANCE } from '../../services/balance-config';

import './Profile.css';

class Profile extends React.Component {
    constructor() {
        super();

        this.state = {
            top: 0,
            navigationHandler: this.navigationHandler.bind(this),
        }
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)

    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    navigationHandler(event) {
        const STEP_SIZE = 40;

        if (event.key === 'ArrowDown') {
            const eventsContainer = document.getElementsByClassName('profile-character-description-inner')[0]

            if (this.state.top * -1 > eventsContainer.clientHeight - (2 * STEP_SIZE)) {
                return
            }
            
            this.setState({
                top: this.state.top - STEP_SIZE
            })

            return;
        }

        if (event.key === 'ArrowUp') {
            if (this.state.top >= 0) {
                return;
            }
            // find top element and move it below visible frame
            this.setState({
                top: this.state.top + STEP_SIZE
            })

            return;
        }

        if (event.key === '<' && this.props.showLeft) {
            this.props.showLeft();
            return;
        }

        if (event.key === '>' && this.props.showRight) {
            this.props.showRight();
            return;
        }
    }

    render() {
        const head = this.props.character.clothingItems?.find(item => (item.type.bodyPart === 'head'));
        const body = this.props.character.clothingItems?.find(item => (item.type.bodyPart === 'body'));
        const leftArm = this.props.character.clothingItems?.find(item => (item.type.bodyPart === 'arm'));
        const rightArm = this.props.character.clothingItems?.find(item => (item.type.bodyPart === 'arm' && item.itemId !== leftArm.itemId ));
        const legs = this.props.character.clothingItems?.find(item => (item.type.bodyPart === 'legs'));
        const feet = this.props.character.clothingItems?.find(item => (item.type.bodyPart === 'feet'));

        return (
            <div className="profile">
                <div className="profile-sidebar">
                    <div className="profile-sidebar-inner" style={{'top': this.state.top}}>
                        <div className="status">
                            <h3>Status</h3>

                            <div className="status-container">
                                <p className="definition">HP</p>
                                <p className="term">{ getHealthString(this.props.character) }</p>
                                <p className="definition">HU</p>
                                <p className="term">{ getHungerString(this.props.character) }</p>
                            </div>
                        </div>
                    </div>

                    <div className="items">
                        <h3 className="items-header" style={{'top': this.state.top}}>Items</h3>

                        <div className="items-container" style={{'top': this.state.top}}>
                            <p className="definition">Right Hand</p>
                            <p className="term">{this.props.character.weaponType?.name ? `${this.props.character.weaponType?.name} ${this.props.character.weaponType?.toHit} ${this.props.character.weaponType?.penetration}➚` : 'Nothing'}</p>
                            <p className="definition">Left Hand</p>
                            <p className="term">{this.props.character.toolType?.name || 'Nothing'}</p>
                            <p className="definition">Head</p>
                            <p className="term">{head ? `${head?.type?.name} ${head.type?.armour}❤ ${head.type?.dodge}❥` : 'Nothing'}</p>
                            <p className="definition">Body</p>
                            <p className="term">{body ? `${body?.type?.name} ${body.type?.armour}❤ ${body.type?.dodge}❥` : 'Nothing'}</p>
                            <p className="definition">Right Arm</p>
                            <p className="term">{rightArm ? `${rightArm?.type.name} ${rightArm.type?.armour}❤ ${rightArm.type?.dodge}❥` : 'Nothing'}</p>
                            <p className="definition">Left Arm</p>
                            <p className="term">{leftArm ? `${leftArm?.type.name} ${leftArm.type?.armour}❤ ${leftArm.type?.dodge}❥` : 'Nothing'}</p>
                            <p className="definition">Legs</p>
                            <p className="term">{legs ? `${legs?.type.name} ${legs.type?.armour}❤ ${legs.type?.dodge}❥` : 'Nothing'}</p>
                            <p className="definition">Feet</p>
                            <p className="term">{feet ? `${feet?.type.name} ${feet.type?.armour}❤ ${feet.type?.dodge}❥` : 'Nothing'}</p>
                        </div>
                    </div>
                </div>

                <div className="profile-character-description">
                    <div className="profile-character-description-inner" style={{'top': this.state.top}}>
                        {/*<p className="character-description-title">Appearance</p>
                        <p>{ this.props.character.description }</p>
                        <p className="character-description-title">Background</p>
                        <p>{ this.props.character.backstory }</p>
                        <p className="character-description-title">Career</p>
                        <p>{ this.props.character.career }</p>
                        <p className="character-description-title">Conflict style</p>
                        <p>{ this.props.character.conflict }</p>
                        <p className="character-description-title">Fear</p>
                        <p>{ this.props.character.fear }</p>
                        <p className="character-description-title">Friendship style</p>
                        <p>{ this.props.character.friendship }</p>*/}
                    </div>
                </div>

                <div className={this.props.leftString ? "bottom-text" : "hidden"}>
                    [ <span className="action"> &lt; </span> {this.props.leftString} | <span className="action"> &gt; </span> {this.props.rightString}]
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state)

    const focussedCharacterId = selectFocussedCharacterId(state);
    const focussedCharacter = selectCharacterById(state, focussedCharacterId)

    const weaponTypes = selectWeaponTypes(state);
    const armourTypes = selectArmourTypes(state);
    const clothingTypes = selectClothingTypes(state);
    const jewelleryTypes = selectJewelleryTypes(state);
    const toolTypes = selectToolTypes(state);

    const profileCharacter = focussedCharacter._id ? focussedCharacter : character

    if (profileCharacter.toolTypeId && !profileCharacter.toolType) {
        profileCharacter.toolType = toolTypes.find(toolType => (toolType._id === profileCharacter.toolTypeId));
    }

    if (profileCharacter.weaponTypeId && !profileCharacter.weaponType) {
        profileCharacter.weaponType = weaponTypes.find(weaponType => (weaponType._id === profileCharacter.weaponTypeId));
    }

    if (profileCharacter.armourTypeId && !profileCharacter.armourType) {
        profileCharacter.armourType = armourTypes.find(armourType => (armourType._id === profileCharacter.armourTypeId));
    }

    if (profileCharacter.clothingItems && !!focussedCharacter._id) {
        profileCharacter.clothingItems = profileCharacter.clothingItems.map(clothingItem => ({
            ...clothingItem,
            type: clothingItem.itemType === 'clothing' ? clothingTypes.find(clothingType => (clothingType._id === clothingItem.itemTypeId)) : jewelleryTypes.find(jewelleryType => (jewelleryType._id === clothingItem.itemTypeId))
        }))
    }

    console.log(profileCharacter.clothingItems?.find(item => (item.type.bodyPart === 'head')))

    // console.log(profileCharacter)

    // TODO - get clothings, armour, weapon, tool and then display it
    // TODO - handle scroll.

    return {
        character: profileCharacter,
        isOtherCharacter: !!focussedCharacter._id
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus }
)(Profile);