import { getRandomArrayItem, getRandomCleanedArrayItem } from '../../../utils/random';
import seedrandom from 'seedrandom';

import { aloeBranchTypes, aloeLeafTypes, aloeSmellTypes } from './plants/aloe'
import { antiarisBranchTypes, antiarisLeafTypes, antiarisSmellTypes } from './plants/antiaris'
import { bambooBranchTypes, bambooLeafTypes, bambooSmellTypes } from './plants/bamboo'
import { bananaBranchTypes, bananaLeafTypes, bananaSmellTypes } from './plants/banana'
import { barleyBranchTypes, barleyLeafTypes, barleySmellTypes } from './plants/barley'
import { beanBranchTypes, beanLeafTypes, beanSmellTypes } from './plants/bean'
import { bermudaGrassBranchTypes, bermudaGrassLeafTypes, bermudaGrassSmellTypes } from './plants/bermuda-grass'
import { blackbearBerryBranchTypes, blackbearBerryLeafTypes, blackbearBerrySmellTypes } from './plants/blackbear-berry'
import { cloudberryBranchTypes, cloudberryLeafTypes, cloudberrySmellTypes } from './plants/cloudberry'
import { cottonBranchTypes, cottonLeafTypes, cottonSmellTypes } from './plants/cotton'
import { crowberryBranchTypes, crowberryLeafTypes, crowberrySmellTypes } from './plants/crowberry'
import { crustyTreeBranchTypes, crustyTreeLeafTypes, crustyTreeSmellTypes } from './plants/crusty-tree'
import { crystalWeedBranchTypes, crystalWeedLeafTypes, crystalWeedSmellTypes } from './plants/crystal-weeds'
import { crystalisedBranchTypes, crystalisedLeafTypes, crystalisedSmellTypes } from './plants/crystalised'
import { cushionPlantBranchTypes, cushionPlantLeafTypes, cushionPlantSmellTypes } from './plants/cushion-plant'
import { discordantBranchTypes, discordantLeafTypes, discordantSmellTypes } from './plants/discordant'
import { elephantGrassBranchTypes, elephantGrassLeafTypes, elephantGrassSmellTypes } from './plants/elephant-grass'
import { evergreenBranchTypes, evergreenLeafTypes, evergreenSmellTypes } from './plants/evergreen'
import { fernBranchTypes, fernLeafTypes, fernSmellTypes } from './plants/fern'
import { flaxBranchTypes, flaxLeafTypes, flaxSmellTypes } from './plants/flax'
import { fossilisedBranchTypes, fossilisedLeafTypes, fossilisedSmellTypes } from './plants/fossilised'
import { furBranchTypes, furLeafTypes, furSmellTypes } from './plants/fur'
import { gooseFootBranchTypes, gooseFootLeafTypes, gooseFootSmellTypes } from './plants/goose-foot'
import { guineaPalmBranchTypes, guineaPalmLeafTypes, guineaPalmSmellTypes } from './plants/guinea-palm'
import { hairGrassBranchTypes, hairGrassLeafTypes, hairGrassSmellTypes } from './plants/hair-grass'
import { heathBranchTypes, heathLeafTypes, heathSmellTypes } from './plants/heath'
import { inglenookBranchTypes, inglenookLeafTypes, inglenookSmellTypes } from './plants/inglenook'
import { junglePlantOneBranchTypes, junglePlantOneLeafTypes, junglePlantOneSmellTypes } from './plants/jungle-plant-1'
import { junglePlantTwoBranchTypes, junglePlantTwoLeafTypes, junglePlantTwoSmellTypes } from './plants/jungle-plant-2'
import { junglePlantThreeBranchTypes, junglePlantThreeLeafTypes, junglePlantThreeSmellTypes } from './plants/jungle-plant-3'
import { junglePlantFourBranchTypes, junglePlantFourLeafTypes, junglePlantFourSmellTypes } from './plants/jungle-plant-4'
import { junglePlantFiveBranchTypes, junglePlantFiveLeafTypes, junglePlantFiveSmellTypes } from './plants/jungle-plant-5'
import { jungleVineBranchTypes, jungleVineLeafTypes, jungleVineSmellTypes } from './plants/jungle-vine'
import { kangarooPawBranchTypes, kangarooPawLeafTypes, kangarooPawSmellTypes } from './plants/kangaroo-paw'
import { kohlrabiBranchTypes, kohlrabiLeafTypes, kohlrabiSmellTypes } from './plants/kohlrabi'
import { lichenBranchTypes, lichenLeafTypes, lichenSmellTypes } from './plants/lichen'
import { lingonberryBranchTypes, lingonberryLeafTypes, lingonberrySmellTypes } from './plants/lingonberry'
import { liverwortBranchTypes, liverwortLeafTypes, liverwortSmellTypes } from './plants/liverwort'
import { lusciousBushBranchTypes, lusciousBushLeafTypes, lusciousBushSmellTypes } from './plants/luscious-bush'
import { maizeBranchTypes, maizeLeafTypes, maizeSmellTypes } from './plants/maize'
import { mangoBranchTypes, mangoLeafTypes, mangoSmellTypes } from './plants/mango'
import { mazeScrubBranchTypes, mazeScrubLeafTypes, mazeScrubSmellTypes } from './plants/maze-scrub'
import { mountainBranchTypes, mountainLeafTypes, mountainSmellTypes } from './plants/mountain'
import { mulberryBranchTypes, mulberryLeafTypes, mulberrySmellTypes } from './plants/mulberry'
import { needlewoodGrassBranchTypes, needlewoodGrassLeafTypes, needlewoodGrassSmellTypes } from './plants/needlewood-grass'
import { needlewoodBranchTypes, needlewoodLeafTypes, needlewoodSmellTypes } from './plants/needlewood'
import { oatBranchTypes, oatLeafTypes, oatSmellTypes } from './plants/oat'
import { oldGrowthBranchTypes, oldGrowthLeafTypes, oldGrowthSmellTypes } from './plants/old-growth'
import { ordealBranchTypes, ordealLeafTypes, ordealSmellTypes } from './plants/ordeal'
import { overgrownFurBranchTypes, overgrownFurLeafTypes, overgrownFurSmellTypes } from './plants/overgrown-fur'
import { painforestBranchTypes, painforestLeafTypes, painforestSmellTypes } from './plants/painforest'
import { palmBushBranchTypes, palmBushLeafTypes, palmBushSmellTypes } from './plants/palm-bush'
import { palmBranchTypes, palmLeafTypes, palmSmellTypes } from './plants/palm'
import { pearlwortBranchTypes, pearlwortLeafTypes, pearlwortSmellTypes } from './plants/pearlwort'
import { plumeWeedBranchTypes, plumeWeedLeafTypes, plumeWeedSmellTypes } from './plants/plume-weed'
import { prickleBushBranchTypes, prickleBushLeafTypes, prickleBushSmellTypes } from './plants/prickle-bush'
import { pricklelandsFlowerBranchTypes, pricklelandsFlowerLeafTypes, pricklelandsFlowerSmellTypes } from './plants/pricklelands-flower'
import { primeordealTreeBranchTypes, primeordealTreeLeafTypes, primeordealTreeSmellTypes } from './plants/primeordeal-tree'
import { primeordealVineBranchTypes, primeordealVineLeafTypes, primeordealVineSmellTypes } from './plants/primeordeal-vine'
import { primevalTreeBranchTypes, primevalTreeLeafTypes, primevalTreeSmellTypes } from './plants/primeval-tree'
import { puzzleboxScrubBranchTypes, puzzleboxScrubLeafTypes, puzzleboxScrubSmellTypes } from './plants/puzzlebox-scrub'
import { reedBranchTypes, reedLeafTypes, reedSmellTypes } from './plants/reed'
import { rumbleGrassBranchTypes, rumbleGrassLeafTypes, rumbleGrassSmellTypes } from './plants/rumble-grass'
import { scrubGrassBranchTypes, scrubGrassLeafTypes, scrubGrassSmellTypes } from './plants/scrub-grass'
import { sedgeGrassBranchTypes, sedgeGrassLeafTypes, sedgeGrassSmellTypes } from './plants/sedge-grass'
import { shortGrassBranchTypes, shortGrassLeafTypes, shortGrassSmellTypes } from './plants/short-grass'
import { slimyTendrilBranchTypes, slimyTendrilLeafTypes, slimyTendrilSmellTypes } from './plants/slimy-tendril'
import { sugarBushBranchTypes, sugarBushLeafTypes, sugarBushSmellTypes } from './plants/sugar-bush'
import { sweetPotatoBranchTypes, sweetPotatoLeafTypes, sweetPotatoSmellTypes } from './plants/sweet-potato'
import { tallGrassBranchTypes, tallGrassLeafTypes, tallGrassSmellTypes } from './plants/tall-grass'
import { thistlebushBranchTypes, thistlebushLeafTypes, thistlebushSmellTypes } from './plants/thistlebush'
import { thistlewoodBranchTypes, thistlewoodLeafTypes, thistlewoodSmellTypes } from './plants/thistlewood'
import { tumblesticksBranchTypes, tumblesticksLeafTypes, tumblesticksSmellTypes } from './plants/tumblesticks'
import { tundraticThornBranchTypes, tundraticThornLeafTypes, tundraticThornSmellTypes } from './plants/tundratic-thorn'
import { turnipBranchTypes, turnipLeafTypes, turnipSmellTypes } from './plants/turnip'
import { uapacaaBranchTypes, uapacaaLeafTypes, uapacaaSmellTypes } from './plants/uapacaa'
import { wheatBranchTypes, wheatLeafTypes, wheatSmellTypes } from './plants/wheat'
import { willowBranchTypes, willowLeafTypes, willowSmellTypes } from './plants/willow'
import { woneyedBranchTypes, woneyedLeafTypes, woneyedSmellTypes } from './plants/woneyed'

const plantTypeDescribers = {
	"Buttonflower": [
		[],
		junglePlantTwoBranchTypes,
		junglePlantTwoLeafTypes,
		[],
		[],
		junglePlantTwoSmellTypes,
		[]
	],
	"Mangra": [
		[],
		mangoBranchTypes,
		mangoLeafTypes,
		[],
		[],
		mangoSmellTypes,
		[]
	],
	"Flossflower": [
		[],
		pearlwortBranchTypes,
		pearlwortLeafTypes,
		[],
		[],
		pearlwortSmellTypes,
		[]
	],
	"Creakbaum": [
		[],
		discordantBranchTypes,
		discordantLeafTypes,
		[],
		[],
		discordantSmellTypes,
		[]
	],
	"Canaerti": [
		[],
		palmBranchTypes,
		palmLeafTypes,
		[],
		[],
		palmSmellTypes,
		[]
	],
	"Pampas": [
		[],
		rumbleGrassBranchTypes,
		rumbleGrassLeafTypes,
		[],
		[],
		rumbleGrassSmellTypes,
		[]
	],
	"Vantha vine": [
		[],
		jungleVineBranchTypes,
		jungleVineLeafTypes,
		[],
		[],
		jungleVineSmellTypes,
		[]
	],
	"Murdock": [
		[],
		oatBranchTypes,
		oatLeafTypes,
		[],
		[],
		oatSmellTypes,
		[]
	],
	"Hullstaff grass": [
		[],
		sedgeGrassBranchTypes,
		sedgeGrassLeafTypes,
		[],
		[],
		sedgeGrassSmellTypes,
		[]
	],
	"Carmine": [
		[],
		junglePlantOneBranchTypes,
		junglePlantOneLeafTypes,
		[],
		[],
		junglePlantOneSmellTypes,
		[]
	],
	"Vetch": [
		[],
		flaxBranchTypes,
		flaxLeafTypes,
		[],
		[],
		flaxSmellTypes,
		[]
	],
	"Tünwillow": [
		[],
		antiarisBranchTypes,
		antiarisLeafTypes,
		[],
		[],
		antiarisSmellTypes,
		[]
	],
	"Cogbloom": [
		[],
		junglePlantFourBranchTypes,
		junglePlantFourLeafTypes,
		[],
		[],
		junglePlantFourSmellTypes,
		[]
	],
	"Hands of industry": [
		[],
		slimyTendrilBranchTypes,
		slimyTendrilLeafTypes,
		[],
		[],
		slimyTendrilSmellTypes,
		[]
	],
	"Burr Amaryllis": [
		[],
		needlewoodGrassBranchTypes,
		needlewoodGrassLeafTypes,
		[],
		[],
		needlewoodGrassSmellTypes,
		[]
	],
	"Breakshoot": [
		[],
		puzzleboxScrubBranchTypes,
		puzzleboxScrubLeafTypes,
		[],
		[],
		puzzleboxScrubSmellTypes,
		[]
	],
	"Pearl of Savoy": [
		[],
		beanBranchTypes,
		beanLeafTypes,
		[],
		[],
		beanSmellTypes,
		[]
	],
	"Socket lichen": [
		[],
		lichenBranchTypes,
		lichenLeafTypes,
		[],
		[],
		lichenSmellTypes,
		[]
	],
	"Guinara": [
		[],
		guineaPalmBranchTypes,
		guineaPalmLeafTypes,
		[],
		[],
		guineaPalmSmellTypes,
		[]
	],
	"Punktail": [
		[],
		reedBranchTypes,
		reedLeafTypes,
		[],
		[],
		reedSmellTypes,
		[]
	],
	"Thistle chaperone": [
		[],
		thistlewoodBranchTypes,
		thistlewoodLeafTypes,
		[],
		[],
		thistlewoodSmellTypes,
		[]
	],
	"Spokescross grass": [
		[],
		hairGrassBranchTypes,
		hairGrassLeafTypes,
		[],
		[],
		hairGrassSmellTypes,
		[]
	],
	"Palm bush": [
		[],
		palmBushBranchTypes,
		palmBushLeafTypes,
		[],
		[],
		palmBushSmellTypes,
		[]
	],
	"Zig-zag thicket": [
		[],
		mazeScrubBranchTypes,
		mazeScrubLeafTypes,
		[],
		[],
		mazeScrubSmellTypes,
		[]
	],
	"Staysgreen tree": [
		[],
		evergreenBranchTypes,
		evergreenLeafTypes,
		[],
		[],
		evergreenSmellTypes,
		[]
	],
	"Waketry": [
		[],
		crystalisedBranchTypes,
		crystalisedLeafTypes,
		[],
		[],
		crystalisedSmellTypes,
		[]
	],
	"Jalama oak": [
		[],
		crustyTreeBranchTypes,
		crustyTreeLeafTypes,
		[],
		[],
		crustyTreeSmellTypes,
		[]
	],
	"Swivelroot": [
		[],
		junglePlantThreeBranchTypes,
		junglePlantThreeLeafTypes,
		[],
		[],
		junglePlantThreeSmellTypes,
		[]
	],
	"Humtry": [
		[],
		primeordealTreeBranchTypes,
		primeordealTreeLeafTypes,
		[],
		[],
		primeordealTreeSmellTypes,
		[]
	],
	"Sprouting clampweed": [
		[],
		plumeWeedBranchTypes,
		plumeWeedLeafTypes,
		[],
		[],
		plumeWeedSmellTypes,
		[]
	],
	"Pendleverge": [
		[],
		shortGrassBranchTypes,
		shortGrassLeafTypes,
		[],
		[],
		shortGrassSmellTypes,
		[]
	],
	"Tornuvera": [
		[],
		aloeBranchTypes,
		aloeLeafTypes,
		[],
		[],
		aloeSmellTypes,
		[]
	],
	"Plateleaf bush": [
		[],
		sugarBushBranchTypes,
		sugarBushLeafTypes,
		[],
		[],
		sugarBushSmellTypes,
		[]
	],
	"Jubur grass": [
		[],
		heathBranchTypes,
		heathLeafTypes,
		[],
		[],
		heathSmellTypes,
		[]
	],
	"Wry hemlock": [
		[],
		furBranchTypes,
		furLeafTypes,
		[],
		[],
		furSmellTypes,
		[]
	],
	"Queen of the Valley": [
		[],
		thistlebushBranchTypes,
		thistlebushLeafTypes,
		[],
		[],
		thistlebushSmellTypes,
		[]
	],
	"Dawnbark Collonade": [
		[],
		fossilisedBranchTypes,
		fossilisedLeafTypes,
		[],
		[],
		fossilisedSmellTypes,
		[]
	],
	"Hingetuber": [
		[],
		kohlrabiBranchTypes,
		kohlrabiLeafTypes,
		[],
		[],
		kohlrabiSmellTypes,
		[]
	],
	"Spindlewood": [
		[],
		needlewoodBranchTypes,
		needlewoodLeafTypes,
		[],
		[],
		needlewoodSmellTypes,
		[]
	],
	"Sirga elm": [
		[],
		mulberryBranchTypes,
		mulberryLeafTypes,
		[],
		[],
		mulberrySmellTypes,
		[]
	],
	"Greyroot neep": [
		[],
		turnipBranchTypes,
		turnipLeafTypes,
		[],
		[],
		turnipSmellTypes,
		[]
	],
	"Bluetail athyrium": [
		[],
		fernBranchTypes,
		fernLeafTypes,
		[],
		[],
		fernSmellTypes,
		[]
	],
	"Pillow plume": [
		[],
		cottonBranchTypes,
		cottonLeafTypes,
		[],
		[],
		cottonSmellTypes,
		[]
	],
	"Prickle bush": [
		[],
		prickleBushBranchTypes,
		prickleBushLeafTypes,
		[],
		[],
		prickleBushSmellTypes,
		[]
	],
	"Tamarisk": [
		[],
		ordealBranchTypes,
		ordealLeafTypes,
		[],
		[],
		ordealSmellTypes,
		[]
	],
	"Sprouting cloakfur": [
		[],
		overgrownFurBranchTypes,
		overgrownFurLeafTypes,
		[],
		[],
		overgrownFurSmellTypes,
		[]
	],
	"Early Salsify": [
		[],
		sweetPotatoBranchTypes,
		sweetPotatoLeafTypes,
		[],
		[],
		sweetPotatoSmellTypes,
		[]
	],
	"Hop clover": [
		[],
		kangarooPawBranchTypes,
		kangarooPawLeafTypes,
		[],
		[],
		kangarooPawSmellTypes,
		[]
	],
	"Humming vine": [
		[],
		primeordealVineBranchTypes,
		primeordealVineLeafTypes,
		[],
		[],
		primeordealVineSmellTypes,
		[]
	],
	"Caskpitcher": [
		[],
		junglePlantFiveBranchTypes,
		junglePlantFiveLeafTypes,
		[],
		[],
		junglePlantFiveSmellTypes,
		[]
	],
	"Pink Tapiscus": [
		[],
		pricklelandsFlowerBranchTypes,
		pricklelandsFlowerLeafTypes,
		[],
		[],
		pricklelandsFlowerSmellTypes,
		[]
	],
	"Sweeptry": [
		[],
		willowBranchTypes,
		willowLeafTypes,
		[],
		[],
		willowSmellTypes,
		[]
	],
	"Rustwort": [
		[],
		liverwortBranchTypes,
		liverwortLeafTypes,
		[],
		[],
		liverwortSmellTypes,
		[]
	],
	"Lattice fife": [
		[],
		crystalWeedBranchTypes,
		crystalWeedLeafTypes,
		[],
		[],
		crystalWeedSmellTypes,
		[]
	],
	"Polestaff bush": [
		[],
		tumblesticksBranchTypes,
		tumblesticksLeafTypes,
		[],
		[],
		tumblesticksSmellTypes,
		[]
	],
	"Mountain": [
		[],
		mountainBranchTypes,
		mountainLeafTypes,
		[],
		[],
		mountainSmellTypes,
		[]
	],
	"Podded ivy": [
		[],
		cloudberryBranchTypes,
		cloudberryLeafTypes,
		[],
		[],
		cloudberrySmellTypes,
		[]
	],
	"Industrial Candytuft": [
		[],
		crowberryBranchTypes,
		crowberryLeafTypes,
		[],
		[],
		crowberrySmellTypes,
		[]
	],
	"King grass": [
		[],
		elephantGrassBranchTypes,
		elephantGrassLeafTypes,
		[],
		[],
		elephantGrassSmellTypes,
		[]
	],
	"Thorn turncore": [
		[],
		tundraticThornBranchTypes,
		tundraticThornLeafTypes,
		[],
		[],
		tundraticThornSmellTypes,
		[]
	],
	"Shelled wonderberry": [
		[],
		lingonberryBranchTypes,
		lingonberryLeafTypes,
		[],
		[],
		lingonberrySmellTypes,
		[]
	],
	"Princely Heaven": [
		[],
		oldGrowthBranchTypes,
		oldGrowthLeafTypes,
		[],
		[],
		oldGrowthSmellTypes,
		[]
	],
	"Reclined locust": [
		[],
		painforestBranchTypes,
		painforestLeafTypes,
		[],
		[],
		painforestSmellTypes,
		[]
	],
	"Woneyed": [
		[],
		woneyedBranchTypes,
		woneyedLeafTypes,
		[],
		[],
		woneyedSmellTypes,
		[]
	],
	"Pulpnet sponge": [
		[],
		cushionPlantBranchTypes,
		cushionPlantLeafTypes,
		[],
		[],
		cushionPlantSmellTypes,
		[]
	],
	"Inglenook": [
		[],
		inglenookBranchTypes,
		inglenookLeafTypes,
		[],
		[],
		inglenookSmellTypes,
		[]
	],
	"Empire weed": [
		[],
		bambooBranchTypes,
		bambooLeafTypes,
		[],
		[],
		bambooSmellTypes,
		[]
	],
	"Beardless Manshury": [
		[],
		barleyBranchTypes,
		barleyLeafTypes,
		[],
		[],
		barleySmellTypes,
		[]
	],
	"Teosinte": [
		[],
		maizeBranchTypes,
		maizeLeafTypes,
		[],
		[],
		maizeSmellTypes,
		[]
	],
	"Vigorosa": [
		[],
		tallGrassBranchTypes,
		tallGrassLeafTypes,
		[],
		[],
		tallGrassSmellTypes,
		[]
	],
	"Peg sweetblue": [
		[],
		blackbearBerryBranchTypes,
		blackbearBerryLeafTypes,
		[],
		[],
		blackbearBerrySmellTypes,
		[]
	],
	"Gasketberry": [
		[],
		gooseFootBranchTypes,
		gooseFootLeafTypes,
		[],
		[],
		gooseFootSmellTypes,
		[]
	],
	"Durum": [
		[],
		wheatBranchTypes,
		wheatLeafTypes,
		[],
		[],
		wheatSmellTypes,
		[]
	],
	"Sureheading": [
		[],
		bermudaGrassBranchTypes,
		bermudaGrassLeafTypes,
		[],
		[],
		bermudaGrassSmellTypes,
		[]
	],
	"Scrub grass": [
		[],
		scrubGrassBranchTypes,
		scrubGrassLeafTypes,
		[],
		[],
		scrubGrassSmellTypes,
		[]
	],
	"Čagan": [
		[],
		uapacaaBranchTypes,
		uapacaaLeafTypes,
		[],
		[],
		uapacaaSmellTypes,
		[]
	],
	"Early Fortune": [
		[],
		primevalTreeBranchTypes,
		primevalTreeLeafTypes,
		[],
		[],
		primevalTreeSmellTypes,
		[]
	],
	"Lunana": [
		[],
		bananaBranchTypes,
		bananaLeafTypes,
		[],
		[],
		bananaSmellTypes,
		[]
	],
	"Sagsar bush": [
		[],
		lusciousBushBranchTypes,
		lusciousBushLeafTypes,
		[],
		[],
		lusciousBushSmellTypes,
		[]
	],
}

export function getPlantDescription(plant) {
	const plantType = plant.plant;

	if (!plantTypeDescribers[plantType.name]) {
		return ''
	}

	const description = getGenericDescription(plant, plantType, ...plantTypeDescribers[plantType.name])

	return description
}

function getGenericDescription(plant, plantType, shapeTypes, branchTypes, leafTypes, fruitTypes, soundTypes, smellTypes, rareTypes) {
	const seed = seedrandom(plantType.name + plant.id);

	const sentences = []

	let sentence = '';

	if (shapeTypes?.length > 0) {
		const randomShapeType = getRandomCleanedArrayItem(shapeTypes, seed);
		sentences.push(`It has a ${randomShapeType} shape.`);
	}

	if (fruitTypes?.length > 0) {
		const randomFruitType = getRandomCleanedArrayItem(fruitTypes, seed);
		sentences.push(`It has ${randomFruitType} fruit.`);
	}

	if (branchTypes?.length > 0) {
		const randomBranchType = getRandomCleanedArrayItem(branchTypes, seed);
		sentences.push(`It has ${randomBranchType} branches.`);
	}

	if (leafTypes?.length > 0) {
		const randomLeafType = getRandomCleanedArrayItem(leafTypes, seed);
		sentences.push(`It has ${randomLeafType} leaves.`);
	}

	if (soundTypes?.length > 0 && seed() < 0.3) {
		const randomSoundType = getRandomCleanedArrayItem(soundTypes, seed);
		sentences.push(`It ${randomSoundType} in the wind.`);
	}

	if (smellTypes?.length > 0 && seed() < 0.25) {
		const randomSmellType = getRandomCleanedArrayItem(smellTypes, seed);
		sentences.push(`It smells faintly ${randomSmellType}.`)
	}

	if (rareTypes?.length > 0 && seed() < 0.05) {
		let randomRareType = getRandomCleanedArrayItem(rareTypes, seed);

		randomRareType = randomRareType[0].toUpperCase() + randomRareType.slice(1);

		sentences.push(`${randomRareType}.`);
	}

	let tries = 0;

	while (sentence.length < 500 && tries < 6) {
		tries++;
		const result = getRandomArrayItem(sentences, seed);

		if (result) {
			sentence += result + ' ';
		}
		sentences = sentences.filter(item => (item !== result))
	}

	return sentence
}
