import React from "react";
import { connect } from 'react-redux'

import store from '../../../redux/store';
import { disableKeyboardMovement, enableKeyboardMovement } from '../../../redux/actions/keyboard-shortcuts.actions';
import { selectMessages, selectCharacter } from '../../../redux/selectors';

import { client } from '../../../services/client';

import { colours } from '../../side-bar/looking/key-description';
import { serialize } from '../../side-bar/looking/Looking';

import './Menu.css';

/*
How to get this to work:

The ELEMENT CONTAINING THE MENU is the 'menuContainer'
It MUST be given a HEIGHT, and an overflow of HIDDEN
*/

class Menu extends React.Component {
    correctScrollPosition() {
        if (!this.props.menuContainer) {
            return;
        }

        const highlighted = document.getElementsByClassName('selected')[0]

        if (!highlighted) {
            return;
        }

        const messagesList = document.getElementsByClassName(this.props.menuContainer)[this.props.menuIndex || 0]

        if (!messagesList) {
            return;
        }

        const isElementAboveTop = highlighted?.offsetTop - (this.props.offsetTop || 0) < messagesList.offsetTop + messagesList.scrollTop
        const isElementBelowBottom = highlighted?.offsetTop + highlighted.offsetHeight + (this.props.offsetTop || 0) > messagesList.offsetTop + messagesList.offsetHeight + messagesList.scrollTop

        if (isElementAboveTop) {
            messagesList.scrollTop = highlighted?.offsetTop - (messagesList.offsetTop || 10);
        }

        if (isElementBelowBottom) {
            messagesList.scrollTop = highlighted?.offsetTop - (messagesList.offsetTop || 10);
        }
    }

    submitHandler = (event) => {
        if (this.props.isDisabled) {
            return;
        }

        let chosenOption;

        if (event.key === 'Tab') {
            event.preventDefault();
        }

        if (event.key === 'Enter' || event.key === ' ') {
            // We can't prevent default here! Unless we have logic for 'unpreventing'
            // event.preventDefault();
            chosenOption = this.props.options[this.state.selectedOption];
        } else {
            chosenOption = this.findChosenOption(event.key)
        }

        if (this.props.disabled) {
            if (this.props.keyPressed) {
                this.props.keyPressed(chosenOption || this.props.options[this.state.selectedOption], event, this.state.selectedOption)
            }
            return;
        }

        if (event.key === 'ArrowDown') {
            if (window.audioService) {
                window.audioService.playSound('scrollMenu')
            }
            if (this.state.selectedOption === this.props.options.length - 1) {
                if (this.props.bottomOfOptionsNavigation) {

                    if (this.props.isEndOfList) {
                        return;
                    }

                    this.setState({
                        selectedOption: this.props.options.length
                    })

                    this.props.bottomOfOptionsNavigation()
                }
                return;
            }

            let newSelectedOption = this.state.selectedOption + 1

            if (this.state.selectedOption >= this.props.options.length - 1) {
                newSelectedOption = this.props.options.length - 1
            }

            this.setState({
                ...this.state,
                selectedOption: newSelectedOption
            })

            if (this.props.highlightedOption) {
                this.props.highlightedOption(this.props.options[this.state.selectedOption])
            }

            this.correctScrollPosition();

            if (this.props.navigation) {
                this.props.navigation(chosenOption || this.props.options[this.state.selectedOption], event, this.state.selectedOption)
            }

            return;
        }

        if (event.key === 'ArrowUp') {
            if (window.audioService) {
                window.audioService.playSound('scrollMenu')
            }
            if (this.state.selectedOption === 0) {
                if (this.props.topOfOptionsNavigation) {
                    this.setState({
                        selectedOption: -1
                    })

                    this.props.topOfOptionsNavigation()
                }
                return;
            }

            let newSelectedOption = this.state.selectedOption - 1

            if (this.state.selectedOption < 0) {
                newSelectedOption = 0
            }

            this.setState({
                ...this.state,
                selectedOption: newSelectedOption
            })

            if (this.props.highlightedOption) {
                this.props.highlightedOption(this.props.options[this.state.selectedOption])
            }

            this.correctScrollPosition();

            if (this.props.navigation) {
                this.props.navigation(chosenOption || this.props.options[this.state.selectedOption], event, this.state.selectedOption)
            }

            return;
        }

        const eventKey = (event.key === ' ') ? 'SPACE' : event.key;
        const option = this.props.options[this.state.selectedOption];
        const optionActions = option?.actions;

        if (optionActions && optionActions[eventKey]) {
            optionActions[eventKey].callback(option)
        }

        if (this.props.keyPressed) {
            this.props.keyPressed(chosenOption || this.props.options[this.state.selectedOption], event, this.state.selectedOption)
        }
        if (!chosenOption) {
            return;
        }

        if (this.props.optionChosen) {
            this.props.optionChosen(chosenOption, event, this.state.selectedOption);
        } else {
            const chosenShortcut = this.props.options.find(option => (option.shortcut?.toLowerCase() === eventKey.toLowerCase()))

            if (chosenShortcut) {
                this.setState({
                    selectedOption: this.props.options.indexOf(chosenShortcut)
                })
            }
        }

        this.correctScrollPosition();
    };

    findChosenOption(input) {
        return this.props.options.find((option, index) => (option.shortcut === input || (!option.shortcut && index + 1 === Number(input))))
    }

    constructor() {
        super();
        this.state = { selectedOption: 0, optionsCount: 0 };

        document.addEventListener('keydown', this.submitHandler)
    }

    componentDidMount() {
        if (this.props.topOfOptionsNavigation) {
            this.setState({
                selectedOption: -1
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.submitHandler);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.options?.length > 0 && state.optionsCount > props.options?.length && state.selectedOption > props.options?.length - 1) {
            state.selectedOption = props.options?.length - 1
        }

        if (props.options.length !== state.optionsCount && props.highlightedOption) {
            props.highlightedOption(props.options[state.selectedOption])
        }

        if (props.isResetChildMenu) {
            state.selectedOption = props.options?.length - 1
        }

        state.optionsCount = props.options?.length

        return { ...state };
    }

    getColourClass(optionText) {
        if (!optionText) {
            return ''
        }

        return colours.find((colour) => {
            return optionText.toLowerCase().indexOf(colour.toLowerCase()) > -1
        })?.toLowerCase()
    }

    getOptionText(optionText) {
        optionText = '' + optionText

        const colourClass = this.getColourClass(optionText);

        const isRustedString = optionText.indexOf('[rusted]') > -1;

        const isEquippedString = optionText.indexOf('[equipped]') > -1;

        if (isEquippedString) {
            return (
                <div className={`${colourClass}`}>
                    {optionText.split('[equipped]')[0]}
                    {isRustedString ? (<span className="rusted">[rusted]</span>) : ('')}
                    <span className="equipped">[equipped]</span>
                </div>
            )
        }

        const isUnequippedString = optionText.indexOf('[unequipped]') > -1;

        if (isUnequippedString) {
            return (
                <div className={colourClass}>
                    {optionText.split('[unequipped]')[0]}
                    {isRustedString ? (<span className="rusted">[rusted]</span>) : ('')}
                    <span className="unequipped">[unequipped]</span>
                </div>
            )
        }

        return (<div className={colourClass}>{optionText}</div>)
    }

    getOptionLevelStartSymbol(level) {
        switch (level) {
            case 0: 
                return '❨'
            case 1: 
                return '❨❨'
            case 2: 
                return '❬'
            case 3: 
                return '❬❬'
            case 4: 
                return '✦'
            case 5: 
                return '✻'
            case 6: 
                return '✿'
            case 7: 
                return '✽'
            case 8: 
                return '❉'
            case 9: 
                return '❁'
            case 10: 
                return '✹'
        }
    }

    getOptionLevelEndSymbol(level) {
        switch (level) {
            case 0: 
                return '❩'
            case 1: 
                return '❩❩'
            case 2: 
                return '❭'
            case 3: 
                return '❭❭'
            case 4: 
                return '✦'
            case 5: 
                return '✻'
            case 6: 
                return '✿'
            case 7: 
                return '✽'
            case 8: 
                return '❉'
            case 9: 
                return '❁'
            case 10: 
                return '✹'
        }
    }

    getBookTitle(graffiti) {
        if (!graffiti || graffiti?.length < 1 || !graffiti[0].children || graffiti[0].children.length < 1) {
            return ''
        }

        const MAX_CHARACTER_COUNT = 20;

        const title = { ...graffiti[0], children: [ ...graffiti[0].children ] };

        // const textCopy = title.children[0]?.text;

        title.children[0] = { ...title.children[0] }
        const words = title.children[0].text.split(' ');

        let characterCount = 0;
        let titleText = '';
        let wordIndex = 0;
        let isTitleFound = false;

        while (!isTitleFound) {
            const newWord = words[wordIndex];

            if (!newWord || ((titleText + newWord).length >= MAX_CHARACTER_COUNT)) {
                isTitleFound = true;
            } else {
                titleText += newWord + ' ';
                characterCount = titleText.length;
                wordIndex++
            }
        }

        title.children[0].text = titleText

        return serialize(title)
    }

    render() {
    	const options = this.props.options.map((option, index) => {
            let className = '';

            if (option.indentation) {
                className += `indentation-${option.indentation}`
            } else {
                className += "indentation-0"
            }

            if (option.className) {
                className += ' ' + option.className;
            }

            const optionText = this.getOptionText(option.text);

    		return (
    			<li key={'option-' + index} className={index == this.state.selectedOption && !this.props.isDisabled ? `selected ` + className : className}>
                    <span className={index == this.state.selectedOption && !this.props.isDisabled ? "selected-indicator": "hidden"}></span>
                    <span className={this.props.isShortcutHidden ? "hidden" : "shortcut"}>{option.shortcut || index + 1}. </span>

                    <div className="option-description-container">
                        <span className={option.level !== undefined ? `level-${option.level}` : "hidden"}>{this.getOptionLevelStartSymbol(option.level)}&nbsp;</span>

                        <span className="book-title">{this.getBookTitle(option.graffiti)}</span>
                        <span className={option.condition ? `${option.condition}` : "hidden"}>{option.condition}&nbsp;</span>
                        <span className={`menu-option-text ${option.className} `}>{this.getOptionText(option.text)}</span>

                        <span className={option.level !== undefined ? `level-${option.level}` : "hidden"}>&nbsp;{this.getOptionLevelEndSymbol(option.level)}</span>

                        <span className={`${option.hintClassName} hint`}>{ option.hint }</span>
                        <span className={option.isWeightShowing && option.weight !== undefined ? "weight" : "hidden"}>{option.weight}</span>
                        <span className={option.tradePrice && option.isMarkedWithPrice ? "marked-trade-qty" : option.markedForTradeCount ? index == this.state.selectedOption && !this.props.isDisabled ? "selected-trade" : "trade" : "hidden"}>{option.markedForTradeCount}<img src={`assets/${this.props.tradeIcon}.svg`}/></span>
                        <span className={option.tradePrice ? "marked-trade" : "hidden"}>¤{option.tradePrice}</span>
                        <span className={option.tradeOfferCount ? "trade-offer" : "hidden"}>{option.tradeOfferCount}</span>
                    </div>
                </li>
			)
    	})

        let actions = ('');

        if (this.props.options && this.props.options[this.state.selectedOption]?.actions) {
            actions = Object.keys(this.props.options[this.state.selectedOption]?.actions).map((actionKey, index) => {
                const action = this.props.options[this.state.selectedOption]?.actions[actionKey]
                return (
                    <span className="action-shortcut" key={'option-' + index}><span className="shortcut">{actionKey}</span> {action.text}</span>
                )
            })
        }

        return (
            <div className="menu-container">
                <p className={this.props.title ? "menu-title" : "hidden"}>{ this.props.title }</p>
                
                <ol className="menu-items">
                	{options}
                </ol>

                <div className={this.props.isDisabled ? "hidden" : "menu-actions"}>
                    {actions}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default connect(
    mapStateToProps,
    { }
)(Menu);