import React from "react";
import { connect } from 'react-redux'

import { addFoodToTileAsync, eatFoodAsync, deleteFoodAsync, addFoodToCharacterAsync } from '../../../redux/actions/food.actions';
import { addMaterialToTileAsync, addMaterialToCharacterAsync } from '../../../redux/actions/inventory.actions';
import { tileTypes, createTileAsync, addTileToGroundAsync, addTileToTileAsync, addTileToCharacterAsync, removeTile } from '../../../redux/actions/tile.actions';
import { addMineralToTileAsync, addMineralToCharacterAsync } from '../../../redux/actions/mineral.actions';
import { addToolToTileAsync, addToolToCharacterAsync } from '../../../redux/actions/tool.actions';
import { addMetalToTileAsync, addMetalToCharacterAsync } from '../../../redux/actions/metal.actions';
import { addKeyToTileAsync, copyKeyAsync, addKeyToCharacterAsync } from '../../../redux/actions/key.actions';
import { copyWritingSurfaceAsync, addWritingSurfaceToTileAsync, editWritingSurfaceAsync, addWritingSurfaceToCharacterAsync } from '../../../redux/actions/writing-surface.actions';
import { addWritingImplementToTileAsync, addWritingImplementToCharacterAsync } from '../../../redux/actions/writing-implement.actions';
import { addWeaponToTileAsync, addWeaponToCharacterAsync } from '../../../redux/actions/weapon.actions';
import { addArmourToTileAsync, addArmourToCharacterAsync } from '../../../redux/actions/armour.actions';
import { addClothingToTileAsync, addClothingToCharacterAsync } from '../../../redux/actions/clothing.actions';
import { addJewelleryToTileAsync, addJewelleryToCharacterAsync } from '../../../redux/actions/jewellery.actions';
import { addTentToCharacterAsync, addTentToTileAsync } from '../../../redux/actions/tent.actions';
import { addLockToCharacterAsync, addLockToTileAsync, installLockAsync, unlockLockAsync, lockLockAsync } from '../../../redux/actions/lock.actions';
import { updateTradeAsync } from '../../../redux/actions/trade.actions';
import { animalTypeDescribers } from '../../side-bar/looking/animal-description';
import {
    equipToolAsync,
    equipWeaponAsync,
    equipArmourAsync,
    equipClothingAsync,
    equipJewelleryAsync,
    unequipClothingAsync,
    unequipJewelleryAsync, 
    equipTentLocally, 
    unequipTentLocally
} from '../../../redux/actions/character.actions';
import {
    createNewMessage
} from '../../../redux/actions/messages.actions';
import {
    showQuantityInput,
    hideQuantityInput,
    chooseCharacterToGiveTo,
    hideCharacterList,
    closeInventory,
    showWritingSurface,
    showDrawingSurface,
    setIsOverencumbered,
} from '../../../redux/actions/keyboard-shortcuts.actions';
import { BRAINCHIP_TYPE_NAMES } from '../../../services/Tilemap.service';
import { BALANCE } from '../../../services/balance-config';

import Menu from '../../utils/menu/Menu';

import {
    selectInventory,
    selectCharacterFood,
    selectCharacterMinerals,
    selectCharacterTools,
    selectCharacterMetals,
    selectCharacterKeys,
    selectTiles,
    selectCharacter,
    selectTileTypeByName,
    selectCharacterWritingSurfaces,
    selectCharacterWritingImplements,
    selectCharacterWeapons,
    selectCharacterArmour,
    selectCharacterClothing,
    selectCharacterJewellery,
    selectCharacterTents,
    selectIsQuantityInputOpen,
    selectIsCharacterListShowing,
    selectNeighbouringCharacters,
    selectCharacterTiles,
    selectTileInventory,
    selectTileFood,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectTileTents,
    selectTileLocks,
    selectCharacterLocks,
    selectCurrencies,
    selectCoinTypes,
    selectCoins,
    selectServerTimeOffset,
    selectAllTileLocks,
    selectCoinRecipes,
    selectMaximumInventoryWeight,
    selectLocationWeight
} from '../../../redux/selectors';
import EditWritingSurface from '../edit-writing-surface/EditWritingSurface';
import QuantityInput from '../../utils/quantity-input/QuantityInput';
import DirectionInput from '../../utils/direction-input/DirectionInput';
import CharacterList from '../../actions/character-list/CharacterList';
import { getKeyDescription } from '../../side-bar/looking/key-description'
import { getTorchDurationString, getCondition } from '../../side-bar/looking/Looking';

import { client } from '../../../services/client';

import './InventoryActions.css';

class InventoryActions extends React.Component {
    totalWeight = 0;
    onQuantitySupplied;
    onQuantityClosed;

    submitHandler = (event) => {
        if (this.props.isDisabled || this.state.isDirectionInputShowing || this.state.isQuantityInputShowing || this.props.isCharacterListShowing) {
            return;
        }

        if (event.key === 'ArrowRight') {
            if (window.audioService) {
                window.audioService.playSound('scrollMenu')
            }
            this.setState({
                isDisabled: true
            })

            return;
        }

        if (event.key === 'ArrowLeft') {
            if (window.audioService) {
                window.audioService.playSound('scrollMenu')
            }
            this.setState({
                isDisabled: false,
            })

            return;
        }
    };

    async onMaterialDrop(material) {
        const quantity = await this.getQuantity(material);
        material = { ...material, quantity };

        const tile = this.getItemTileAtPosition(this.props.character.position);

        this.setState({ isLoading: true })

        if (tile) {
            this.addMaterialToTile(material, tile)
        } else {
            this.addMaterialToTile(material, {}, this.props.itemTileType._id)
        }
    }

    async onMaterialGive(materialInstance) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                const quantity = await this.getQuantity(materialInstance);
                materialInstance = { ...materialInstance, quantity };

                this.props.addMaterialToCharacterAsync({ characterId: character._id, materialInstance });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addMaterialToTile(material, tile, tileTypeId) {
        this.props.addMaterialToTileAsync({ materialInstance: material, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onFoodDrop(food) {
        const quantity = await this.getQuantity(food);
        food = { ...food, quantity };

        const tile = this.getItemTileAtPosition(this.props.character.position);

        this.setState({ isLoading: true })

        if (tile) {
            this.addFoodToTile(food, tile)
        } else {
            this.addFoodToTile(food, {}, this.props.itemTileType._id)
        }
    }

    async onFoodGive(foodInstance) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                const quantity = await this.getQuantity(foodInstance);
                foodInstance = { ...foodInstance, quantity };

                this.props.addFoodToCharacterAsync({ characterId: character._id, foodInstance });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    onFoodEat(food) {
        this.props.eatFoodAsync({ ...food })
    }

    addFoodToTile(food, tile, tileTypeId) {
        this.props.addFoodToTileAsync({ foodInstance: food, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onMineralDrop(mineral) {
        const quantity = await this.getQuantity(mineral);
        mineral = { ...mineral, quantity };

        
        const tile = this.getItemTileAtPosition(this.props.character.position);

        this.setState({ isLoading: true })

        if (tile) {
            this.addMineralToTile(mineral, tile)
        } else {
            this.addMineralToTile(mineral, {}, this.props.itemTileType._id)
        }
    }

    async onMineralGive(mineralInstance) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                const quantity = await this.getQuantity(mineralInstance);
                mineralInstance = { ...mineralInstance, quantity };

                this.props.addMineralToCharacterAsync({ characterId: character._id, mineralInstance });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addMineralToTile(mineral, tile, tileTypeId) {
        this.props.addMineralToTileAsync({ mineralInstance: mineral, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onToolDrop(tool) {
        let quantity = 1;

        if (tool.hint > 1) {
            quantity = await this.getQuantity({ ...tool, quantity: tool.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _tool = tool.instances[i]

            if (i >= quantity) {
                return;
            }

            let tile = this.getItemTileAtPosition(this.props.character.position);

            if (tile) {
                await this.addToolToTile(_tool, tile)
            } else {
                await this.addToolToTile(_tool, {}, this.props.itemTileType._id)
            }
        }
    }

    async onToolGive(tool) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (tool.hint > 1) {
                    quantity = await this.getQuantity({ ...tool, quantity: tool.hint });
                }

                const toolInstances = tool.instances.slice(0, quantity)

                this.props.addToolToCharacterAsync({ characterId: character._id, toolInstances });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${tool.toolType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    async addToolToTile(tool, tile, tileTypeId) {
        return this.props.addToolToTileAsync({ toolInstance: tool, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onMetalDrop(metal) {
        const quantity = await this.getQuantity(metal);
        metal = { ...metal, quantity };

        const tile = this.getItemTileAtPosition(this.props.character.position);

        this.setState({ isLoading: true })

        if (tile) {
            this.addMetalToTile(metal, tile)
        } else {
            this.addMetalToTile(metal, {}, this.props.itemTileType._id)
        }
    }

    async onMetalGive(metalInstance) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                const quantity = await this.getQuantity(metalInstance);
                metalInstance = { ...metalInstance, quantity };

                this.props.addMetalToCharacterAsync({ characterId: character._id, metalInstance });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addMetalToTile(metal, tile, tileTypeId) {
        this.props.addMetalToTileAsync({ metalInstance: metal, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onKeyDrop(key) {
        let quantity = 1;

        if (key.hint > 1) {
            quantity = await this.getQuantity({ ...key, quantity: key.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _key = key.instances[i]

            if (i >= quantity) {
                return;
            }

            const tile = this.getItemTileAtPosition(this.props.character.position);

            if (tile) {
                this.addKeyToTile(_key, tile)
            } else {
                const result = await this.addKeyToTile(_key, {}, this.props.itemTileType._id)

                
            }
        }
    }

    async onKeyGive(key) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (key.hint > 1) {
                    quantity = await this.getQuantity({ ...key, quantity: key.hint });
                }

                const keyInstances = key.instances.slice(0, quantity);

                this.props.addKeyToCharacterAsync({ characterId: character._id, keyInstance });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${key.keyType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addKeyToTile(key, tile, tileTypeId) {
        return this.props.addKeyToTileAsync({ keyInstance: key, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    onKeyCopy(key) {
        this.props.copyKeyAsync({ keyInstance: key, characterId: this.props.character._id });
    }

    onWritingSurfaceCopy(writingSurface) {
        this.props.copyWritingSurfaceAsync({ writingSurfaceInstance: writingSurface, characterId: this.props.character._id });
    }

    async onWritingSurfaceDrop(writingSurface) {
        let quantity = 1;

        if (writingSurface.hint > 1) {
            quantity = await this.getQuantity({ ...writingSurface, quantity: writingSurface.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _writingSurface = writingSurface.instances[i]

            if (i >= quantity) {
                return;
            }

            const tile = this.getItemTileAtPosition(this.props.character.position, _writingSurface);

            if (tile) {
                this.addWritingSurfaceToTile(_writingSurface, tile)
            } else {
                const result = await this.addWritingSurfaceToTile(_writingSurface, {}, this.props.itemTileType._id)

                
            }
        }
    }

    async onWritingSurfaceGive(writingSurface) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (writingSurface.hint > 1) {
                    quantity = await this.getQuantity({ ...writingSurface, quantity: writingSurface.hint });
                }

                const writingSurfaceInstances = writingSurface.instances.slice(0, quantity)

                this.props.addWritingSurfaceToCharacterAsync({ characterId: character._id, writingSurfaceInstances });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${writingSurface.writingSurfaceType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addWritingSurfaceToTile(writingSurface, tile, tileTypeId) {
        return this.props.addWritingSurfaceToTileAsync({ writingSurfaceInstance: writingSurface, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onWritingImplementDrop(writingImplement) {
        let quantity = 1;

        if (writingImplement.hint > 1) {
            quantity = await this.getQuantity({ ...writingImplement, quantity: writingImplement.hint });
        }

        this.setState({ isLoading: true })

        let _writingImplement = writingImplement.instances[0]
        _writingImplement = { ..._writingImplement, quantity }

        let tile = this.getItemTileAtPosition(this.props.character.position);

        if (tile) {
            this.addWritingImplementToTile(_writingImplement, tile)
        } else {
            const result = await this.addWritingImplementToTile(_writingImplement, {}, this.props.itemTileType._id)

            tile = {
                _id: result.tileId
            }
        }
    }

    async onWritingImplementGive(writingImplement) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (writingImplement.hint > 1) {
                    quantity = await this.getQuantity({ ...writingImplement, quantity: writingImplement.hint });
                }

                const writingImplementInstances = writingImplement.instances.slice(0, quantity)

                this.props.addWritingImplementToCharacterAsync({ characterId: character._id, writingImplementInstances });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${writingImplement.writingImplementType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addWritingImplementToTile(writingImplement, tile, tileTypeId) {
        return this.props.addWritingImplementToTileAsync({ writingImplementInstance: writingImplement, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onWeaponDrop(weapon) {
        let quantity = 1;

        if (weapon.hint > 1) {
            quantity = await this.getQuantity({ ...weapon, quantity: weapon.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _weapon = weapon.instances[i]

            if (i >= quantity) {
                return;
            }

            const tile = this.getItemTileAtPosition(this.props.character.position);

            if (tile) {
                this.addWeaponToTile(_weapon, tile)
            } else {
                const result = await this.addWeaponToTile(_weapon, {}, this.props.itemTileType._id)

                
            }
        }
    }

    async onWeaponGive(weapon) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (weapon.hint > 1) {
                    quantity = await this.getQuantity({ ...weapon, quantity: weapon.hint });
                }

                const weaponInstances = weapon.instances.slice(0, quantity)

                this.props.addWeaponToCharacterAsync({ characterId: character._id, weaponInstances });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${weapon.weaponType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addWeaponToTile(weapon, tile, tileTypeId) {
        return this.props.addWeaponToTileAsync({ weaponInstance: weapon, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onArmourDrop(armour) {
        let quantity = 1;

        if (armour.hint > 1) {
            quantity = await this.getQuantity({ ...armour, quantity: armour.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _armour = armour.instances[i]

            if (i >= quantity) {
                return;
            }

            const tile = this.getItemTileAtPosition(this.props.character.position);

            if (tile) {
                this.addArmourToTile(_armour, tile)
            } else {
                const result = await this.addArmourToTile(_armour, {}, this.props.itemTileType._id)

                
            }
        }
    }

    async onArmourGive(armour) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (armour.hint > 1) {
                    quantity = await this.getQuantity({ ...armour, quantity: armour.hint });
                }

                const armourInstances = armour.instances?.slice(0, quantity) || armour

                this.props.addArmourToCharacterAsync({ characterId: character._id, armourInstances });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${armour.armourType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addArmourToTile(armour, tile, tileTypeId) {
        return this.props.addArmourToTileAsync({ armourInstance: armour, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onClothingDrop(clothing) {
        let quantity = 1;

        if (clothing.hint > 1) {
            quantity = await this.getQuantity({ ...clothing, quantity: clothing.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _clothing = clothing.instances[i]

            if (i >= quantity) {
                return;
            }

            const tile = this.getItemTileAtPosition(this.props.character.position);

            if (tile) {
                this.addClothingToTile(_clothing, tile)
            } else {
                const result = await this.addClothingToTile(_clothing, {}, this.props.itemTileType._id)

                
            }
        }
    }

    async onClothingGive(clothing) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (clothing.hint > 1) {
                    quantity = await this.getQuantity({ ...clothing, quantity: clothing.hint });
                }

                const clothingInstances = clothing.instances?.slice(0, quantity) || clothing

                this.props.addClothingToCharacterAsync({ characterId: character._id, clothingInstances });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${clothing.clothingType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addClothingToTile(clothing, tile, tileTypeId) {
        return this.props.addClothingToTileAsync({ clothingInstance: clothing, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onJewelleryDrop(jewellery) {
        let quantity = 1;

        if (jewellery.hint > 1) {
            quantity = await this.getQuantity({ ...jewellery, quantity: jewellery.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _jewellery = jewellery.instances[i]

            if (i >= quantity) {
                return;
            }

            const tile = this.getItemTileAtPosition(this.props.character.position);

            if (tile) {
                this.addJewelleryToTile(_jewellery, tile)
            } else {
                const result = await this.addJewelleryToTile(_jewellery, {}, this.props.itemTileType._id)

                
            }
        }
    }

    async onJewelleryGive(jewellery) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (jewellery.hint > 1) {
                    quantity = await this.getQuantity({ ...jewellery, quantity: jewellery.hint });
                }

                const jewelleryInstances = jewellery.instances?.slice(0, quantity) || jewellery

                this.props.addJewelleryToCharacterAsync({ characterId: character._id, jewelleryInstances });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${jewellery.jewelleryType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addJewelleryToTile(jewellery, tile, tileTypeId) {
        return this.props.addJewelleryToTileAsync({ jewelleryInstance: jewellery, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onTentDrop(tent) {
        let quantity = 1;

        if (tent.hint > 1) {
            quantity = await this.getQuantity({ ...tent, quantity: tent.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _tent = tent.instances[i]

            if (i >= quantity) {
                return;
            }

            const tile = this.getItemTileAtPosition(this.props.character.position);

            if (tile) {
                this.addTentToTile(_tent, tile)
            } else {
                const result = await this.addTentToTile(_tent, {}, this.props.itemTileType._id)

                
            }
        }
    }

    async onTentGive(tent) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (tent.hint > 1) {
                    quantity = await this.getQuantity({ ...tent, quantity: tent.hint });
                }

                const tentInstances = tent.instances?.slice(0, quantity) || tent

                this.props.addTentToCharacterAsync({ characterId: character._id, tentInstances });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${tent.tentType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addTentToTile(tent, tile, tileTypeId) {
        return this.props.addTentToTileAsync({ tentInstance: tent, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onContainerDrop(container) {
        let quantity = 1;

        if (container.hint > 1) {
            quantity = await this.getQuantity({ ...container, quantity: container.hint });
        }

        this.setState({ isLoading: true })

        for (let i=0; i < quantity; i++) {
            const _container = container.instances ? container.instances[i] : container

            if (i >= quantity) {
                return;
            }

            const tile = this.getItemTileAtPosition(this.props.character.position);

            if (tile) {
                this.addContainerToTile(_container, tile)
            } else {
                const result = await this.addContainerToTile(_container, {})

                tile = {
                    _id: result?.tileId
                }
            }
        }
    }

    async onContainerGive(tile) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                let quantity = 1;

                if (tile.hint > 1) {
                    quantity = await this.getQuantity({ ...tile, quantity: tile.hint });
                }

                for (let i=0; i < quantity; i++) {
                    const tileInstance = tile.instances[i]

                    if (i >= quantity) {
                        return;
                    }

                    this.props.addTileToCharacterAsync({ characterId: character._id, tileInstance })
                        .then((response) => (this.props.removeTile(response)))
                    this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${tile.tileType.name} to ${character.name}` } });
                }

            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addContainerToTile(container, tile) {
        if (tile?._id && container.tileType?.name === 'Book') {
            this.props.addTileToTileAsync({ tileInstance: container, tileId: tile._id })
                .finally(() => (this.setState({isLoading: false})));
            return;    
        }

        return this.props.addTileToGroundAsync({ tileInstance: container, position: this.props.character.position, panelId: this.props.character.panelId })
            .finally(() => (this.setState({isLoading: false})));
    }

    getItemTileAtPosition(position, item) {
        return Object.values(this.props.tiles.byId).find(tile => {
            const isValidTile = tile?.position?.x === position.x
                && tile?.position?.y === position.y
                && tile?.tileType?.name !== tileTypes.FARM_PLOT

            const installedLock = this.props.tileLocks.find(lock => (lock.tileId === tile._id && lock.isInstalled));

            let isItemValidForTile = true;

            if (tile.type?.insertableObjects) {
                isItemValidForTile = tile.type?.insertableObjects.find(insertableObject => (item && item[insertableObject?.name]))
            }

            return isValidTile && isItemValidForTile && !installedLock?.isLocked
        })
    }

    onLookAtWritingSurface(writingSurface) {
        if (writingSurface.graffiti) {
            this.onEditWritingSurface({ ...writingSurface, isLooking: true })
            return;
        }

        if (writingSurface.pixelsArray && writingSurface.pixelsArray.length > 0) {
            this.onEditDrawingSurface({ ...writingSurface, isLooking: true })
            return;
        }
    }

    onEditWritingSurface(writingSurface) {
        this.props.showWritingSurface({
            surfaceService: writingSurface.serviceName || 'writing-surface-instances',
            surfaceId: writingSurface._id,
            isLooking: writingSurface.isLooking,
            isEditWritingSurfaceShowing: true
        })
    }

    onEditDrawingSurface(writingSurface) {
        this.props.showDrawingSurface({
            surfaceService: writingSurface.serviceName || 'writing-surface-instances',
            surfaceId: writingSurface._id,
            isLooking: writingSurface.isLooking,
        })
    }

    onSaveWritingSurface(text) {
        this.setState({isEditWritingSurfaceShowing: false})
        this.props.editWritingSurfaceAsync({
            ...this.state.writingSurface,
            text
        })
    }

    onEquipWeapon({_id, weaponTypeId}) {
        this.props.equipWeaponAsync({
            _id,
            weaponTypeId,
            characterId: this.props.character._id
        })
    }

    onEquipArmour({_id, armourTypeId}) {
        this.props.equipArmourAsync({
            _id,
            armourTypeId,
            characterId: this.props.character._id
        })
    }

    onEquipTool({_id, toolTypeId}) {
        this.props.equipToolAsync({
            _id,
            toolTypeId,
            characterId: this.props.character._id
        })
    }

    onWearClothing({ _id, clothingTypeId, clothingType }) {
        this.props.equipClothingAsync({
            _id,
            clothingTypeId,
            bodyPart: clothingType?.bodyPart,
            clothingItems: this.props.character.clothingItems || [],
            characterId: this.props.character._id
        })
    }

    onRemoveClothing({ _id }) {
        this.props.unequipClothingAsync({
            _id,
            clothingItems: this.props.character.clothingItems,
            characterId: this.props.character._id
        })
    }
    
    onWearJewellery({ _id, jewelleryTypeId, jewelleryType }) {
        this.props.equipJewelleryAsync({
            _id,
            jewelleryTypeId,
            bodyPart: jewelleryType.bodyPart,
            clothingItems: this.props.character.clothingItems || [],
            characterId: this.props.character._id
        });
    }

    onRemoveJewellery({ _id }) {
        this.props.unequipJewelleryAsync({
            _id,
            clothingItems: this.props.character.clothingItems,
            characterId: this.props.character._id
        })
    }

    onEquipTent({ _id, tentTypeId }) {
        this.props.equipTentLocally({
            _id,
            tentTypeId,
        })
    }

    onUnequipTent({ _id }) {
        this.props.unequipTentLocally({
            _id
        })
    }

    onInstallLock({ lockType, _id, lockTypeId }) {
        if (lockType.name.toLowerCase().indexOf('lock') > -1) {
            this.props.installLockAsync({
                _id: _id,
                lockTypeId: lockTypeId,
                direction: undefined
            })
            return;
        }

        // only show direction for bolt locks, not key locks.
        this.setState({
            isDirectionInputShowing: true,
            lockId: _id,
            lockTypeId: lockTypeId
        })
    }

    onDirectionSupplied(direction) {
        if (this.state.lockId) {
            this.props.installLockAsync({
                _id: this.state.lockId,
                lockTypeId: this.state.lockTypeId,
                direction
            })
        }

        this.setState({
            isDirectionInputShowing: false,
            lockId: undefined,
            lockTypeId: undefined,
        })
    }

    onDirectionClosed() {
        this.setState({
            isDirectionInputShowing: false
        })
    }

    async onLockDrop(lock) {
        const tile = this.getItemTileAtPosition(this.props.character.position);

        this.setState({ isLoading: true })

        if (tile) {
            this.addLockToTile(lock, tile)
        } else {
            this.addLockToTile(lock, {}, this.props.itemTileType._id)
        }
    }

    async onLockGive(lockInstance) {
        this.setState({
            onCharacterSelected: async (character) => {
                this.props.hideCharacterList();

                const quantity = await this.getQuantity(lockInstance);
                lockInstance = { ...lockInstance, quantity };

                this.props.addLockToCharacterAsync({ characterId: character._id, lockInstance });
                this.props.createNewMessage({ message: { createdAt: new Date().getTime(), characterName: this.props.character.name, _id: '' + new Date().getTime(), text: `${this.props.character.name} gives ${lockInstance.lockType.name} to ${character.name}` } });
            }
        })
        this.props.chooseCharacterToGiveTo();
    }

    addLockToTile(lock, tile, tileTypeId) {
        this.props.addLockToTileAsync({ lockInstance: lock, tileId: tile._id, tileTypeId })
            .finally(() => (this.setState({isLoading: false})));
    }

    getWeight(item, itemTypeKey, isTileWeightCalculation) {
        if (isTileWeightCalculation) {
            return (item.quantity || 1) * item[itemTypeKey]?.weight;
        }
        this.totalWeight += (item.quantity || 1) * item[itemTypeKey]?.weight;

        return `${(item.quantity || 1) * item[itemTypeKey]?.weight}u`;
    }

    extractNumber(str) {
      // Use a regular expression to match all digits in the string
      const match = str.match(/\d+/);
      // If match is found, convert it to a number and return
      if (match) {
        return parseInt(match[0], 10);
      }
      // If no match is found, return null or handle the case as needed
      return null;
    }


    async getQuantity(item) {
        if (Number(item.quantity) > 1) {
            const weight = this.extractNumber(item.weight);
            const weightPerItem = weight / item.quantity;

            const maxQuantity = Math.min(item.quantity, Math.floor((BALANCE.MAX_FLOOR_WEIGHT - this.props.locationWeight) / weightPerItem))

            this.setState({ isQuantityInputShowing: true, maxQuantity });
            this.props.showQuantityInput();
        }

        return new Promise((resolve, reject) => {
            if (Number(item.quantity) === 1) {
                return resolve(1);
            }

            this.setState({
                onQuantitySupplied: (quantity) => {
                    this.props.hideQuantityInput();
                    this.setState({isQuantityInputShowing: false})

                    resolve(Number(quantity));
                },
                onQuantityClosed: () => {
                    this.props.hideQuantityInput();
                    this.setState({isQuantityInputShowing: false})
                    reject('No quantity supplied');
                }
            })
        });
    }

    getPrice(item) {
        this.props.showQuantityInput();
        this.setState({ isQuantityInputShowing: true, maxQuantity: item.tradePrice });

        return new Promise((resolve, reject) => {
            this.setState({
                onQuantitySupplied: (quantity) => {
                    this.props.hideQuantityInput();
                    this.setState({isQuantityInputShowing: false})
                    resolve(Number(quantity));
                },
                onQuantityClosed: () => {
                    this.props.hideQuantityInput();
                    this.setState({isQuantityInputShowing: false})
                    reject('No quantity supplied');
                }
            })
        });
    }

    onAddItemToTrade(serviceName, item) {
        if (this.state.isDisabled) {
            return;
        }
        this.props.updateTradeAsync({
            _id: item._id,
            serviceName,
            markedForTradeCount: item.markedForTradeCount ? Math.min(item.markedForTradeCount + 1, item.quantity) : 1
        })
    }

    async onAddPrice(serviceName, item) {
        // Step one: get the price mate.
        const tradePrice = await this.getPrice(item);

        this.props.updateTradeAsync({
            _id: item._id,
            serviceName,
            tradePrice
        })
    }

    onRemoveItemFromTrade(serviceName, item) {
        this.props.updateTradeAsync({
            _id: item._id,
            serviceName,
            markedForTradeCount: item.markedForTradeCount ? Math.min(item.markedForTradeCount - 1, 0) : 0
        })
    }

    constructor() {
        super();
        this.state = {
            isQuantityInputShowing: false,
            isDisabled: false,
            hiddenOptions: [],
            onCharacterSelected: () => {}
        };

        document.addEventListener('keydown', this.submitHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.submitHandler);
    }

    correctShortcutKeys(newConversationTree) {
        let index = 0;

        return newConversationTree.map((option) => {
            const newShortcut = String.fromCharCode(97 + index)
            index++;

            return {
                ...option,
                shortcut: newShortcut
            }
        })
    }


    render() {
        this.totalWeight = 0;

        const menuOptions = {
            '+': {
                callback: (option) => {
                    if (this.state.isDisabled) {
                        return;
                    }
                    this.setState({
                        hiddenOptions: [ ...this.state.hiddenOptions.filter(hiddenOption => (hiddenOption !== `${option.text}.${option.parentId}`)) ]
                    })
                },
                text: 'Open'
            },
            '-': {
                callback: (option) => {
                    if (this.state.isDisabled) {
                        return;
                    }
                    this.setState({
                        hiddenOptions: [ ...this.state.hiddenOptions.filter(hiddenOption => (hiddenOption !== `${option.text}.${option.parentId}`)), `${option.text}.${option.parentId}` ]
                    })
                },
                text: 'Close'
            }
        }

        let tiles = Object.values(this.props.containers).map((tile, index) => {
            const materials = {
                text: 'Materials',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Materials.${tile._id}`) > -1 ? [] : tile.inventory?.map((material, index) => {
                    let materialName = material?.materialType?.name;

                    if (material?.plant?.name || animalTypeDescribers[material?.animal?.name]?.name || material?.fish?.name) {
                        materialName = (material?.plant?.name || animalTypeDescribers[material?.animal?.name]?.name || material?.fish?.name) + ' ' + material?.materialType?.name;
                    }

                    return (
                        {
                            ...material,
                            text: materialName,
                            condition: getCondition(material),
                            hintClassName: 'quantity',
                            hint:material.quantity || 1,
                            markedForTradeCount: material.markedForTradeCount,
                            isMarkedWithPrice: !!material.tradePrice,
                            weight: this.getWeight(material, 'materialType'),
                            indentation: 2,
                            actions: {
                                'SPACE': {
                                    callback: (option) => (this.onMaterialDrop(option)),
                                    text: 'Drop'
                                },
                                ',': {
                                    callback: (lock) => (this.onMaterialGive(lock)),
                                    text: 'Give'
                                },
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            const foods = {
                text: 'Food',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Food.${tile._id}`) > -1 ? [] : tile.food?.map((food, index) => {
                    let text = food?.foodType?.name

                    const brainchipEffect = BRAINCHIP_TYPE_NAMES[food?.effect];

                    if (brainchipEffect) {
                        text = `${text} [${brainchipEffect}]`
                    }

                    return (
                        {
                            ...food,
                            text,
                            condition: getCondition(food),
                            hintClassName: 'quantity',
                            hint:food.quantity || 1,
                            markedForTradeCount: food.markedForTradeCount,
                            isMarkedWithPrice: !!food.tradePrice,
                            weight: this.getWeight(food, 'foodType'),
                            indentation: 2,
                            actions: {
                                'SPACE': {
                                    callback: (option) => (this.onFoodDrop(option)),
                                    text: 'Drop'
                                },
                                '.': {
                                    callback: (lock) => (this.onFoodEat(lock)),
                                    text: 'Eat'
                                },
                                ',': {
                                    callback: (lock) => (this.onFoodGive(lock)),
                                    text: 'Give'
                                },
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            const minerals = {
                text: 'Minerals',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Minerals.${tile._id}`) > -1 ? [] : tile.minerals?.map((mineral, index) => {
                    return (
                        {
                            ...mineral,
                            text: mineral?.mineralType?.name,
                            condition: getCondition(mineral),
                            hintClassName: 'quantity',
                            hint:mineral.quantity || 1,
                            markedForTradeCount: mineral.markedForTradeCount,
                            isMarkedWithPrice: !!mineral.tradePrice,
                            weight: this.getWeight(mineral, 'mineralType'),
                            indentation: 2,
                            actions: {
                                'SPACE': {
                                    callback: (option) => (this.onMineralDrop(option)),
                                    text: 'Drop'
                                },
                                ',': {
                                    callback: (lock) => (this.onMineralGive(lock)),
                                    text: 'Give'
                                },
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            let tools = {
                text: 'Tools',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Tools.${tile._id}`) > -1 ? [] : tile.tools?.map((tool, index) => {
                    const isToolEquipped = this.props.character.toolId === tool._id;
                    const torchDurationString = getTorchDurationString(tool, this.props.serverTimeOffset, isToolEquipped);
                    const text = isToolEquipped ? `${tool?.toolType?.name} [equipped]` : `${tool?.toolType?.name}`
                    return (
                        {
                            ...tool,
                            text,
                            condition: torchDurationString ? torchDurationString : getCondition(tool),
                            hintClassName: 'quantity',
                            hint:tool.quantity || 1,
                            markedForTradeCount: tool.markedForTradeCount,
                            isMarkedWithPrice: !!tool.tradePrice,
                            weight: this.getWeight(tool, 'toolType'),
                            indentation: 2,
                            actions: {
                                'SPACE': {
                                    callback: (option) => (this.onToolDrop(option)),
                                    text: 'Drop'
                                },
                                '.': {
                                    callback: (option) => (this.onEquipTool(option)),
                                    text: 'Equip'
                                },
                                '/': {
                                    callback: (option) => (this.onEquipTool({})),
                                    text: 'Unequip'
                                },
                                ',': {
                                    callback: (lock) => (this.onToolGive(lock)),
                                    text: 'Give'
                                },
                            }
                        }
                    )
                }),
                actions: {
                    ...menuOptions
                }
            }

            const metals = {
                text: 'Metals',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Metals.${tile._id}`) > -1 ? [] : tile.metals?.map((metal, index) => {
                    return {
                        ...metal,
                        text: metal?.metalType?.name,
                        condition: getCondition(metal),
                        hintClassName: 'quantity',
                        hint:metal.quantity || 1,
                        markedForTradeCount: metal.markedForTradeCount,
                        isMarkedWithPrice: !!metal.tradePrice,
                        weight: this.getWeight(metal, 'metalType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onMetalDrop(option)),
                                text: 'Drop'
                            },
                            ',': {
                                callback: (lock) => (this.onMetalGive(lock)),
                                text: 'Give'
                            },
                        }

                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const keys = {
                text: 'Keys',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Keys.${tile._id}`) > -1 ? [] : tile.keys?.map((key, index) => {
                    return {
                        ...key,
                        text: `${key?.keyType?.name}(${getKeyDescription(key?.lockId)})`,
                        condition: getCondition(key),
                        hintClassName: 'quantity',
                        hint:key.quantity || 1,
                        markedForTradeCount: key.markedForTradeCount,
                        isMarkedWithPrice: !!key.tradePrice,
                        weight: this.getWeight(key, 'keyType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onKeyDrop(option)),
                                text: 'Drop'
                            },
                            ',': {
                                callback: (lock) => (this.onKeyGive(lock)),
                                text: 'Give'
                            },
                            ';': {
                                callback: (key) => (this.onKeyCopy(key)),
                                text: 'Copy'
                            },
                        }

                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const writingSurfaces = {
                text: 'Writing Surfaces',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Writing Surfaces.${tile._id}`) > -1 ? [] : tile.writingSurfaces?.map((writingSurface, index) => {
                    return {
                        ...writingSurface,
                        text: writingSurface?.writingSurfaceType?.name,
                        condition: getCondition(writingSurface),
                        hintClassName: 'quantity',
                        hint:writingSurface.quantity || 1,
                        markedForTradeCount: writingSurface.markedForTradeCount,
                        isMarkedWithPrice: !!writingSurface.tradePrice,
                        weight: this.getWeight(writingSurface, 'writingSurfaceType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onWritingSurfaceDrop(option)),
                                text: 'Drop'
                            },
                            '\\': {
                                callback: (lock) => (setTimeout(() => (this.onLookAtWritingSurface(lock)))),
                                text: 'Look'
                            },
                            '.': {
                                callback: (lock) => (setTimeout(() => (this.onEditWritingSurface(lock)))),
                                text: 'Write'
                            },
                            '/': {
                                callback: (lock) => (setTimeout(() => (this.onEditDrawingSurface(lock)))),
                                text: 'Draw'
                            },
                            ',': {
                                callback: (lock) => (this.onWritingSurfaceGive(lock)),
                                text: 'Give'
                            },
                            ';': {
                                callback: (writingSurface) => (this.onWritingSurfaceCopy(writingSurface)),
                                text: 'Copy'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const writingImplements = {
                text: 'Writing Implements',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Writing Implements.${tile._id}`) > -1 ? [] : tile.writingImplements?.map((writingImplement, index) => {
                    return {
                        ...writingImplement,
                        text: writingImplement?.writingImplementType?.name,
                        condition: getCondition(writingImplement),
                        hintClassName: 'quantity',
                        hint:writingImplement.quantity || 1,
                        markedForTradeCount: writingImplement.markedForTradeCount,
                        isMarkedWithPrice: !!writingImplement.tradePrice,
                        weight: this.getWeight(writingImplement, 'writingImplementType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onWritingImplementDrop(option)),
                                text: 'Drop'
                            },
                            ',': {
                                callback: (lock) => (this.onWritingImplementGive(lock)),
                                text: 'Give'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const weapons = {
                text: 'Weapons',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Weapons.${tile._id}`) > -1 ? [] : tile.weapons?.map((weapon, index) => {
                    const isToolEquipped = this.props.character.weaponId === weapon._id;
                    const combatValues = ` ${weapon.weaponType.damage}ϴ ${weapon.weaponType?.toHit}ϰ ${weapon.weaponType?.penetration}➚`
                    let text = `${weapon?.weaponType?.name} ${combatValues}`
                    const isToolRusted = weapon.isRusted;
                    text = isToolRusted ? `${text} [rusted]` : `${text}`
                    text = isToolEquipped ? `${text} [equipped]` : `${text}`
                    return {
                        ...weapon,
                        text,
                        condition: getCondition(weapon),
                        hintClassName: 'quantity',
                        hint:weapon.quantity || 1,
                        markedForTradeCount: weapon.markedForTradeCount,
                        isMarkedWithPrice: !!weapon.tradePrice,
                        weight: this.getWeight(weapon, 'weaponType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onWeaponDrop(option)),
                                text: 'Drop'
                            },
                            '.': {
                                callback: (lock) => (this.onEquipWeapon(lock)),
                                text: 'Equip'
                            },
                            '/': {
                                callback: (lock) => (this.onEquipWeapon({})),
                                text: 'Unequip'
                            },
                            ',': {
                                callback: (lock) => (this.onWeaponGive(lock)),
                                text: 'Give'
                            },
                            '\\': {
                                callback: (option) => (setTimeout(() => (this.onLookAtWritingSurface({ ...option, serviceName: 'weapon-instances' })))),
                                text: 'Look'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const armours = {
                text: 'Armour',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Armour.${tile._id}`) > -1 ? [] : tile.armour?.map((armour) => {
                    const isToolEquipped = this.props.character.armourId === armour._id;
                    const combatValues = `${armour?.armourType?.armour}❤ ${armour?.armourType?.dodge}❥`
                    const isToolRusted = armour.isRusted;
                    let text = isToolEquipped ? `${armour?.armourType?.name} ${combatValues} [equipped]` : `${armour?.armourType?.name} ${combatValues}`
                    text = isToolRusted ? `${text} [rusted]` : `${text}`
                    return {
                        ...armour,
                        text,
                        condition: getCondition(armour),
                        hintClassName: 'quantity',
                        hint:armour.quantity || 1,
                        markedForTradeCount: armour.markedForTradeCount,
                        isMarkedWithPrice: !!armour.tradePrice,
                        weight: this.getWeight(armour, 'armourType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onArmourDrop(option)),
                                text: 'Drop'
                            },
                            '.': {
                                callback: (lock) => (this.onEquipArmour(lock)),
                                text: 'Equip'
                            },
                            '/': {
                                callback: (lock) => (this.onEquipArmour({})),
                                text: 'Unequip'
                            },
                            ',': {
                                callback: (lock) => (this.onArmourGive(lock)),
                                text: 'Give'
                            },
                            '\\': {
                                callback: (option) => (setTimeout(() => (this.onLookAtWritingSurface({ ...option, serviceName: 'armour-instances' })))),
                                text: 'Look'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const clothing = {
                text: 'Clothing',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Clothing.${tile._id}`) > -1 ? [] : tile.clothing?.map((clothing, index) => {
                    const isClothingEquipped = this.props.character?.clothingItems?.find(item => (item.itemId === clothing._id))
                    const combatValues = `${clothing.clothingType?.armour}❤ ${clothing.clothingType?.dodge}❥`
                    const isToolRusted = clothing.isRusted;
                    let text = isClothingEquipped ? `${clothing?.clothingType?.name} ${combatValues} [equipped]` : `${clothing?.clothingType?.name} ${combatValues}`
                    text = isToolRusted ? `${text} [rusted]` : `${text}`
                    return {
                        ...clothing,
                        text,
                        condition: getCondition(clothing),
                        hintClassName: 'quantity',
                        hint:clothing.quantity || 1,
                        markedForTradeCount: clothing.markedForTradeCount,
                        isMarkedWithPrice: !!clothing.tradePrice,
                        weight: this.getWeight(clothing, 'clothingType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onClothingDrop(option)),
                                text: 'Drop'
                            },
                            '.': {
                                callback: (lock) => (this.onWearClothing(lock)),
                                text: 'Equip'
                            },
                            '/': {
                                callback: (lock) => (this.onRemoveClothing(lock)),
                                text: 'Unequip'
                            },
                            ',': {
                                callback: (lock) => (this.onClothingGive(lock)),
                                text: 'Give'
                            },
                            '\\': {
                                callback: (option) => (setTimeout(() => (this.onLookAtWritingSurface({ ...option, serviceName: 'clothing-instances' })))),
                                text: 'Look'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const jewellery = {
                text: 'Jewellery',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Jewellery.${tile._id}`) > -1 ? [] : tile.jewellery?.map((jewellery, index) => {
                    const isJewelleryEquipped = this.props.character?.clothingItems?.find(item => (item.itemId === jewellery._id))
                    const text = isJewelleryEquipped ? `${jewellery?.jewelleryType?.name} [equipped]` : `${jewellery?.jewelleryType?.name}`
                    return {
                        ...jewellery,
                        text,
                        condition: getCondition(jewellery),
                        hintClassName: 'quantity',
                        hint:jewellery.quantity || 1,
                        markedForTradeCount: jewellery.markedForTradeCount,
                        isMarkedWithPrice: !!jewellery.tradePrice,
                        weight: this.getWeight(jewellery, 'jewelleryType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onJewelleryDrop(option)),
                                text: 'Drop'
                            },
                            '.': {
                                callback: (lock) => (this.onWearJewellery(lock)),
                                text: 'Equip'
                            },
                            '/': {
                                callback: (lock) => (this.onRemoveJewellery(lock)),
                                text: 'Unequip'
                            },
                            ',': {
                                callback: (lock) => (this.onJewelleryGive(lock)),
                                text: 'Give'
                            },
                            '\\': {
                                callback: (option) => (setTimeout(() => (this.onLookAtWritingSurface({ ...option, serviceName: 'jewellery-instances' })))),
                                text: 'Look'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const tents = {
                text: 'Tents',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Tents.${tile._id}`) > -1 ? [] : tile.tents?.map((tent, index) => {
                    return {
                        ...tent,
                        text: tent?.tentType?.name,
                        condition: getCondition(tent),
                        hintClassName: 'quantity',
                        hint:tent.quantity || 1,
                        markedForTradeCount: tent.markedForTradeCount,
                        isMarkedWithPrice: !!tent.tradePrice,
                        weight: this.getWeight(tent, 'tentType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onTentDrop(option)),
                                text: 'Drop'
                            },
                            '.': {
                                callback: (lock) => (this.onEquipTent(lock)),
                                text: 'Equip'
                            },
                            '/': {
                                callback: (lock) => (this.onUnequipTent(lock)),
                                text: 'Equip'
                            },
                            ',': {
                                callback: (lock) => (this.onTentGive(lock)),
                                text: 'Give'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const locks = {
                text: 'Locks',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Locks.${tile._id}`) > -1 ? [] : tile.locks?.filter(lock => (!lock.isInstalled)).map((lock, index) => {
                    return {
                        ...lock,
                        text: `${lock?.lockType?.name} (${getKeyDescription(lock?.lockId)})`,//
                        condition: getCondition(lock),
                        hintClassName: 'quantity',
                        hint:lock.quantity || 1,
                        markedForTradeCount: lock.markedForTradeCount,
                        isMarkedWithPrice: !!lock.tradePrice,
                        weight: this.getWeight(lock, 'lockType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onLockDrop(option)),
                                text: 'Drop'
                            },
                            '.': {
                                callback: (lock) => (this.onInstallLock(lock)),
                                text: 'install'
                            },
                            ',': {
                                callback: (lock) => (this.onLockGive(lock)),
                                text: 'Give'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const coins = {
                text: 'Coins',
                parentId: tile._id,
                childOptions: (this.state.hiddenOptions.indexOf(`Coins.${tile._id}`) > -1 || tile.coins === undefined) ? [] : tile.coins?.map((coin, index) => {
                    const coinName = coin?.coinType?.name || '';
                    const coinSymbol = coin.coinType?.currency?.symbol || ''

                    const value = 1000 / coin.coinRecipe.quantity

                    return {
                        ...coin,
                        text: `${coinName} (${coinSymbol}${value})`,
                        hintClassName: 'quantity',
                        hint:coin.quantity || 1,
                        markedForTradeCount: coin.markedForTradeCount,
                        isMarkedWithPrice: !!coin.tradePrice,
                        weight: this.getWeight(coin, 'coinType'),
                        indentation: 2,
                        actions: {}
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const combineReducer = (combinedTools, toolInstance, index) => {
                const existingTool = combinedTools.find(tool => (tool.text === toolInstance.text && tool.graffiti === toolInstance.graffiti && tool.condition === toolInstance.condition && tool.level === toolInstance.level))

                if (existingTool) {
                    existingTool.instances.push({ ...toolInstance })
                    existingTool.hint++;
                } else {
                    combinedTools.push({
                        ...toolInstance,
                        instances: [ { ...toolInstance } ],
                        hint: toolInstance.quantity || 1
                    })
                }

                return combinedTools
            }

            let options = [
                { ...materials, indentation: 1 },
                ...materials.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...foods, indentation: 1 },
                ...foods.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...minerals, indentation: 1 },
                ...minerals.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...tools, indentation: 1 },
                ...tools.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...metals, indentation: 1 },
                ...metals.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...keys, indentation: 1 },
                ...keys.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...writingSurfaces, indentation: 1 },
                ...writingSurfaces.childOptions.reduce(combineReducer, []).sort((a, b) => {
                    if (a.graffiti && a.graffiti[0] && a.graffiti[0].children && a.graffiti[0].children[0]?.text && b.graffiti && b.graffiti[0]?.children[0]?.text) {
                        return a.graffiti[0]?.children[0]?.text.localeCompare(b.graffiti[0]?.children[0]?.text)
                    }

                    return a.text?.localeCompare(b.text)
                }),
                { ...writingImplements, indentation: 1 },
                ...writingImplements.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...weapons, indentation: 1 },
                ...weapons.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...armours, indentation: 1 },
                ...armours.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...clothing, indentation: 1 },
                ...clothing.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...jewellery, indentation: 1 },
                ...jewellery.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...tents, indentation: 1 },
                ...tents.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...locks, indentation: 1 },
                ...locks.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...coins, indentation: 1 },
                ...coins?.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
            ]
            .filter(option => {
                if (option.childOptions?.length === 0 && this.state.hiddenOptions.indexOf(`${option.text}.${option.parentId}`) === -1) {
                    return false;
                }

                return true
            });
            // 

            const installedLock = tile.locks.find(lock => (lock.isInstalled));

            let text = tile.tileType?.name === 'ITEM' ? 'Loose' : tile.tileType?.name || 'Loose'

            const actions = {
                'SPACE': {
                    callback: (option) => (this.onContainerDrop(option)),
                    text: 'Drop'
                },
                '\\': {
                    callback: (option) => {
                        return setTimeout(() => (this.onLookAtWritingSurface({ ...option, serviceName: 'tile-instances' })))
                    },
                    text: tile.tileType?.name === 'Book' ? 'Read' : 'Look'
                },
            }

            if (installedLock) {
                if (installedLock.lockDirection) {
                    text += ` [${installedLock.lockDirection}`
                } else {
                    text += ` [${installedLock.lockDirection}]`
                }

                if (installedLock.isLocked) {
                    text += ' [locked]'
                } else {
                    text += ' [unlocked]'
                }
            }

            return [
                {
                    ...tile,
                    text,
                    condition: getCondition(tile),
                    actions: tile.tileType?.name ? actions : undefined
                },
                ...options
            ]
        })

        // to enable deep level flatten use recursion with reduce and concat
        function flatDeep(arr, d = 1) {
           return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
                        : arr.slice();
        };

        tiles = flatDeep(tiles);

        tiles = this.correctShortcutKeys(tiles);

        return (
            <div className="my-inventory">
                <p className="inventory-title">&nbsp;[ <span className="title">Inventory</span> ]&nbsp;</p>
                {
                    this.props.isDisabled ? ('') : (
                        <div className="inventory-actions-menu-container">
                            <Menu
                                isDisabled={this.state.isDisabled || this.state.isDirectionInputShowing || this.state.isQuantityInputShowing || this.props.isCharacterListShowing}
                                highlightedOption={(item) => (this.props.onSelectedItem(item))}
                                options={
                                    tiles
                                }
                                tradeIcon="eye"
                                menuContainer="inventory-actions-menu-container"
                            />
                        </div>
                    )
                }
                <p className="inventory-total-weight">{this.totalWeight}/{this.props.maxInventoryWeight}</p>

                {this.state.isEditWritingSurfaceShowing &&
                    <EditWritingSurface
                        text={this.state.writingSurface.text}
                        paints={this.props.containers[this.props.containers.length - 1].inventory.filter(item => (item.materialType?.colourHex))}
                        isReadOnly={this.state.writingSurface.writingSurfaceType.isReadOnly}
                        onSave={this.onSaveWritingSurface.bind(this)}
                        onClose={() => (this.setState({isEditWritingSurfaceShowing: false}))}
                    />
                }

                {this.props.isQuantityInputShowing && this.state.isQuantityInputShowing &&
                    <QuantityInput
                        quantity={this.state.maxQuantity}
                        onQuantitySupplied={this.state.onQuantitySupplied}
                        onQuantityClosed={this.state.onQuantityClosed}
                    />
                }

                {this.state.isDirectionInputShowing &&
                    <DirectionInput
                        text={"Side of the door which is 'outside':"}
                        onDirectionSupplied={this.onDirectionSupplied.bind(this)}
                        onDirectionClosed={this.onDirectionClosed.bind(this)}
                    />
                }

                {this.props.isCharacterListShowing && <CharacterList onItemSelect={this.state.onCharacterSelected.bind(this)} />}

                <div className="crafting-close-button"
                    onClick={() => (this.props.closeInventory())}>
                    <span className="shortcut">ESC</span> to exit
                </div>
            </div>
        )
    }
}

const mapToStateProps = state => {
    const inventory = selectInventory(state)
    const food = selectCharacterFood(state)
    const tiles = selectTiles(state);
    const character = selectCharacter(state);
    const minerals = selectCharacterMinerals(state);
    const tools = selectCharacterTools(state);
    const metals = selectCharacterMetals(state);
    const keys = selectCharacterKeys(state);
    const itemTileType = selectTileTypeByName(state, tileTypes.ITEM)
    const writingSurfaces = selectCharacterWritingSurfaces(state);
    const writingImplements = selectCharacterWritingImplements(state);
    const weapons = selectCharacterWeapons(state);
    const armour = selectCharacterArmour(state);
    const clothing = selectCharacterClothing(state);
    const jewellery = selectCharacterJewellery(state);
    const tents = selectCharacterTents(state);
    const locks = selectCharacterLocks(state);

    const isQuantityInputShowing = selectIsQuantityInputOpen(state);
    const isCharacterListShowing = selectIsCharacterListShowing(state);
    const neighbouringCharacters = selectNeighbouringCharacters(state, character);
    const containers = selectCharacterTiles(state);

    const looseTileType = selectTileTypeByName(state, tileTypes.ITEMS)

    let maxInventoryWeight = selectMaximumInventoryWeight(state)

    containers.byId.forEach(tile => {
        tile.inventory =  [ ...selectTileInventory(state, tile._id) ];
        tile.food =  [ ...selectTileFood(state, tile._id) ];
        tile.minerals =  [ ...selectTileMinerals(state, tile._id) ];
        tile.tools =  [ ...selectTileTools(state, tile._id) ];
        tile.metals =  [ ...selectTileMetals(state, tile._id) ];
        tile.keys =  [ ...selectTileKeys(state, tile._id) ];
        tile.writingSurfaces =  [ ...selectTileWritingSurfaces(state, tile._id) ];
        tile.writingImplements =  [ ...selectTileWritingImplements(state, tile._id) ];
        tile.weapons =  [ ...selectTileWeapons(state, tile._id) ];
        tile.armour =  [ ...selectTileArmour(state, tile._id) ];
        tile.clothing =  [ ...selectTileClothing(state, tile._id) ];
        tile.jewellery =  [ ...selectTileJewellery(state, tile._id) ];
        tile.tents =  [ ...selectTileTents(state, tile._id) ];
        tile.locks =  [ ...selectTileLocks(state, tile._id) ];
    })

    containers.byId.push({
        _id: 'loose',
        inventory: inventory.inventory,
        food,
        minerals,
        tools,
        metals,
        keys,
        writingSurfaces,
        writingImplements,
        weapons,
        armour,
        clothing,
        jewellery,
        tents,
        locks,
        coins: []
    })

    const currencies = selectCurrencies(state);
    let coinTypes = selectCoinTypes(state);
    let coins = selectCoins(state);
    const coinRecipes = selectCoinRecipes(state);

    const currency = currencies.find(currency => (currency.groupId === character.groupId));

    coinTypes = coinTypes.map(coinType => {
        return {
            ...coinType,
            currency: currencies.find(currency => currency._id === coinType.currencyId)
        }
    })

    coins = coins.map(coin => {
        return {
            ...coin,
            coinType: coinTypes.find(type => type._id === coin.coinTypeId),
            coinRecipe: coinRecipes.find(recipe => recipe.coinTypeId === coin.coinTypeId)
        }
    })

    let totalMoney = 0;

    coins.forEach(coin => {
        totalMoney += (1000 / (coin.coinRecipe?.quantity)) * (coin.quantity || 1)
    })

    if (totalMoney > 0) {
        containers.byId.push({
            _id: 'wallet',
            inventory: [],
            food: [],
            minerals: [],
            tools: [],
            metals: [],
            keys: [],
            writingSurfaces: [],
            writingImplements: [],
            weapons: [],
            armour: [],
            clothing: [],
            jewellery: [],
            tents: [],
            locks: [],
            coins,
            tileType: {
                name: `Wallet (${currencies[0]?.symbol}${totalMoney})`
            }
        })
    }

    const serverTimeOffset = selectServerTimeOffset(state);

    const tileLocks = selectAllTileLocks(state);

    const locationWeight = selectLocationWeight(state, character.position);

    return {
        currency,
        character,
        maxInventoryWeight,
        tiles,
        itemTileType,
        isQuantityInputShowing,
        isCharacterListShowing,
        neighbouringCharacters,
        containers: containers.byId,
        serverTimeOffset,
        tileLocks,
        locationWeight
    }
}

export default connect(
    mapToStateProps,
    {
        createTileAsync,
        addFoodToTileAsync,
        addMaterialToTileAsync,
        addMineralToTileAsync,
        addToolToTileAsync,
        addMetalToTileAsync,
        addKeyToTileAsync,
        copyKeyAsync,
        addWritingSurfaceToTileAsync,
        addWritingImplementToTileAsync,
        editWritingSurfaceAsync,
        addWeaponToTileAsync,
        addArmourToTileAsync,
        addClothingToTileAsync,
        addJewelleryToTileAsync,
        equipToolAsync,
        equipWeaponAsync,
        equipArmourAsync,
        equipClothingAsync,
        equipJewelleryAsync,
        equipTentLocally,
        unequipClothingAsync,
        unequipJewelleryAsync,
        unequipTentLocally,
        showQuantityInput,
        hideQuantityInput,
        chooseCharacterToGiveTo,
        hideCharacterList,
        addMaterialToCharacterAsync,
        addFoodToCharacterAsync,
        addMineralToCharacterAsync,
        addToolToCharacterAsync,
        addMetalToCharacterAsync,
        addKeyToCharacterAsync,
        addWritingSurfaceToCharacterAsync,
        addWritingImplementToCharacterAsync,
        addWeaponToCharacterAsync,
        addArmourToCharacterAsync,
        addClothingToCharacterAsync,
        addJewelleryToCharacterAsync,
        addTentToCharacterAsync,
        addTentToTileAsync,
        addTileToGroundAsync,
        addTileToTileAsync,
        addTileToCharacterAsync,
        addLockToTileAsync,
        addLockToCharacterAsync,
        installLockAsync,
        eatFoodAsync,
        deleteFoodAsync,
        closeInventory,
        removeTile,
        updateTradeAsync,
        showWritingSurface,
        showDrawingSurface,
        unlockLockAsync,
        lockLockAsync,
        copyWritingSurfaceAsync,
        setIsOverencumbered,
        createNewMessage
    }
)(InventoryActions);