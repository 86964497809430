import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { newZone, showZone, disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectCharacters,
    selectCharacterZones,
    selectOrders,
    selectPanel,
    selectCharacterPanels,
    selectOrganisations,
    selectCharacterOrders
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { createOrganisationAsync } from '../../redux/actions/organisation.actions'
import { deleteOrderAsync, updateOrderAsync, skipOrderAsync, createOrderAsync, deleteCompletedOrderAsync } from '../../redux/actions/order.actions';
import { WORLD_WIDTH, WORLD_HEIGHT } from '../../services/biome-geography'

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';


import DirectionInput from '../utils/direction-input/DirectionInput';
import QuantityInput from '../utils/quantity-input/QuantityInput';

import { client } from '../../services/client';

import './Scheduler.css';

const SCHEDULE_REPETITIONS = {
    SINGLE_TIME: 's',
    ONCE_DAILY: 'd',
    INDEFINITE_DAILY: 'i',
    REACTION: 'r'
}

const SCHEDULE_REPETITION_TEXTS_MAP = {
    's': '',
    'd': ' Repeats Daily',
    'i': ' Repeat Until Sleep',
    'r': ' Reaction'
}

class Scheduler extends React.Component {
    state = {
        navigationHandler: this.navigationHandler.bind(this),
        isRepetitionsMenuShowing: false,
        updatedOrders: []
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    async navigationHandler(event) {
        if (this.state.isSetTextShowing || this.state.isMemberSelectShowing) {
            return;
        }

        if (!this.state.isRepetitionsMenuShowing) {
            if (event.key === '/') {
                if (this.state.selectedItem?.hint.indexOf('DONE') === -1) {
                    return;
                }

                const newSelectedItem = this.props.orders.find(order => (order._id === this.state.selectedItem?._id))

                if (!newSelectedItem) {
                    return;
                }

                this.props.createOrderAsync({
                    ...newSelectedItem,
                })

                return;
            }

            if (event.key === '>') {
                if (!this.state.selectedItem) {
                    return;
                }

                const orderToRepeat = this.props.orders.find(order => (order._id === this.state.selectedItem?._id))

                if (orderToRepeat.weight === SCHEDULE_REPETITIONS.REACTION) {
                    return;
                }

                const direction = await this.getDirection();

                this.setState({ isQuantityInputShowing: true });

                const quantity = await new Promise((resolve, reject) => {
                    this.onQuantitySupplied = (quantity) => {
                        this.setState({isQuantityInputShowing: false})
                        resolve(Number(quantity));
                    }

                    this.onQuantityClosed = () => {
                        this.setState({isQuantityInputShowing: false})
                        reject('No quantity supplied');
                    }
                });


                const { panelIds, zIndexes } = this.getPanelIds(orderToRepeat, direction, quantity);

                // If it's move, we just move one time x panels in direction of course.
                // For all the other orders, we have to fulfill the order on each panel in that direction.
                if (orderToRepeat.type === 'move') {
                    if (panelIds.length > 0) {
                        this.createOrder(orderToRepeat, panelIds[panelIds.length - 1]);
                    } else if (zIndexes.length > 0) {
                        this.createOrder(orderToRepeat, undefined, zIndexes[zIndexes.length - 1]);
                    }
                } else {
                    if (panelIds.length > 0) {
                        panelIds.forEach(async (panelId) => {
                            this.createOrder(orderToRepeat, panelId);
                        })
                    } else if (zIndexes.length > 0) {
                        zIndexes.forEach(async (z) => {
                            this.createOrder(orderToRepeat, undefined, z);
                        })
                    }
                }

                return;
            }

            if (event.key === 'r') {
                const newSelectedItem = this.state.orders.find(order => (order._id === this.state.selectedItem?._id))

                if (!newSelectedItem) {
                    return;
                }

                if (newSelectedItem.weight === SCHEDULE_REPETITIONS.REACTION) {
                    return;
                }

                this.setState({
                    isRepetitionsMenuShowing: true,
                    selectedItem: {
                        ...this.state.selectedItem,
                        weight: newSelectedItem.weight
                    }
                })
                return;
            }

            if (event.key === 'p') {
                const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

                if (selectedOrder.weight !== SCHEDULE_REPETITIONS.REACTION) {
                    return;
                }

                this.setState({
                    isCharacterPanelSelectShowing: true
                })

                return;
            }

            if (event.key === 'c') {
                const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

                if (selectedOrder.weight !== SCHEDULE_REPETITIONS.REACTION) {
                    return;
                }

                this.setState({
                    isCharacterSelectShowing: true
                })

                return;
            }

            if (event.key === 'g') {
                const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

                if (selectedOrder.weight !== SCHEDULE_REPETITIONS.REACTION) {
                    return;
                }

                this.setState({
                    isCharacterOrganisationSelectShowing: true
                })

                return;
            }

            if (event.key === 'x') {
                if (this.state.selectedItem?.hint.indexOf('DONE') > -1) {
                    return;
                }

                // skip for the day!
                const orderToSkip = this.props.orders.find(order => (order._id === this.state.selectedItem?._id))

                if (!orderToSkip || orderToSkip?.isCompleted) {
                    return;
                }

                if (orderToSkip.weight === SCHEDULE_REPETITIONS.REACTION) {
                    return;
                }

                this.props.skipOrderAsync({
                    ...orderToSkip,
                })
            }

            if (event.key === 'd') {
                const orderToDelete = { ...this.state.selectedItem }

                if (!orderToDelete || !orderToDelete._id) {
                    return;
                }

                if (orderToDelete.weight === SCHEDULE_REPETITIONS.REACTION) {
                    return;
                }

                const orderToDeleteIndex = this.state.orders.findIndex(order => (order._id === orderToDelete._id));

                const newOrders = [ ...this.state.orders ]
                newOrders.splice(orderToDeleteIndex, 1);

                this.setState({
                    isRepetitionsMenuShowing: false,
                    updatedOrders: [ ...newOrders ],
                    orders: [ ...newOrders ]
                })

                if (orderToDelete.weight === SCHEDULE_REPETITIONS.SINGLE_TIME && orderToDelete.hint.indexOf('DONE') > -1) {
                    this.props.deleteCompletedOrderAsync({
                        ...orderToDelete
                    })

                    return;
                }

                this.props.deleteOrderAsync({
                    ...orderToDelete
                })

                return;
            }

            if (event.key === 'w') {
                const orderToIncrease = { ...this.state.selectedItem }
                const orderToIncreaseIndex = this.state.orders.findIndex(order => (order._id === orderToIncrease._id));

                if (!orderToIncrease || !orderToIncrease._id) {
                    return;
                }

                if (orderToIncreaseIndex === 0) {
                    // Cannot increase priority of highest priority order
                    return;
                }

                const orderToDecreaseIndex = orderToIncreaseIndex - 1
                const orderToDecrease = { ...this.state.orders[orderToDecreaseIndex] };

                if (orderToIncrease.text.indexOf('[current]') > -1 || orderToDecrease.text.indexOf('[current]') > -1) {
                    // Cannot switch priority of current order
                    return;
                }

                if (orderToDecrease.weight !== orderToIncrease.weight) {
                    // Cannot switch priority of orders of different repetition types...?
                    return;
                }

                const newOrders = [ ...this.state.orders ]

                newOrders[orderToDecreaseIndex] = orderToIncrease
                newOrders[orderToIncreaseIndex] = orderToDecrease

                this.props.updateOrderAsync({
                    _id: orderToDecrease._id,
                    priority: orderToDecrease.priority + 1
                })

                this.props.updateOrderAsync({
                    _id: orderToIncrease._id,
                    priority: orderToIncrease.priority - 1
                })

                orderToIncrease.priority = orderToIncrease.priority - 1;
                orderToDecrease.priority = orderToDecrease.priority + 1;

                this.setState({
                    isRepetitionsMenuShowing: false,
                    updatedOrders: [ ...newOrders ],
                    orders: [ ...newOrders ],
                    selectedItem: { ...orderToDecrease }
                })



                return;
            }

            if (event.key === 's') {
                const orderToDecrease = { ...this.state.selectedItem }

                if (!orderToDecrease || !orderToDecrease._id) {
                    return;
                }

                const orderToDecreaseIndex = this.state.orders.findIndex(order => (order._id === orderToDecrease._id));

                if (orderToDecreaseIndex === this.state.orders.length - 1) {
                    // Cannot decrease priority of lowest priority order
                    return;
                }

                const orderToIncreaseIndex = orderToDecreaseIndex + 1
                const orderToIncrease = { ...this.state.orders[orderToIncreaseIndex] };

                if (orderToIncrease.text.indexOf('[current]') > -1 || orderToDecrease.text.indexOf('[current]') > -1) {
                    // Cannot switch priority of current order
                    return;
                }

                if (orderToDecrease.weight !== orderToIncrease.weight) {
                    // Cannot switch priority of orders of different repetition types...?
                    return;
                }

                const newOrders = [ ...this.state.orders ]

                newOrders[orderToDecreaseIndex] = orderToIncrease
                newOrders[orderToIncreaseIndex] = orderToDecrease

                this.props.updateOrderAsync({
                    _id: orderToDecrease._id,
                    priority: orderToDecrease.priority + 1
                })

                this.props.updateOrderAsync({
                    _id: orderToIncrease._id,
                    priority: orderToIncrease.priority - 1
                })

                orderToIncrease.priority = orderToIncrease.priority - 1;
                orderToDecrease.priority = orderToDecrease.priority - 1;

                this.setState({
                    isRepetitionsMenuShowing: false,
                    updatedOrders: [ ...newOrders ],
                    orders: [ ...newOrders ],
                    selectedItem: { ...orderToIncrease }
                })
                return;
            }
        }

        if (event.key === 'o') {
            const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

            if (selectedOrder.weight !== SCHEDULE_REPETITIONS.REACTION) {
                return;
            }

            this.props.updateOrderAsync({
                ...selectedOrder,
                data: {
                    ...selectedOrder.data,
                    isOn: !selectedOrder.data.isOn
                }
            })
            return;
        }

        if (event.key === 'h') {
            const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

            this.setState({ isQuantityInputShowing: true });

            const quantity = await new Promise((resolve, reject) => {
                this.onQuantitySupplied = (quantity) => {
                    this.setState({isQuantityInputShowing: false})
                    resolve(Number(quantity));
                }

                this.onQuantityClosed = () => {
                    this.setState({isQuantityInputShowing: false})
                    reject('No quantity supplied');
                }
            });

            this.props.updateOrderAsync({
                ...selectedOrder,
                data: {
                    ...selectedOrder.data,
                    hunger: quantity
                }
            })

            return;
        }

        if (event.key === 'e') {
            const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

            this.setState({ isQuantityInputShowing: true });

            const quantity = await new Promise((resolve, reject) => {
                this.onQuantitySupplied = (quantity) => {
                    this.setState({isQuantityInputShowing: false})
                    resolve(Number(quantity));
                }

                this.onQuantityClosed = () => {
                    this.setState({isQuantityInputShowing: false})
                    reject('No quantity supplied');
                }
            });

            this.props.updateOrderAsync({
                ...selectedOrder,
                data: {
                    ...selectedOrder.data,
                    healthPoints: quantity
                }
            })

            return;
        }

        if (event.key === 'Escape' && this.state.isCharacterPanelSelectShowing) {
            event.preventDefault();
            this.setState({
                isCharacterPanelSelectShowing: false
            })
            return;
        }

        if (event.key === 'Escape' && this.state.isCharacterSelectShowing) {
            event.preventDefault();
            this.setState({
                isCharacterSelectShowing: false
            })
            return;
        }

        if (event.key === 'Escape' && this.state.isCharacterOrganisationSelectShowing) {
            event.preventDefault();
            this.setState({
                isCharacterOrganisationSelectShowing: false
            })
            return;
        }

        if (event.key === 'Escape' && this.state.isRepetitionsMenuShowing) {
            event.preventDefault();
            const updatedOrder = { ...this.state.selectedItem }
            const updatedOrderIndex = this.state.orders.findIndex(order => (order._id === updatedOrder._id));

            const newOrders = [ ...this.state.orders ]
            newOrders[updatedOrderIndex] = { ...this.state.selectedItem }

            if (updatedOrder.weight !== SCHEDULE_REPETITIONS.SINGLE_TIME && updatedOrder.hint.indexOf('DONE') > -1) {
                const existingOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id))

                if (existingOrder.isCompleted && existingOrder.weight === SCHEDULE_REPETITIONS.SINGLE_TIME) {
                    await this.props.createOrderAsync({
                        ...existingOrder,
                        weight: updatedOrder.weight
                    })

                    this.setState({
                        isRepetitionsMenuShowing: false,
                    })

                    return;
                }
            }

            if (updatedOrder.weight === SCHEDULE_REPETITIONS.SINGLE_TIME) {
                // Change order to put new order at the top (but at the bottom of the top list)
                const lastNonRepeatingOrderIndex = this.state.orders.findLastIndex(order => (order.weight === ''))

                const newOrders = [ ...this.state.orders ]
                const itemToMove = newOrders.splice(updatedOrderIndex, 1)[0]

                newOrders.splice(lastNonRepeatingOrderIndex + 1, 0, updatedOrder);

                this.setState({
                    isRepetitionsMenuShowing: false,
                    updatedOrders: [ ...newOrders ],
                    orders: [ ...newOrders ],
                    selectedItem: { ...newOrders[updatedOrderIndex] }
                })

                this.props.updateOrderAsync({
                    ...updatedOrder
                })
                return;
            }

            if (updatedOrder.weight === SCHEDULE_REPETITIONS.INDEFINITE_DAILY) {
                // IF you do not already have one of these orders
                const existingRepeatUntilSleepOrder = this.state.orders.find(order => (order.weight === SCHEDULE_REPETITIONS.INDEFINITE_DAILY))

                if (existingRepeatUntilSleepOrder) {
                    existingRepeatUntilSleepOrder.weight = SCHEDULE_REPETITIONS.ONCE_DAILY;

                    this.props.updateOrderAsync({
                        ...existingRepeatUntilSleepOrder
                    })
                }

                const newOrders = [ ...this.state.orders.filter(item => (item._id !== updatedOrder._id)), { ...updatedOrder} ]

                this.setState({
                    isRepetitionsMenuShowing: false,
                    updatedOrders: [ ...newOrders ],
                    orders: [ ...newOrders ],
                    selectedItem: { ...newOrders[updatedOrderIndex] }
                })

                this.props.updateOrderAsync({
                    ...updatedOrder
                })
                return;
            }

            this.props.updateOrderAsync({
                ...updatedOrder
            })

            this.setState({
                isRepetitionsMenuShowing: false,
                updatedOrders: [ ...this.state.updatedOrders, { ...this.state.selectedItem } ],
                orders: [ ...newOrders ]
            })
            return;
        }
    }

    getPanelIds(orderToRepeat, direction, quantity) {
        let panelId = orderToRepeat.panelId || orderToRepeat.data?.panelId;
        let z = orderToRepeat.z;

        if (!panelId) {
            throw new Error('Cannot repeat in direction for an order without a panel id')
        }

        let panelIds = [];
        let zIndexes = [];

        if (direction === 'north') {
            for (var i=0; i < quantity; i++) {
                panelId = panelId - WORLD_WIDTH;
                panelIds.push(panelId)
            }
        }

        if (direction === 'east') {
            for (var i=0; i < quantity; i++) {
                panelId = panelId + 1;
                panelIds.push(panelId)
            }
        }

        if (direction === 'south') {
            for (var i=0; i < quantity; i++) {
                panelId = panelId + WORLD_WIDTH;
                panelIds.push(panelId)
            }
        }

        if (direction === 'west') {
            for (var i=0; i < quantity; i++) {
                panelId = panelId - 1;
                panelIds.push(panelId)
            }
        }

        if (direction === 'up') {
            for (var i=0; i < quantity; i++) {
                z = z + 1;
                zIndexes.push(z)
            }
        }

        if (direction === 'down') {
            for (var i=0; i < quantity; i++) {
                z = z - 1;
                zIndexes.push(z)
            }
        }

        return { panelIds, zIndexes };
    }

    selectCharacterPanel(characterPanel) {
        const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

        let updatedPanelIds = [];

        if (selectedOrder.data?.panelIds) {
            if (selectedOrder.data.panelIds.find(panelId => panelId === characterPanel.panelId)) {
                // Remove it
                updatedPanelIds = [ ...selectedOrder.data.panelIds.filter(panelId => (panelId !== characterPanel.panelId)) ];
            } else {
                // Add it
                updatedPanelIds = [ ...selectedOrder.data.panelIds, characterPanel.panelId ];
            }
        } else {
            // Add it
            updatedPanelIds.push(characterPanel.panelId);
        }

        this.props.updateOrderAsync({
            ...selectedOrder,
            data: {
                ...selectedOrder.data,
                panelIds: updatedPanelIds
            }
        })
    }

    selectCharacter(character) {
        const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

        let updatedCharacterIds = [];

        if (selectedOrder.data?.characterIds) {
            if (selectedOrder.data.characterIds.find(characterId => characterId === character._id)) {
                // Remove it
                updatedCharacterIds = [ ...selectedOrder.data.characterIds.filter(characterId => (characterId !== character._id)) ];
            } else {
                // Add it
                updatedCharacterIds = [ ...selectedOrder.data.characterIds, character._id ];
            }
        } else {
            // Add it
            updatedCharacterIds.push(character._id);
        }

        this.props.updateOrderAsync({
            ...selectedOrder,
            data: {
                ...selectedOrder.data,
                characterIds: updatedCharacterIds
            }
        })
    }

    selectOrganisation(organisation) {
        const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

        let updatedOrganisationIds = [];

        if (selectedOrder.data?.organisationIds) {
            if (selectedOrder.data.organisationIds.find(organisationId => organisationId === organisation._id)) {
                // Remove it
                updatedOrganisationIds = [ ...selectedOrder.data.organisationIds.filter(organisationId => (organisationId !== organisation._id)) ];
            } else {
                // Add it
                updatedOrganisationIds = [ ...selectedOrder.data.organisationIds, organisation._id ];
            }
        } else {
            // Add it
            updatedOrganisationIds.push(organisation._id);
        }

        this.props.updateOrderAsync({
            ...selectedOrder,
            data: {
                ...selectedOrder.data,
                organisationIds: updatedOrganisationIds
            }
        })
    }

    async createOrder(orderToRepeat, panelId, z) {
        delete orderToRepeat._id

        if (panelId !== undefined) {
            await this.props.createOrderAsync({
                ...orderToRepeat,
                data: {
                    ...orderToRepeat.data,
                    panelId: orderToRepeat.data.panelId ? panelId : undefined
                },
                panelId,
            })
        }

        if (z !== undefined) {
            await this.props.createOrderAsync({
                ...orderToRepeat,
                data: {
                    ...orderToRepeat.data,
                    z: orderToRepeat.data.z ? Math.min(z, 0) : undefined
                },
                z: Math.min(z, 0),
            })
        }
    }

    getDirection() {
        this.setState({ isDirectionInputShowing: true });

        return new Promise((resolve, reject) => {
            this.onDirectionSupplied = (direction) => {
                this.setState({isDirectionInputShowing: false})
                resolve(direction);
            }

            this.onDirectionClosed = () => {
                this.setState({isDirectionInputShowing: false})
                reject('No direction supplied');
            }
        });
    }

    setRepetition(repetition) {
        const oldOrder = this.state.selectedItem

        this.setState({
            selectedItem: {
                ...oldOrder,
                weight: (repetition.text === ' No Repetitions') ? SCHEDULE_REPETITIONS.SINGLE_TIME : (repetition.text === ' Repeats Daily') ? SCHEDULE_REPETITIONS.ONCE_DAILY : SCHEDULE_REPETITIONS.INDEFINITE_DAILY
            }
        })
    }

    onOrderChosen(order) {
        this.setState({
            selectedItem: order
        })
    }

    static getDerivedStateFromProps(props, state) {
        state.orders = props.orders
            .sort((itemA, itemB) => {
                if (itemA.weight === SCHEDULE_REPETITIONS.REACTION && itemB.weight !== SCHEDULE_REPETITIONS.REACTION) {
                    return 1;
                }

                if (itemB.weight === SCHEDULE_REPETITIONS.REACTION && itemA.weight !== SCHEDULE_REPETITIONS.REACTION) {
                    return -1;
                }

                if (itemA.weight === SCHEDULE_REPETITIONS.SINGLE_TIME && itemB.weight !== SCHEDULE_REPETITIONS.SINGLE_TIME) {
                    return -1
                }

                if (itemB.weight === SCHEDULE_REPETITIONS.SINGLE_TIME && itemA.weight !== SCHEDULE_REPETITIONS.SINGLE_TIME) {
                    return 1
                }

                if (itemA.weight === SCHEDULE_REPETITIONS.ONCE_DAILY && itemB.weight !== SCHEDULE_REPETITIONS.ONCE_DAILY) {
                    return -1
                }

                if (itemB.weight === SCHEDULE_REPETITIONS.ONCE_DAILY && itemA.weight !== SCHEDULE_REPETITIONS.ONCE_DAILY) {
                    return 1
                }

                if (itemA.weight === SCHEDULE_REPETITIONS.INDEFINITE_DAILY && itemB.weight !== SCHEDULE_REPETITIONS.INDEFINITE_DAILY) {
                    return -1
                }

                if (itemB.weight === SCHEDULE_REPETITIONS.INDEFINITE_DAILY && itemA.weight !== SCHEDULE_REPETITIONS.INDEFINITE_DAILY) {
                    return 1
                }

                return itemA.priority - itemB.priority
            })
            .map(order => {
                const character = props.character;

                if (!order) {
                    return;
                }

                let directionString = '';

                if (order.panelId || order.data?.panelId) {
                    const panelId = order.panelId || order.data?.panelId;

                    const targetX = panelId % WORLD_WIDTH;
                    const targetY = Math.floor(panelId / WORLD_WIDTH);

                    // find coords of current panel
                    const currentX = character.panelId % WORLD_WIDTH;
                    const currentY = Math.floor(character.panelId / WORLD_WIDTH);

                    // transform so current panel === 0,0
                    const x = currentX - targetX
                    const y = currentY - targetY
                    const z = character.z - order.z

                    let directionXString = `${x} Panels West`;

                    if (x < 0) {
                        directionXString = `${x * -1} Panels East`
                    }

                    let directionYString = `${y} Panels North`;

                    if (y < 0) {
                        directionYString = `${y * -1} Panels South`
                    }

                    let directionZString = `${z} Panels Down`;

                    if (z < 0) {
                        directionZString = `${z} Panels Up`
                    }

                    if (x === 0) {
                        directionXString = ''
                    }

                    if (y === 0) {
                        directionYString = ''
                    }

                    if (isNaN(z) || z === 0) {
                        directionZString = ``
                    }

                    if (x === 0 && y === 0 && (isNaN(z) || z === 0)) {
                        let __x = order.x !== undefined ? order.x : order.data.x;
                        let __y = order.y !== undefined ? order.y : order.data.y;

                        if (order.data && order.data.panelAreas && order.data.panelAreas[0]) {
                            __x = order.data.panelAreas[0].x
                            __y = order.data.panelAreas[0].y
                        }

                        const _x = character.position.x - __x;
                        const _y = character.position.y - __y;

                        if (_x < 0) {
                            directionXString = `${_x * -1} Steps West`;
                        } else if (_x > 0) {
                            directionXString = `${_x} Steps East`;
                        } else {
                            directionXString = '';
                        }

                        if (order.data?.panelAreas && order.data?.panelAreas[0]) {
                            directionXString = `Starting at ${directionXString}`
                        }

                        if (_y < 0) {
                            directionYString = `${_y * -1} Steps South`;
                        } else if (_y > 0) {
                            directionYString = `${_y} Steps North`;
                        } else {
                            directionYString = '';
                        }
                    }

                    if (directionXString) {
                        directionYString = ` ${directionYString}`
                    }

                    if (directionZString) {
                        directionYString = `${directionYString} `
                    }

                    if (!directionXString && !directionYString && !directionZString) {
                        directionXString = 'Current Position'
                    }

                    directionString = `${directionXString}${directionYString}${directionZString}`
                }

                let text = order.type === 'gather' ? order.data?.resources : order.type;

                if (order.type === 'create') {
                    const craftedCount = order.data?.initialQuantity - order.data?.quantity
                    if (craftedCount) {
                        text = `${text} ${craftedCount}/${order.data?.initialQuantity} ${order.data.itemText}`
                    } else {
                        text = `${text} ${order.data.itemText}`
                    }
                }

                if (order.type === 'fetch') {
                    text = `${text} ${order.data.itemText}`
                }

                if (order.type === 'haul') {
                    // TODO: Display the name of the place youre hauling to 
                    // TODO: Display the name of the place youre hauling from
                }

                if (order.type === 'gather') {
                    text = `gather ${text}`
                }

                if (order.type === 'plant') {
                    text = `${text} seeds`
                }

                if (order.type === 'build') {
                    text = `${text} ${order.data.itemText}`
                }

                if (order.type === 'follow') {
                    text = `${text} ${order.data.itemText}`
                }

                if (order.type === 'fight') {
                    text = `${text} ${order.data.itemText}`
                    // TODO: Display the name of the animal type youre attacking
                    // or if its many, just write that :)
                }

                return {
                    text,
                    hint: order.step > 0 ? ` ACTIVE ${directionString}` : order.isCompleted ? ` DONE ${directionString}` : ` ${directionString}`,
                    weight: order.weight || SCHEDULE_REPETITIONS.SINGLE_TIME,
                    tradePrice: SCHEDULE_REPETITION_TEXTS_MAP[order.weight || SCHEDULE_REPETITIONS.SINGLE_TIME],
                    tradeOfferCount: order.priority,
                    priority: order.priority,
                    _id: order._id,
                    className: (order.isFullyCompleted || order.isCompleted) ? 'disabled' : ''
                }
            })

        return state;
    }

    render() {
        const selectedOrder = this.props.orders.find(order => (order._id === this.state.selectedItem?._id));

        return (
            <div className="conversation-tree-wizard">
                {
                    this.state.isCharacterPanelSelectShowing ? (
                        <Menu options={this.props.characterPanels.map(panel => ({
                            ...panel,
                            text: panel.locationName,
                            hint: selectedOrder.data?.panelIds?.find(panelId => (panelId === panel.panelId)) ? ' selected' : ''
                        }))}
                            menuContainer="equipment"
                            optionChosen={this.selectCharacterPanel.bind(this)}
                            isWeightShowing={false}
                        />
                    ) : this.state.isCharacterSelectShowing ? (
                        <Menu options={this.props.characters.map(character => ({
                            ...character,
                            text: character.name ?? 'Not present',
                            hint: selectedOrder.data?.characterIds?.find(characterId => (characterId === character._id)) ? ' selected' : ''
                        }))}
                            menuContainer="equipment"
                            optionChosen={this.selectCharacter.bind(this)}
                            isWeightShowing={false}
                        />
                    ) : this.state.isCharacterOrganisationSelectShowing ? (
                        <Menu options={this.props.organisations.map(organisation => ({
                            ...organisation,
                            text: organisation.name ?? 'Not present',
                            hint: selectedOrder.data?.organisationIds?.find(organisationId => (organisationId === organisation._id)) ? ' selected' : ''
                        }))}
                            menuContainer="equipment"
                            optionChosen={this.selectOrganisation.bind(this)}
                            isWeightShowing={false}
                        />
                    ) : this.state.isRepetitionsMenuShowing ? (
                        <>
                            <div className="equipment">
                                <Menu options={[
                                        { shortcut: '', text: ' No Repetitions', hint: this.state.selectedItem?.weight === SCHEDULE_REPETITIONS.SINGLE_TIME ? ' selected' : '', weight: '', },
                                        { shortcut: '', text: ' Repeats Daily', hint: this.state.selectedItem?.weight === SCHEDULE_REPETITIONS.ONCE_DAILY ? ' selected' : '', weight: '', },
                                        { shortcut: '', text: ' Repeat Until Sleep', hint: this.state.selectedItem?.weight === SCHEDULE_REPETITIONS.INDEFINITE_DAILY ? ' selected' : '', weight: '' },
                                    ].filter(option => (option.text === ' Repeat Until Sleep' && this.state.selectedItem?.text !== 'create' ? false : true))}
                                    menuContainer="equipment"
                                    isDisabled={!this.state.isRepetitionsMenuShowing}
                                    optionChosen={this.setRepetition.bind(this)}
                                    isWeightShowing={false}
                                />
                            </div>
                            <div className="conversation-tree-actions-container">
                                <p><span className="action">SPACE</span> - Select</p>
                                <p><span className="action">ESC</span> - Back to scheduled tasks</p>
                            </div>
                        </>
                    ) : (
                        <>
                            {
                                this.state.isDirectionInputShowing && (
                                    <DirectionInput
                                        text={'Choose which direction to repeat the order in'}
                                        onDirectionSupplied={this.onDirectionSupplied}
                                        onDirectionClosed={this.onDirectionClosed}
                                        includeZDirections={true}
                                    />
                                )
                            }
                            {
                                this.state.isQuantityInputShowing && (
                                    <QuantityInput
                                        quantity={1}
                                        onQuantitySupplied={this.onQuantitySupplied}
                                        onQuantityClosed={this.onQuantityClosed}
                                    />
                                )
                            }
                            <div className="equipment">
                                <Menu options={this.state.orders} //.map(order => ({ ...order, weight: SCHEDULE_REPETITION_TEXTS_MAP[order.weight] }))
                                    highlightedOption={this.onOrderChosen.bind(this)}
                                    menuContainer="equipment"
                                    isDisabled={this.state.isRepetitionsMenuShowing}
                                />
                            </div>
                            <div className="scheduler-actions">
                                <div className="conversation-tree-actions-container">
                                    <p><span className={this.state.selectedItem?.hint.indexOf('DONE') > -1 ? "action" : "disabled action"}>/</span> - Redo order</p>
                                    <p><span className="action">></span> - Repeat in panels to the...</p>
                                    <p><span className={this.state.selectedItem?.hint.indexOf('DONE') > -1 ? "disabled action" : "action"}>X</span> - Skip for the day</p>
                                    <p><span className="action">R</span> - Set repetitions</p>
                                    <p><span className="action">D</span> - Delete order</p>
                                    <p><span className="action">W</span> - Increase priority</p>
                                    <p><span className="action">S</span> - Decrease priority</p>
                                </div>
                                <div className="conversation-tree-actions-container">
                                    {selectedOrder?.weight === SCHEDULE_REPETITIONS.REACTION && (
                                        <>
                                            {selectedOrder.data?.isOn !== undefined && (<p><span className="action">O</span> - Is On: <span className="hint">{selectedOrder.data?.isOn ? 'true' : 'false'}</span></p>)}
                                            {selectedOrder.data?.hunger && (<p><span className="action">H</span> - Hunger trigger: <span className="hint">{selectedOrder.data?.hunger}</span></p>)}
                                            {selectedOrder.data?.healthPoints && (<p><span className="action">E</span> - Health points: <span className="hint">{selectedOrder.data?.healthPoints}</span></p>)}
                                            {selectedOrder.data?.characterIds && (<p><span className="action">C</span> - Character Ids: <span className="hint">{selectedOrder.data?.characterIds.map(characterId => (this.props.characters.find(char => char._id === characterId)?.name ?? 'Unknown')).join(', ')}</span></p>)}
                                            {selectedOrder.data?.organisationIds && (<p><span className="action">G</span> - Organisation Ids: <span className="hint">{selectedOrder.data?.organisationIds.map(organisationId => (this.props.organisations.find(org => org._id === organisationId)?.name ?? 'Unknown')).join(', ')}</span></p>)}
                                            {selectedOrder.data?.panelIds && (<p><span className="action">P</span> - Panels: <span className="hint">{(!selectedOrder.data?.panelIds || selectedOrder.data.panelIds.length === 0) ? 'All panels' : this.props.characterPanels.filter(panel => (selectedOrder.data?.panelIds.indexOf(panel.panelId) > -1)).map(panel => (panel.locationName)).join(', ')}</span></p>)}
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state)
    const orders = selectCharacterOrders(state, character._id)
    const panel = selectPanel(state);
    const characterPanels = selectCharacterPanels(state);
    const characters = selectCharacters(state);
    const organisations = selectOrganisations(state);

    return {
        orders,
        character,
        characterPanels,
        characters,
        organisations,
    }
}

export default connect(
    mapStateToProps,
    { deleteOrderAsync, updateOrderAsync, skipOrderAsync, createOrderAsync, deleteCompletedOrderAsync }
)(Scheduler);