import {
    GET_ORDERS_SUCCESS,
    ADD_ORDER,
    UPDATE_ORDER,
    REMOVE_ORDER,
    GET_COMPLETED_ORDERS_SUCCESS,
    ADD_COMPLETED_ORDER,
    UPDATE_COMPLETED_ORDER,
    REMOVE_COMPLETED_ORDER
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const getOrdersSuccess = payload => {
    return ({
        type: GET_ORDERS_SUCCESS,
        payload
    })
}

export const getCompletedOrdersSuccess = payload => {
    return ({
        type: GET_COMPLETED_ORDERS_SUCCESS,
        payload
    })
}

export const addCompletedOrder = payload => {
    return ({
        type: ADD_COMPLETED_ORDER,
        payload
    })
}

export const updateCompletedOrder = payload => {
    return ({
        type: UPDATE_COMPLETED_ORDER,
        payload
    })
}

export const removeCompletedOrder = payload => {
    return ({
        type: REMOVE_COMPLETED_ORDER,
        payload
    })
}

export const addOrder = payload => {
    return ({
        type: ADD_ORDER,
        payload
    })
}

export const updateOrder = payload => {
    return ({
        type: UPDATE_ORDER,
        payload
    })
}

export const removeOrder = payload => {
    return ({
        type: REMOVE_ORDER,
        payload
    })
}

export const deleteOrderAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'orders',
        commandType: 'remove',
        data: {
            ...payload
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const deleteCompletedOrderAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'completed-orders',
        commandType: 'remove',
        data: {
            ...payload
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const createOrderAsync = payload => dispatch => {
    delete payload._id;

    colyClient.room.send('doAction', {
        serviceName: 'orders',
        commandType: 'create',
        data: {
            ...payload
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const skipOrderAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'completed-orders',
        commandType: 'create',
        data: {
            ...payload
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const updateOrderAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'orders',
        commandType: 'patch',
        data: {
            ...payload,
            isActive: false
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}