import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import {
    disableKeyboardMovement,
    enableKeyboardMovement,
    showHelp,
    startLooking,
    hideAllMenus,
    showWorkshop,
    showSelectItems,
} from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPanel,
    selectCharacterMessages,
    selectEmbarkCharacterMessages,
    selectTotalMessages,
    selectCharacter,
    selectCharacters,
    selectIsMessagesFocussed,
    selectFocussedCharacterId,
    selectMessageOptionss,
    selectEmbarkCharacter,
    selectCurrentEmbarkGroup,
    selectCharacterById,
    selectConversationTree
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { colyClient } from '../../services/Panel-initialisation.service';
import { client } from '../../services/client';

import './ConversationTree.css';

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

// DISABLE DEFAULT SCROLL?

export const Hints = {
    trade: '[begin trade]',
    talk: '[begin talk]',
    restart: '[go back]',
    end: '[end]',
    continue: '[continue]',
    createEmbark: '[create embark]',
    soloEmbark: '[join random group]',
    action: ' [give order]'
}

class ConversationTree extends React.Component {
    state = {
        text: "",
        isFetching: false,
        isMessagesSet: false,
        isEmptyFocussedCharacterShowing: false,
        messages: [],
        showingMessageIndexes: [],
        navigationHandler: this.navigationHandler.bind(this)
    };

    handleInput = event => {
        
    };

    disableMovement = () => {
        store.dispatch(disableKeyboardMovement());
    }

    enableMovement = () => {
        store.dispatch(enableKeyboardMovement());
    }

    submitMessage = () => {
        
    };

    openHelp = () => {
        store.dispatch(showHelp());
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);

        if (this.props.conversationTree) {
            this.setState({
                conversationTree: [ ...this.props.conversationTree ]
            })
        }

    }

    navigationHandler(event) {
        if (event.key === '<') {
            this.props.showLeft();
            return;
        }

        if (event.key === '>') {
            this.props.showRight();
            return;
        }
    }

    optionChosen(option) {
        if (option.hint === Hints.continue) {
            option = this.state.conversationTree.find(option => (option.children?.length > 0));

            if (!option) {
                option = this.state.conversationTree[this.state.conversationTree.length - 1];
            }
        }

        if (option.hint === Hints.end) {
            setTimeout(() => (this.props.hideAllMenus()));
            return;
        }

        if (option.hint === Hints.talk) {
            this.props.showDialogue()
            return;
        }

        if (option.hint === Hints.trade) {
            //TODO - begin trade
            return;
        }

        if (option.hint === Hints.restart) {
            //TODO - begin restart
            this.setState({
                conversationTree: [ ...this.props.conversationTree ]
            })
            return;
        }

        if (option.hint === Hints.createEmbark) {
            this.props.createEmbark();
        }

        if (option.hint === Hints.soloEmbark) {
            this.props.soloEmbark();
        }

        // if (option.hint === Hints.action) {
        //     if (option.text === 'Follow me') {
        //         // TODO
        //         colyClient.room.send('doSuperAction', {
        //             name: 'follow',
        //             data: {
        //                 selectedCharacterId: this.props.focussedCharacter._id,
        //                 characterId: this.props.character._id,
        //             },
        //         })
        //     }

        //     if (option.text === 'Gather something') {
        //         store.dispatch(showSelectItems({ itemsToSelect: 'resources', selectedCharacterId: this.props.focussedCharacter._id }))
        //     }

        //     if (option.text === 'Craft something') {
        //         store.dispatch(showWorkshop({ workshopTypeId: 'workshopSelect', selectedCharacterId: this.props.focussedCharacter._id }));
        //     }

        //     if (option.text === 'Haul something') {
        //         store.dispatch(showSelectItems({ itemsToSelect: 'zones', selectedCharacterId: this.props.focussedCharacter._id }))
        //     }

        //     return;
        // }

        this.setState({
            currentMessage: option.response,
            conversationTree: option.children
        })

        setTimeout(() => {
            console.log(this.state);    
        })
    }

    render() {
        let conversationTreeOptions = ('');
        let textInput = ('');
        let messagesList = ('');

        if (this.state.conversationTree) {
            conversationTreeOptions = (<Menu options={this.state.conversationTree} optionChosen={this.optionChosen.bind(this)} />);
        }

        return (
            <div>
                <div className="dialogue-content">
                    <p className="greeting">{this.state.currentMessage || (this.props.focussedCharacter?.conversationTree && this.props.focussedCharacter?.conversationTree[0].text)|| this.props.focussedCharacter.greeting  || 'Greetings, stranger'}</p>

                    <ol className="dialogue-tree-options">
                        {conversationTreeOptions}
                    </ol>
                </div>

                <div className="bottom-text">
                    [ <span className="action"> &lt; </span> {this.props.leftString} | <span className="action"> &gt; </span> {this.props.rightString}]
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let isEmbarkMessages = false;

    const panel = selectPanel(state);
    let messages = selectCharacterMessages(state)

    const embarkMessages = selectEmbarkCharacterMessages(state);

    if (Object.entries(embarkMessages).length !== 0) {
        isEmbarkMessages = true;
    }

    const character = selectCharacter(state)
    const embarkCharacter = selectEmbarkCharacter(state);

    const characters = selectCharacters(state);
    const embarkCharacters = embarkCharacter && selectCurrentEmbarkGroup(state, embarkCharacter?._id)?.embarkCharacters

    const focussedCharacterId = selectFocussedCharacterId(state);
    const focussedCharacter = selectCharacterById(state, focussedCharacterId)

    const messageOptions = selectMessageOptionss(state);

    messages = messages[focussedCharacterId]

    let conversationTree = selectConversationTree(state, focussedCharacter.conversationTree)

    const converastionTreeActions = [
        // {
        //     text: 'Follow me',
        //     indentation: 0,
        //     hint: Hints.action
        // },
        // {
        //     text: 'Gather something',
        //     indentation: 0,
        //     hint: Hints.action
        // },
        // {
        //     text: 'Craft something',
        //     indentation: 0,
        //     hint: Hints.action
        // },
        // {
        //     text: 'Haul something',
        //     indentation: 0,
        //     hint: Hints.action
        // },
    ]

    if (!conversationTree) {
        conversationTree = []
    }

    conversationTree.push(...converastionTreeActions)

    return {
        panel,
        messages: isEmbarkMessages ? embarkMessages : messages,
        character: isEmbarkMessages ? embarkCharacter : character,
        characters: isEmbarkMessages ? embarkCharacters : characters,
        focussedCharacterId,
        focussedCharacter,
        messageOptions,
        conversationTree
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus, getMessagesAsync, getMoreMessagesAsync, createMessageAsync, flashCharacterColorAsync, startLooking, createNewMessage }
)(ConversationTree);