import { CHANGE_CHARACTER, START_NEW_GAME, GET_CHARACTER_MESSAGES_SUCCESS } from "../actionTypes";

const initialState = {
  messages: [],
  totalMessages: 0,
  messageOptions: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CHARACTER:
    case START_NEW_GAME: {
      return { ...initialState }
    }

    case GET_CHARACTER_MESSAGES_SUCCESS: {
      console.log(action.payload)
      return {
        ...state,
        characterMessages: action.payload,
      }
    }

    default:
      return state;
  }
}
