import {
    CREATE_KEY_LOADING,
    CREATE_KEY_SUCCESS,
    CREATE_KEY_FAILURE,
    GET_KEYS_LOADING,
    GET_KEYS_SUCCESS,
    GET_KEYS_FAILURE,
    ADD_KEY,
    UPDATE_KEY,
    REMOVE_KEY,
    GET_TRADE_KEYS_SUCCESS,
    GET_TRADE_KEYS_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createKeySuccess = payload => ({
    type: CREATE_KEY_SUCCESS,
    payload
})

export const createKeyFailure = () => ({
    type: CREATE_KEY_FAILURE
})

export const createKeyAsync = payload => dispatch => {
    const { _id, keyTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'jewellery-instances',
        name: 'create',
        data: {
            keyTypeId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getKeysSuccess = payload => {
    return ({
        type: GET_KEYS_SUCCESS,
        payload
    })
}

export const getKeysFailure = () => ({
    type: GET_KEYS_FAILURE
})

export const getKeysAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('key-instances').find({ query })
        .then((response) => {
            response = response.map(key => ({ ...key, keyType: payload.keyTypes.find(type => type._id === key.keyTypeId) }))
            dispatch(getKeysSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getTradeKeysSuccess = payload => {
    return ({
        type: GET_TRADE_KEYS_SUCCESS,
        payload
    })
}

export const getTradeMetalsFailure = () => ({
    type: GET_TRADE_KEYS_FAILURE
})

export const getTradeKeysAsync = payload => dispatch => {
    return client.service('key-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(key => ({ ...key, keyType: payload.keyTypes.find(type => type._id === key.keyTypeId) }))
            dispatch(getTradeKeysSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addKeyToTileAsync = payload => dispatch => {
    const { keyInstance, tileId, tileTypeId } = payload;
    const { keyTypeId, lockId, _id } = keyInstance;

    colyClient.room.send('doAction', {
        serviceName: 'key-instances',
        commandType: 'create',
        data: {
            keyTypeId,
            tileId,
            tileTypeId,
            characterId: "",
            lockId
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addKeyToCharacterAsync = payload => dispatch => {
    const { keyInstances, characterId } = payload;
    const { keyTypeId, lockId, _id } = keyInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'key-instances',
        name: 'fetch',
        data: {
            keyTypeId,
            lockId,
            tileId: "",
            characterId,
            instanceIds: keyInstances.map(instance => instance._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id: undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const copyKeyAsync = payload => dispatch => {
    const { keyInstance, characterId } = payload;
    const { keyTypeId, lockId, _id } = keyInstance;

    console.log('errr wat?', {
            keyTypeId,
            lockId,
            quantity: 1,
        })

    colyClient.room.send('doSuperAction', {
        serviceName: 'key-instances',
        name: 'create',
        data: {
            keyTypeId,
            lockId,
            quantity: 1,
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addKey = payload => {
    return ({
        type: ADD_KEY,
        payload
    })
}

export const updateKey = payload => {
    return ({
        type: UPDATE_KEY,
        payload
    })
}

export const removeKey = payload => {
    return ({
        type: REMOVE_KEY,
        payload
    })
}
