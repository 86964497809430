import React from "react";
import { connect } from 'react-redux'
import { pointAtAsync } from '../../../redux/actions/messages.actions';
import { getCharacterEquippedItemsAsync } from '../../../redux/actions/characters.actions';
import client from '../../../services/client';
import { getKeyDescription } from './key-description'
import {
    selectHighlight,
    selectCharacter,
    selectCurrentTiles,
    selectIsQuantityInputOpen,
    selectTileInventory,
    selectTileFood,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectTileTents,
    selectTileLocks,
    selectDeadCharactersByPosition,
    selectCharactersByPosition,
    selectPlantInstanceIdAtTile,
    selectPlantById,
    selectConstructionByPosition,
    selectAnimalsByPosition,
    selectWorkshopByPosition,
    selectFurnitureByPosition,
    selectMineralAtPosition,
    selectLocksByConstructions,
    selectClothingTypes,
    selectJewelleryTypes,
    selectArmourTypes,
    selectWeaponTypes,
    selectOrders,
} from '../../../redux/selectors';
import escapeHtml from 'escape-html'
import { Text } from 'slate'

import ImageSurface from '../../edit-drawing-surface/ImageSurface'

import { PANEL_WIDTH, PANEL_HEIGHT } from '../../../services/geography';
import { BALANCE } from '../../../services/balance-config';

import { getAnimalDescription } from './animal-description';
import { getPlantDescription } from './plant-description';

import './Looking.css';

const titleTranslations = {
    FARM_PLOT: 'Farm Plot'
}

export const serialize = (node, index) => {
  if (Text.isText(node)) {
    let string = node.text
    if (node.bold) {
      string = (<span key={index} style={{color: node.color, backgroundColor: node.backgroundColor}}><strong>{string}</strong></span>)
    }
    return <span key={index} style={{color: node.color, backgroundColor: node.backgroundColor}}>{string}</span>
  }

  if (typeof node.children === 'string') {
    node.children = JSON.parse(node.children)
  }

  if (typeof node === 'string') {
    node = JSON.parse(node)
  }

  if (!node.children?.map) {
    return;
  }

  const children = node.children?.map((n, i) => serialize(n, i))

  switch (node.type) {
    case 'paragraph':
      return (<p key={index}>{children}</p>)
    default:
      return children
  }
}

export const  getTorchDurationString = (tool, serverTimeOffset, isToolEquipped) => {
    if (!tool.toolType?.lightSourceDuration) {
        return '';
    }

    const now = new Date().getTime() - serverTimeOffset

    if (!tool.expiresAt) {
        return 'Pristine'
    }

    const remainingTime = isToolEquipped ? tool.expiresAt - now : tool.lightSourceDuration;

    const torchHealthIndex = remainingTime / tool.toolType?.lightSourceDuration;

    if (torchHealthIndex > 0.75) {
        return 'Healthy'
    }

    if (torchHealthIndex > 0.5) {
        return 'Used'
    }

    if (torchHealthIndex > 0.25) {
        return 'Dimming'
    }

    return 'Dying'
}

function getType(item) {
    return item?.foodType
        || item?.machineType
        || item?.toolType
        || item?.metalType
        || item?.mineralType
        || item?.materialType
        || item?.lockType
        || item?.writingSurfaceType
        || item?.writingImplementType
        || item?.furnitureType
        || item?.tileType
        || item?.weaponType
        || item?.armourType
        || item?.clothingType
        || item?.jewelleryType
        || item?.keyType
}

export const getAnimalHealthString = (animal) => {
    const healthRatio = animal.healthPoints / animal.animal.healthPoints;

    if (!healthRatio) {
        return 'Dying'
    }

    if (healthRatio < 0.25) {
        return 'Badly hurt'
    }

    if (healthRatio < 0.5) {
        return 'Hurt'
    }

    if (healthRatio < 0.75) {
        return 'Slightly hurt'
    }

    return 'Healthy'
}

export const getAnimalDifficultyString = (targetAnimal, character) => {
    if (!targetAnimal || !character || !targetAnimal.animal.name) {
        return;
    }

    const characterHealth = character.maxHealthPoints;
    const targetAnimalHealth = targetAnimal.animal.healthPoints;
    let characterArmour = 0;

    if (character.clothingItems) {
        characterArmour = character.clothingItems.reduce((acc, val) => {
            return acc + val.type?.armour + val.type?.dodge
        }, 0)
    }

    const targetAnimalArmour = BALANCE[targetAnimal.animal.name].armour?.defence + BALANCE[targetAnimal.animal.name].armour?.dodge

    const difficultyRatio = (targetAnimalHealth + targetAnimalArmour) / (characterHealth + characterArmour);

    if (difficultyRatio < 0.5) {
        return 'Trivial'
    }

    if (difficultyRatio < 0.75 && difficultyRatio > 0.5) {
        return 'Easy'
    }

    if (difficultyRatio < 1.25 && difficultyRatio >= 0.75) {
        return 'Normal'
    }

    if (difficultyRatio < 2 && difficultyRatio > 1.25) {
        return 'Difficult'
    }

    if (difficultyRatio > 2) {
        return 'Impossible'
    }
}

export const getDifficultyString = (targetCharacter, character) => {
    if (!targetCharacter || !character) {
        return;
    }

    const characterHealth = character.maxHealthPoints;
    const targetCharacterHealth = targetCharacter.maxHealthPoints;
    let characterArmour = 0;
    let targetCharacterArmour = 0;

    if (character.clothingItems) {
        characterArmour = character.clothingItems.reduce((acc, val) => {
            return acc + val.type?.armour + val.type?.dodge
        }, 0)
    }

    if (targetCharacter.clothingItems) {
        targetCharacterArmour = targetCharacter.clothingItems.reduce((acc, val) => {
            if (!val.type) {
                return acc;
            }

            return acc + val.type?.armour + val.type?.dodge
        }, 0)
    }

    const difficultyRatio = (targetCharacterHealth + targetCharacterArmour) / (characterHealth + characterArmour);

    if (difficultyRatio < 0.5) {
        return 'Trivial'
    }

    if (difficultyRatio < 0.75 && difficultyRatio >= 0.5) {
        return 'Easy'
    }

    if (difficultyRatio < 1.25 && difficultyRatio >= 0.75) {
        return 'Normal'
    }

    if (difficultyRatio < 2 && difficultyRatio >= 1.25) {
        return 'Difficult'
    }

    if (difficultyRatio > 2) {
        return 'Impossible'
    }
}

export const getOrderString = (character, orders) => {
    const activeOrder = orders.find(order => (order.characterId === character._id && order.isActive && !order.isCompleted));

    if (!activeOrder) {
        return undefined
    }

    let orderString = `${activeOrder.type}`

    if (activeOrder.waitCost) {
        orderString += ` ${activeOrder.waitCount}/${activeOrder.waitCost}`
    }

    return orderString
}

export const getCondition = (item) => {
    if (!item || item.expiresAt) {
        return '';
    }

    const type = getType(item)

    if (type?.isLightSource) {
        return ''
    }

    const rotDays = type?.rotDays || item.rotDays || type?.totalUses;
    const daysRemaining = item.daysRemaining || rotDays || item.remainingUses;

    if (daysRemaining && rotDays) {
        if (daysRemaining > 80) {
            return 'Pristine';
        }

        if (daysRemaining > 60) {
            return 'Excellent'
        }

        if (daysRemaining > 40) {
            return 'Good'
        }

        if (daysRemaining > 20) {
            return 'Used'
        }

        if (daysRemaining > 10) {
            return 'Tattered'
        }

        if (daysRemaining > 5) {
            return 'Poor'
        }

        return 'Breaking'
    }

    return '';
}

export const getHealthString = (character) => {
    const healthRatio = character.healthPoints / character.maxHealthPoints;

    if (!healthRatio) {
        return 'Dying'
    }

    if (healthRatio < 0.25) {
        return 'Badly hurt'
    }

    if (healthRatio < 0.5) {
        return 'Hurt'
    }

    if (healthRatio < 0.75) {
        return 'Slightly hurt'
    }

    return 'Healthy'
}

export const getHungerString = (character) => {
    const hungerRatio = (BALANCE.HUNGER_IMPACTS_VALUE - character.hunger) / BALANCE.HUNGER_IMPACTS_VALUE;

    if (hungerRatio < 0.1) {
        return 'Starving'
    }

    if (hungerRatio < 0.25) {
        return 'Hungry'
    }

    if (hungerRatio < 0.5) {
        return 'Peckish'
    }

    if (hungerRatio < 0.75) {
        return 'Satiated'
    }

    return 'Full'
}

class Looking extends React.Component {
    state = {
        showingItemIndex: 0
    }

    submitHandler = (event) => {
        if (event.key === '>') {
            if (this.state.showingItemIndex < (this.state.itemsToSee?.length - 1)) {
                this.setState({
                    isHidden: true,
                    showingItemIndex: this.state.showingItemIndex + 1
                })

                setTimeout(() => {
                    this.setState({
                        isHidden: false
                    })
                })
            }

            return;
        }

        if (event.key === '<') {
            if (this.state.showingItemIndex > 0) {
                this.setState({
                    isHidden: true,
                    showingItemIndex: this.state.showingItemIndex - 1
                })

                setTimeout(() => {
                    this.setState({
                        isHidden: false
                    })
                })
            }

            return;
        }
    };

    constructor() {
        super();

        document.addEventListener('keydown', this.submitHandler)
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.submitHandler);
    }

    pointAt() {
        this.props.pointAtAsync({
            position: this.props.highlight
        })
    }

    // This function exists to force the box to be repositioned after the contents has been filled :)
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isChangedHighlight) {
        this.setState({
            forceRerender: !this.state.forceRerender
        })
    }
  }

    static getDerivedStateFromProps(props, state) {
        if (props.highlight?.x !== state.highlight?.x || props.highlight?.y !== state.highlight?.y) {
            state.isChangedHighlight = true
            state.highlight = { ...props.highlight }
            state.showingItemIndex = 0;
        } else {
            state.isChangedHighlight = false
        }

        if (!props.highlight) {
            return state;
        }

        let itemsToSee = [];

        if (props.characters) {
            props.characters.forEach(async character => {
                const characterDescription = {
                    title: character.name,
                    description: character.description,
                    health: getHealthString(character),
                    difficulty: getDifficultyString(character, props.character),
                    order: getOrderString(character, props.orders),
                    equippedItems: []
                }

                itemsToSee.push(characterDescription)

                const characterItemDescriptions = await props.getCharacterEquippedItemsAsync(character);

                if (characterItemDescriptions.weapon) {
                    const weaponType = props.weaponTypes.find(type => (type._id === characterItemDescriptions.weapon.weaponTypeId))

                    if (weaponType) {
                        itemsToSee.push({
                            title: `The ${weaponType.name} of ${character.name}`,
                            graffiti: characterItemDescriptions.weapon.graffiti
                        })
                    }
                }

                if (characterItemDescriptions.armour) {
                    const armourType = props.armourTypes.find(type => (type._id === characterItemDescriptions.armour.armourTypeId))

                    if (armourType) {
                        itemsToSee.push({
                            title: `The ${armourType.name} of ${character.name}`,
                            graffiti: characterItemDescriptions.armour.graffiti
                        })
                    }
                }

                if (characterItemDescriptions.clothingItems) {
                    characterItemDescriptions.clothingItems.forEach(clothingItem => {
                        const clothingType = props.clothingTypes.find(type => (type._id === clothingItem.itemTypeId))
                        const jewelleryType = props.jewelleryTypes.find(type => (type._id === clothingItem.itemTypeId))

                        const itemName = clothingType ? clothingType.name : jewelleryType.name;

                        itemsToSee.push({
                            title: `The ${itemName} of ${character.name}`,
                            graffiti: clothingItem.graffiti
                        })
                    })
                }
            })

        }
        if (props.animals) {
            props.animals.forEach((animal) => {
                const animalText = getAnimalDescription(animal);
                itemsToSee.push({
                    title: animalText?.title || animal.animal.name,
                    description: animalText.description,
                    health: getAnimalHealthString(animal),
                    difficulty: getAnimalDifficultyString(animal, props.character)
                })
            })
        }
        if (props.furniture) {
            props.furniture.forEach(furniture => {
                let title = furniture.furnitureType?.name;
                console.log(furniture);

                if (furniture.ownerCharacterName) {
                    title = `${furniture.furnitureType?.name} owned by ${furniture.ownerCharacterName}`
                }

                itemsToSee.push({
                    title,
                    description: furniture.furnitureType?.description,
                    graffiti: furniture.graffiti,
                    pixelsArray: furniture.pixelsArray
                })
            })
        }
        if (props.plant) {
            itemsToSee.push({
                title: props.plant.plant.name,
                description: getPlantDescription(props.plant),
                tended: props.plant.plant.maxTendingCount ? `${props.plant.tendedCount}/${props.plant.plant.maxTendingCount}` : ''
            })
        }
        if (props.tiles?.byId && props.tiles?.byId[0]) {
            props.tiles?.byId.forEach(tile => {
                if (tile.tileType?.name === 'ITEM') {
                    const tileId = tile._id
                    const title = `Unstored items`
                    let description = Object.values(props.tileContents[tileId]).reduce((string, itemArray, index) => {

                        if (Array.isArray(itemArray)) {
                            itemArray.forEach(item => {
                                const type = getType(item);

                                if (string.indexOf(type?.name) === -1) {
                                    string += type?.name + ', ';
                                }
                            })
                        }

                        return string;
                    }, '')
                    description = description.slice(0, description.length - 2)

                    itemsToSee.push({
                        title,
                        description
                    })
                } else {
                    const tileId = tile._id

                    itemsToSee.push({
                        title: tile.tileType?.name,
                        pixelsArray: tile.pixelsArray,
                        graffiti: tile.graffiti,
                        lock: props.tileContents[tileId].tileLocks[0]
                    })

                    Object.values(props.tileContents[tileId]).map(items => {
                        if (!items || items.length === 0 || items._id) {
                            return;
                        }

                        items.forEach(item => {
                            if (!item.text) {
                                return;
                            }

                            itemsToSee.push({
                                title: item.text,
                                pixelsArray: item.pixelsArray,
                                graffiti: item.graffiti,
                            })
                        })
                    })
                }
            })
        }
        if (props.constructions) {
            props.constructions.forEach(construction => {
                itemsToSee.push({
                    title: construction.construction?.name,
                    description: construction.construction?.description,
                    graffiti: construction.graffiti,
                    pixelsArray: construction.pixelsArray,
                    progress: construction.progress,
                    lock: props.locks[0]
                })
            })
        }
        if (props.workshop?._id && props.workshop?.workshopType) {
            itemsToSee.push({
                title: props.workshop.workshopType?.name,
                description: props.workshop.workshopType?.description
            });
        }
        if (props.deadCharacters) {
            props.deadCharacters.forEach(deadCharacter => {
                itemsToSee.push({
                    title: `The dearly departed ${deadCharacter.name}`,
                    description: deadCharacter.description
                })
            })
        }
        if (props.mineral) {
            itemsToSee.push({
                title: props.mineral.mineralType?.name || props.mineral.mineral?.name
            })
        }

        state.itemsToSee = itemsToSee;

        return state;
    }

    render() {
        const itemsToSee = this.state.itemsToSee || [];
        let itemIndex = this.state.showingItemIndex;

        if (itemIndex > (itemsToSee.length - 1)) {
            itemIndex = itemsToSee.length - 1
        }

        let title = itemsToSee[itemIndex]?.title;
        let description = itemsToSee[itemIndex]?.description;
        let tended = itemsToSee[itemIndex]?.tended
        let graffiti = itemsToSee[itemIndex]?.graffiti
        let pixelsArray

        if (titleTranslations[title]) {
            title = titleTranslations[title]
        }

        try {
            pixelsArray = JSON.parse(itemsToSee[itemIndex]?.pixelsArray)
        } catch(e) {
            pixelsArray = itemsToSee[itemIndex]?.pixelsArray
        }
        let progress = itemsToSee[itemIndex]?.progress || 100;
        let health = itemsToSee[itemIndex]?.health;
        let difficulty = itemsToSee[itemIndex]?.difficulty;
        let lock = itemsToSee[itemIndex]?.lock;
        let order = itemsToSee[itemIndex]?.order;

        if (graffiti) {
            graffiti = serialize({ children: graffiti })
        }

        const canvas = document.getElementsByTagName('canvas')[0]

        const canvasWidth = Number(canvas?.style?.width.slice(0, -2));
        const canvasHeight = Number(canvas?.style?.height.slice(0, -2));

        const tileHeight = canvasHeight / PANEL_HEIGHT;
        const tileWidth = canvasWidth / PANEL_WIDTH;

        const lookingContainer = document.getElementsByClassName('looking-container')[0]
        let lookingContainerShape = lookingContainer?.getBoundingClientRect()

        let top;
        let left;

        if (lookingContainerShape) {
            if (this.props.highlight.y < PANEL_HEIGHT / 2) {
                top = tileHeight * this.props.highlight?.y + tileHeight;
            } else {
                top = tileHeight * this.props.highlight?.y - (lookingContainerShape?.height || 0);
            }

            if (this.props.highlight.x < PANEL_WIDTH / 2) {
                left = tileWidth * this.props.highlight?.x + tileWidth;
            } else {
                left = tileWidth * this.props.highlight?.x + tileWidth - (lookingContainerShape?.width || 0);
            }
        }

        return this.props.highlight ? (
            <div className={(title && (!this.state.isChangedHighlight && !this.state.isHidden)) ? "looking-container" : "looking-container hiding"} style={{ top, left }}>
                <p className="looking-title">{title}<span className={(graffiti || pixelsArray?.length > 0) ? "": "hidden"}> [<span className="hint">decorated</span>]</span></p>
                <p className={description ? "looking-description" : "hidden"}>{description}</p>
                <p className={tended ? "looking-description" : "hidden"}>{tended}</p>
                <div className="combat-stats">
                    <p className={health ? `looking-description ${health.toLowerCase().split(' ').join('-')}` : "hidden"}>{health}&nbsp;</p>
                    <p className={difficulty ? `looking-description ${difficulty.toLowerCase()}` : 'hidden'}>{difficulty}</p>
                </div>

                {lock ? (
                    <div>{lock.lockType.name}
                    &nbsp;({getKeyDescription(lock.lockId)})
                    {lock.lockDirection ? (<p className="locked">[{lock.lockDirection}]</p>) : (<></>)}
                    {lock.isLocked ? (<p className="locked">[locked]</p>) : (<p className="unlocked">[unlocked]</p>)}
                    </div>
                ) : ('')}

                {progress < 100? (
                    <p className="looking-description">{progress}/100</p>
                ) : ('')}

                {order && (<p className="looking-description"><span className="order">{order}</span></p>)}

                {
                    pixelsArray && pixelsArray.length > 0 ? (
                        <div className="drawing">
                            <ImageSurface pixelsArray={pixelsArray} width={this.props.width || 20} height={this.props.height || 15}/>
                        </div>
                    ) : ('')
                }

                <div className={graffiti ? "graffiti": "hidden"}>
                    <p>&nbsp;</p>
                    {graffiti}
                </div>
                <div className={this.state.itemsToSee?.length > 1 ? "bottom-text" : "hidden"}>
                    <span className="action"> &lt; </span> Previous | <span className="action"> &gt; </span> Next
                </div>
            </div>
        ) : ('')
    }
}

const mapToStateProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const clothingTypes = selectClothingTypes(state);
    const jewelleryTypes = selectJewelleryTypes(state);
    const armourTypes = selectArmourTypes(state);
    const weaponTypes = selectWeaponTypes(state);

    const currentPosition = highlight || character.position;

    if (!currentPosition) {
        return;
    }

    const isQuantityInputShowing = selectIsQuantityInputOpen(state);

    const tiles = selectCurrentTiles(state, currentPosition);
    const deadCharacters = selectDeadCharactersByPosition(state, currentPosition);
    const characters = selectCharactersByPosition(state, currentPosition);
    const plantId = selectPlantInstanceIdAtTile(state, currentPosition)
    const plant = selectPlantById(state, plantId);
    const constructions = selectConstructionByPosition(state, currentPosition);
    const animals = selectAnimalsByPosition(state, currentPosition);
    const workshop = selectWorkshopByPosition(state, currentPosition);
    const furniture = selectFurnitureByPosition(state, currentPosition);
    const mineral = selectMineralAtPosition(state, currentPosition);
    const locks = selectLocksByConstructions(state, constructions);
    const orders = selectOrders(state);

    let tileContents = {};

    tiles.byId.forEach(tile => {
        tileContents[tile._id] = {
            tile,
            tileInventory: [ ...selectTileInventory(state, tile._id) ],
            tileFood: [ ...selectTileFood(state, tile._id) ],
            tileMinerals: [ ...selectTileMinerals(state, tile._id) ],
            tileTools: [ ...selectTileTools(state, tile._id) ],
            tileMetals: [ ...selectTileMetals(state, tile._id) ],
            tileKeys: [ ...selectTileKeys(state, tile._id) ],
            tileWritingSurfaces: [ ...selectTileWritingSurfaces(state, tile._id) ],
            tileWritingImplements: [ ...selectTileWritingImplements(state, tile._id) ],
            tileWeapons: [ ...selectTileWeapons(state, tile._id) ],
            tileArmour: [ ...selectTileArmour(state, tile._id) ],
            tileClothing: [ ...selectTileClothing(state, tile._id) ],
            tileJewellery: [ ...selectTileJewellery(state, tile._id) ],
            tileTents: [ ...selectTileTents(state, tile._id) ],
            tileLocks: [ ...selectTileLocks(state, tile._id) ],
        }
    })

    return {
        character,
        highlight,
        tiles,
        deadCharacters,
        characters,
        plant,
        constructions,
        locks,
        animals,
        workshop,
        furniture,
        mineral,
        tileContents,
        clothingTypes,
        jewelleryTypes,
        armourTypes,
        weaponTypes,
        orders
    }
}

export default connect(
    mapToStateProps,
    {
        pointAtAsync,
        getCharacterEquippedItemsAsync
    }
)(Looking);