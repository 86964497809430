import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { newZone, showZone, disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectCharacters,
    selectCharacterZones
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { createOrganisationAsync } from '../../redux/actions/organisation.actions'

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

import { client } from '../../services/client';

// import './Diary.css';

class ZoneList extends React.Component {
    state = {
        navigationHandler: this.navigationHandler.bind(this),
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    navigationHandler(event) {
        if (this.state.isSetTextShowing || this.state.isMemberSelectShowing) {
            return;
        }

        if (event.key === 'c') {
            this.props.newZone()
            return;
        }
    }

    onZoneChosen(option) {
        this.props.showZone({ zoneId: option._id })
    }

    render() {
        return (
            <div className="conversation-tree-wizard">
                <div className="conversation-tree-wizard-container">
                    <Menu options={this.props.zones.map(zone => ({ ...zone, text: zone.name, hint: ` A Zone` }))}
                        menuContainer="conversation-tree-wizard-container"
                        optionChosen={this.onZoneChosen.bind(this)}
                    />
                </div>
                <div className="conversation-tree-actions-container">
                    <p><span className="action">C</span> - Create new zone</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const characters = selectCharacters(state);
    const character = selectCharacter(state);
    const zones = selectCharacterZones(state, character._id);

    return {
        characters: characters.map(character => ({ ...character, text: character.name })),
        zones
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus, newZone, showZone }
)(ZoneList);