import { combineReducers } from "redux";
import meta from './meta';
import character from './character';
import user from './user';
import panel from './panel';
import tileTypes from './tile-types';
import tiles from './tiles';
import inventory from './inventory';
import plants from './plants';
import constructionTypes from './construction-types';
import constructions from './constructions';
import foodRecipes from './food-recipes';
import food from './food';
import deadCharacters from './dead-characters';
import characters from './characters';
import minerals from './minerals';
import machineRecipes from './machine-recipes';
import toolRecipes from './tool-recipes';
import metalRecipes from './metal-recipes';
import materialRecipes from './material-recipes';
import tools from './tools';
import machines from './machines';
import metals from './metals';
import messages from './messages';
import locks from './locks';
import lockRecipes from './lock-recipes';
import keys from './keys';
import keyRecipes from './key-recipes';
import writingImplementRecipes from './writing-implement-recipes';
import writingSurfaceRecipes from './writing-surface-recipes';
import writingImplements from './writing-implements';
import writingSurfaces from './writing-surfaces';
import workshopLevelRecipes from './workshop-level-recipes';
import keyboardShortcuts from './keyboard-shortcuts';
import furnitureRecipes from './furniture-recipes';
import furniture from './furniture';
import tileRecipes from './tile-recipes';
import weapons from './weapons';
import weaponRecipes from './weapon-recipes';
import armour from './armour';
import armourRecipes from './armour-recipes';
import clothing from './clothing';
import clothingRecipes from './clothing-recipes';
import jewellery from './jewellery';
import jewelleryRecipes from './jewellery-recipes';
import embarkCharacter from './embark-character';
import embarkCharacters from './embark-characters';
import embarkGroups from './embark-groups';
import groups from './groups';
import embarkForms from './embark-forms';
import fish from './fish';
import animals from './animals';
import skillTypes from './skill-types';
import materialTypes from './material-types';
import mineralTypes from './mineral-types';
import plantTypes from './plant-types';
import lockTypes from './lock-types';
import foodTypes from './food-types';
import jewelleryTypes from './jewellery-types';
import clothingTypes from './clothing-types';
import armourTypes from './armour-types';
import weaponTypes from './weapon-types';
import writingImplementTypes from './writing-implement-types';
import writingSurfaceTypes from './writing-surface-types';
import furnitureTypes from './furniture-types';
import machineTypes from './machine-types';
import keyTypes from './key-types';
import metalTypes from './metal-types';
import toolTypes from './tool-types';
import animalTypes from './animal-types';
import fishTypes from './fish-types';
import plantProducts from './plant-products';
import constructionRecipes from './construction-recipes';
import clock from './clock';
import wagonRecipes from './wagon-recipes';
import tentRecipes from './tent-recipes';
import workshopRecipes from './workshop-recipes';
import boatRecipes from './boat-recipes';
import wagonTypes from './wagon-types';
import tentTypes from './tent-types';
import workshopTypes from './workshop-types';
import boatTypes from './boat-types';
import wagons from './wagons';
import tents from './tents';
import workshops from './workshops';
import boats from './boats';
import stairs from './stairs';
import mineWalls from './mine-walls';
import characterLevels from './character-levels';
import coins from './coins';
import coinTypes from './coin-types';
import coinRecipes from './coin-recipes';
import currency from './currency';
import brainchipTypes from './brainchip-types';
import brainchipRecipes from './brainchip-recipes';
import brainchips from './brainchips';
import connectionState from './connection-state';
import prompts from './prompt-instances';
import diaryEntries from './diary-entries';
import organisations from './organisations';
import zones from './zones';
import orders from './orders';
import characterPanels from './character-panels';
import criminals from './criminals';
import characterMessages from './character-messages';

export default combineReducers({
	meta,
	character,
	user,
	panel,
	tileTypes,
	tiles,
	inventory,
	plants,
	constructionTypes,
	constructions,
	foodRecipes,
	food,
	deadCharacters,
	characters,
	minerals,
	machineRecipes,
	toolRecipes,
	metalRecipes,
	materialRecipes,
	tools,
	machines,
	metals,
	messages,
	locks,
	lockRecipes,
	keys,
	keyRecipes,
	writingImplementRecipes,
	writingSurfaceRecipes,
	writingImplements,
	writingSurfaces,
	keyboardShortcuts,
	furnitureRecipes,
	furniture,
	tileRecipes,
	weapons,
	weaponRecipes,
	armour,
	armourRecipes,
	clothing,
	clothingRecipes,
	jewellery,
	jewelleryRecipes,
	embarkCharacter,
	embarkCharacters,
	embarkGroups,
	groups,
	embarkForms,
	fish,
	animals,
	skillTypes,
	materialTypes,
	plantTypes,
	mineralTypes,
	lockTypes,
	foodTypes,
	jewelleryTypes,
	clothingTypes,
	armourTypes,
	weaponTypes,
	writingImplementTypes,
	writingSurfaceTypes,
	furnitureTypes,
	machineTypes,
	keyTypes,
	metalTypes,
	toolTypes,
	animalTypes,
	fishTypes,
	plantProducts,
	constructionRecipes,
	clock,
	wagonRecipes,
	tentRecipes,
	boatRecipes,
	wagonTypes,
	tentTypes,
	boatTypes,
	wagons,
	tents,
	boats,
	stairs,
	mineWalls,
	workshopRecipes,
	workshopTypes,
	workshops,
	characterLevels,
	coins,
	coinTypes,
	coinRecipes,
	currency,
	brainchipTypes,
	brainchipRecipes,
	brainchips,
	workshopLevelRecipes,
	connectionState,
	prompts,
	diaryEntries,
	organisations,
	zones,
	orders,
	characterPanels,
	criminals,
	characterMessages
});
