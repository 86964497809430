import React from "react";
import { connect } from 'react-redux'

import store from '../../../redux/store';
import { disableKeyboardMovement, enableKeyboardMovement } from '../../../redux/actions/keyboard-shortcuts.actions';
import { selectMessages, selectCharacter } from '../../../redux/selectors';

import { client } from '../../../services/client';

import './TextInput.css';

class TextInput extends React.Component {
    state = {
        handleClick: this.handleClick.bind(this),
        handleEnter: this.handleEnter.bind(this)
    }

    constructor() {
        super();
    }

    handleClick(event) {
        event.stopPropagation();
        event.preventDefault();
        setTimeout(() => (document.getElementsByClassName('textarea')[0].focus()))
    }

    handleEnter(event) {
        if (event.code === 'Enter') {
            event.stopPropagation();
            event.preventDefault();

            this.props.onTextSubmit(this.state.text);
            setTimeout(() => {
                this.setState({
                    text: ''
                })
            })
            return;
        }

        if (event.code === this.props.exitKey && this.props.onExitKey) {
            event.preventDefault();
            this.props.onExitKey();
        }

        if (this.props.onTextUpdate) {
            setTimeout(() => {
                this.props.onTextUpdate(this.state.text)
            })
        }
    }

    componentDidMount() {
        if (this.props.text) {
            this.updateInput(this.props.text);
        }

        document.addEventListener('click', this.state.handleClick);
        document.addEventListener('keydown', this.state.handleEnter);

        setTimeout(() => (document.getElementsByClassName('textarea')[0].focus()))
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.state.handleClick)
        document.removeEventListener('keydown', this.state.handleEnter)
    }

    updateInput(text, event) {
        this.setState({
            text
        })
    }

    render() {
        return (
            <div className="menu-input">
                <textarea
                    maxLength="250"
                    className="textarea"
                    placeholder=">" 
                    onChange={e => this.updateInput(e.target.value, e)}
                    rows="5"
                    value={this.state.text}>
                </textarea>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const messages = selectMessages(state)
    const character = selectCharacter(state)

    return { messages, character }
}

export default connect(
    mapStateToProps,
    { }
)(TextInput);